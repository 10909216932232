import { Component } from 'react'
import { connect } from 'react-redux'
import { resetSearch, searchEveryplace } from 'actions/search'
import { ENTITY_TYPES, LEAD_ROLES, ORG_ROLES, ROLES } from 'const'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { Button, Input, Select } from '@creditclubteam/kit/ui-components'
import { Autocomplete } from 'components/common'
import { utils } from 'helpers'

import { formatSearchData, requestsBodies } from './helpers'

import './AddForm.scss'

const { PERSON, ENTREPRENEUR, ORGANIZATION } = PARTICIPANTS_TYPES

const ROSREESTR_REGISTRATION_ROLES = ROLES.filter(({ id }) => id !== 'WARRANTOR')

const AddOrganizationOrEntrepreneur = (props) => {
	const { type, onCreate } = props

	return (
		<div className='participants-addform__add-manual'>
			<div className='participants-addform__not-found-label'>Нет результатов по такому ИНН</div>
			<span onClick={onCreate} className='participants-addform__create-button'>
				Создать {type === ORGANIZATION ? 'ЮЛ' : 'ИП'}
			</span>
		</div>
	)
}

class AddForm extends Component {
	static propTypes = {
		entity: PropTypes.object.isRequired,
		addingProcess: PropTypes.bool.isRequired,
		searchResults: PropTypes.object.isRequired,
		defineSearchScopes: PropTypes.func,

		// for max value
		participantsData: PropTypes.array.isRequired,

		onSuccessEdit: PropTypes.func,
		resetSearch: PropTypes.func.isRequired,
		searchEveryplace: PropTypes.func.isRequired,
		onAddParticipant: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		const {
			entity: { name },
		} = props

		this.state = {
			role: '',
			roles: [],
			phone: '',
			query: '',
			subject: {},
			type: PERSON,
			subjectOptions: [{ label: 'ФЛ', id: PERSON }],
			isSearching: false,

			isFacility: name === ENTITY_TYPES.FACILITY,
			isApplication: name === ENTITY_TYPES.APPLICATION,
			isOrganization: name === ENTITY_TYPES.ORGANIZATION,
			isEntrepreneur: name === ENTITY_TYPES.ENTREPRENEUR,
			isLead: name === ENTITY_TYPES.LEAD,
			isDelivery: name === ENTITY_TYPES.DELIVERY,
			isRosreestrRegistration: name === ENTITY_TYPES.ROSREESTR_REGISTRATION,
		}
	}

	componentDidMount() {
		const { subjectOptions, isApplication, isEntrepreneur, isDelivery } = this.state

		// Добавляем в селект субьектов исходя из родительской страницы
		const additionalSubjectOptions = []
		if (isApplication || isDelivery)
			additionalSubjectOptions.push({ label: 'ИП', id: ENTREPRENEUR })
		if (!isEntrepreneur) additionalSubjectOptions.push({ label: 'ЮЛ', id: ORGANIZATION })

		if (utils.hasObjectLength(additionalSubjectOptions))
			this.setState({
				subjectOptions: [...subjectOptions, ...additionalSubjectOptions],
			})

		// Добавление на клавишу Enter
		this.nodeElement.addEventListener('keyup', this.handleEnterSubmit)
	}

	componentWillUnmount() {
		this.nodeElement.removeEventListener('keyup', this.handleEnterSubmit)
	}

	componentDidUpdate(prevProps) {
		const { addingProcess } = this.props

		// Сброс формы
		if (prevProps.addingProcess && !addingProcess) {
			this.setState({
				roles: [],
				phone: '',
				query: '',
				subject: {},
				type: PERSON,
				isSearching: false,
			})
		}
	}

	generateDisabled = () => {
		const { addingProcess } = this.props

		return addingProcess
	}

	checkСompleteness = () => {
		const { isEntrepreneur, isDelivery, isFacility, isLead, roles, type, query, phone } = this.state

		if (isDelivery) {
			return Boolean(query)
		}

		if (isFacility) {
			return Boolean(query)
		}

		if (isEntrepreneur) {
			return Boolean(query)
		}

		if (isLead && type === ENTITY_TYPES.PERSON) {
			return Boolean(
				Array.isArray(roles) && roles.length && query && (phone ? utils.isValidPhone(phone) : true)
			)
		} else if (isLead && type !== ENTITY_TYPES.PERSON) {
			return Boolean(Array.isArray(roles) && roles.length && query)
		}

		return Boolean(Array.isArray(roles) && roles.length && query)
	}

	handleEnterSubmit = ({ key }) => {
		if (key === 'Enter' && this.checkСompleteness()) {
			this.handleAddParticipant()
		}
	}

	handleAddParticipant = () => {
		const { onAddParticipant, onSuccessEdit, entity } = this.props
		const { type, role, phone, query, subject, roles, isFacility, isEntrepreneur, isLead } =
			this.state

		const results = {
			dataForCreatingEntity: null,
			type,
			query,
			role,
			roles: [],
			subject,
			// Если добавляем человека из своей базы а у него не было номера и его не указали
			// при добавлении то берём тот хеш которым был изначально в нём, что бы не создавались дубли
			phone: phone || (subject.$$category === 'internal' ? subject.phone : phone),
		}

		// У ИП никаких дополнительных доль и ролей не нужно
		// поэтому проверяем только если это не он
		if (!isEntrepreneur) {
			if (!isFacility) {
				results.roles = roles
			}
		}

		if (isLead) {
			results.roles = roles
		}

		if (subject.$$category === 'external' || isEmpty(subject)) {
			results.dataForCreatingEntity = requestsBodies[type](results)
		}

		onAddParticipant({ target: { id: entity.id, type: entity.name }, entity: results }).then(
			async () => {
				await onSuccessEdit?.()
				this.handleReset()
			}
		)
	}

	createEmptyParticipant = () => {
		const { type, query } = this.state

		if (![ORGANIZATION, ENTREPRENEUR].includes(type)) {
			return
		}

		this.setState({
			subject: {
				empty: true,
				$$category: 'external',
				data: {
					inn: query,
				},
				value: `Неизвестное ${type === ORGANIZATION ? 'ЮЛ' : 'ИП'}`,
			},
		})
	}

	handleChangeType = ({ value }) => {
		this.handleReset(() => {
			this.setState({ type: value })
		})
	}

	handleReset = (callback) => {
		const { resetSearch } = this.props

		resetSearch(true)

		this.setState({ subject: {}, phone: '', query: '' }, () => {
			if (callback && typeof callback === 'function') {
				callback()
			}
		})
	}

	handleChangeParticipant = (option) => {
		const { type } = this.state

		this.setState({ subject: option })

		// Если найденный пользователь существует и у него
		// есть номер телефона то сразу запишем его
		if (option.id && type === PERSON && option.info) {
			this.setState({ phone: option.info })
		}
	}

	handleChangeAutocomplete = async (value) => {
		const { type } = this.state
		const { searchEveryplace, resetSearch, defineSearchScopes } = this.props

		this.setState({ subject: {}, query: value, isSearching: value.length > 2 })

		if (value.length > 2) {
			const options = {
				query: value,
				methodType: type,
				params: { size: 5 },
				searchIn: {
					internal: defineSearchScopes ? defineSearchScopes(type).includes('internal') : true,
					external: defineSearchScopes ? defineSearchScopes(type).includes('external') : true,
				},
				type: type === ENTREPRENEUR ? 'INDIVIDUAL' : 'LEGAL',
			}

			await searchEveryplace(options, type === PERSON ? 'fio' : 'party')

			this.setState({ isSearching: false })
		} else {
			resetSearch(true)
		}
	}

	handleChangeField = (field, value) => this.setState({ [field]: value })

	renderPlaceholderFio = () => {
		const { type } = this.state

		if (type === PERSON) return 'ФИО или номер телефона'
		else if (type === ENTREPRENEUR) return 'ИНН или имя предпринимателя'
		else if (type === ORGANIZATION) return 'ИНН или название ООО'
	}

	getRolesByType = () => {
		const {
			entity: { name },
			participantsData,
		} = this.props

		switch (name) {
			case ENTITY_TYPES.ORGANIZATION: {
				return ORG_ROLES
			}
			case ENTITY_TYPES.LEAD: {
				return LEAD_ROLES.filter((item) => item.id !== 'INVESTOR')
			}
			case ENTITY_TYPES.ROSREESTR_REGISTRATION: {
				return ROSREESTR_REGISTRATION_ROLES
			}
			case ENTITY_TYPES.APPLICATION: {
				return participantsData?.find(({ roles }) => roles.includes('APPLICANT'))
					? ROLES.filter(({ id }) => id !== 'APPLICANT')
					: ROLES
			}
			default:
				return ROLES.filter(({ id }) => id !== 'APPLICANT')
		}
	}

	render() {
		const { searchResults, addingProcess } = this.props
		const {
			type,
			subject,
			phone,
			roles,
			subjectOptions,
			query,
			isSearching,
			isEntrepreneur,
			isDelivery,
			isFacility,
		} = this.state

		const selectTypesOptions = {
			value: type,
			labelPlacing: 'out',
			options: subjectOptions,
			onChange: this.handleChangeType,
			disabled: this.generateDisabled(),
		}

		// Загоняем все результаты поиска (грязные данные) в функцию
		// далее она сфомирует всё необходимое и отдаст готовый результат
		// в виде обьекта { options: [], categories:[] }
		const autocompleteData = formatSearchData(type, searchResults)

		const autocompleteOptions = {
			selected: subject,
			parentQuery: query,
			...autocompleteData,
			replaceOptionsIfNotFound: type !== PERSON &&
				!!query.match(type === ORGANIZATION ? /^(\d{10})$/ : /^(\d{12})$/) &&
				!isSearching && (
					<AddOrganizationOrEntrepreneur type={type} onCreate={this.createEmptyParticipant} />
				),
			onReset: this.handleReset,
			onChange: this.handleChangeAutocomplete,
			placeholder: this.renderPlaceholderFio(),
			onSelectOption: this.handleChangeParticipant,
			disabled:
				this.generateDisabled() || (subject && subject.$$category === 'internal') || subject.empty,
		}

		const inputPhoneOptions = {
			value: phone,
			placeholder: 'Телефон',
			labelPlacing: 'out',
			maskTemplate: 'phone',
			onChange: ({ target }) => this.handleChangeField('phone', target.value),
			disabled: this.generateDisabled() || subject.$$category === 'internal',
		}

		const selectRolesOptions = {
			multi: true,
			value: roles,
			placeholder: 'Добавить роль',
			hideOptionsAfterSelect: false,
			labelPlacing: 'out',
			disabled: this.generateDisabled(),
			options: this.getRolesByType(),
			onChange: ({ value }) => this.handleChangeField('roles', value),
		}

		const addButtonOptions = {
			children: 'Добавить',
			type: 'button',
			onClick: this.handleAddParticipant,
			disabled: !this.checkСompleteness() || addingProcess,
		}

		return (
			<div className='participants-addform' ref={(el) => (this.nodeElement = el)}>
				<div data-test-id='participant-type-select' className='participants-addform__type'>
					<Select {...selectTypesOptions} />
				</div>
				<div data-test-id='participant-credentials' className='participants-addform__fio'>
					<Autocomplete {...autocompleteOptions} />
				</div>
				{type === PERSON && (
					<div data-test-id='participant-phone' className='participants-addform__phone'>
						<Input {...inputPhoneOptions} />
					</div>
				)}
				{!isEntrepreneur && !isDelivery && !isFacility && (
					<div data-test-id='participant-role' className='participants-addform__opt'>
						<Select {...selectRolesOptions} />
					</div>
				)}
				<div data-test-id='participant-add-btn' className='participants-addform__button'>
					<Button {...addButtonOptions} />
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	searchResults: state.search,
})

const mapDispatchToProps = {
	resetSearch,
	searchEveryplace,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddForm)
