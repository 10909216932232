import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Facility } from 'converters/facility'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'
import type { PayloadStatus } from 'types/redux'

export interface FacilityFilter {
	id: TUtils.Maybe<string[]>
	type: TUtils.Maybe<string[]>
	owners: TUtils.Maybe<string[]>
	address: TUtils.Maybe<string>
	minArea: TUtils.Maybe<number>
	maxArea: TUtils.Maybe<number>
	cadastralId: TUtils.Maybe<string>
}

const filter: FacilityFilter = {
	id: null,
	type: null,
	owners: null,
	address: null,
	minArea: null,
	maxArea: null,
	cadastralId: null,
}

const initialState = {
	data: [] as Facility.Main[],
	status: 'pending' as PayloadStatus,
	filter,
}

const list = createSlice({
	name: 'facility/list',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Facility.Main[]>) {
			state.data = payload
		},

		mergeFilter(state, { payload }: PayloadAction<Partial<FacilityFilter>>) {
			state.filter = Object.assign(state.filter, payload)
		},

		add(state, { payload }: PayloadAction<Facility.Main>) {
			state.data.push(payload)
		},

		delete(state, { payload }: PayloadAction<string>) {
			state.data = state.data.filter((item) => item.id !== payload)
		},

		update(state, { payload }: PayloadAction<{ id: string; value: Partial<Facility.Main> }>) {
			const index = state.data.findIndex(({ id }) => id === payload.id)

			state.data[index] = { ...state.data[index], ...payload.value }
		},

		setFilter: commonActions.setFilter(),
		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: facilityListReducerActions } = list
export default list.reducer
