import { useCallback } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { type CreditPolicyReport, creditPolicyReport } from 'converters/creditPolicyReport'
import { type ArrayHelpers, useFormikContext } from 'formik'
import { nanoid } from 'nanoid'

import { type ButtonProps, Button, Text } from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'

import type { FormValues } from './Form'
import { useFormCtx } from './helpers'
import { getMappedType } from './helpers'
import { Values } from './Values'

export type FieldsProps = {
	type: CreditPolicyReport.AdditionalRequest['type']
	entity: string
} & Pick<ArrayHelpers, 'push' | 'remove'>

const createValue = ({ type, entity }: Pick<FieldsProps, 'entity' | 'type'>) => {
	const [entityId, entityType] = entity.split('@')

	return creditPolicyReport.additionalRequest({
		id: nanoid(),
		type,
		evaluationEntity: { id: entityId, type: entityType },
	})
}

export const Fields = ({ type, entity, push, remove }: FieldsProps) => {
	const { isEditing } = useFormCtx()
	const formik = useFormikContext<FormValues>()
	const [entityId] = entity.split('@')
	const mappedType = getMappedType(type)
	const values = formik.values[mappedType].filter(
		({ evaluationEntity, type: t }) => evaluationEntity.id === entityId && type === t
	)

	const pushProps: ButtonProps = {
		type: 'button',
		size: 'inline',
		onClick: useCallback(() => push(createValue({ type, entity })), [entity, push, type]),
		variant: 'transparent-blue',
		styleOverride: { padding: 0, width: 'fit-content' },
	}

	if (!values.length && !isEditing) return null

	const pushText = {
		requiredActions: 'Добавить требование',
		conditions: 'Добавить условие',
		actualValue: 'Добавить фактическое значение',
		stopFactorMeeting: 'Добавить фактическое значение',
	}[mappedType]

	return (
		<Grid.Section>
			{values.map((data) => (
				<Values key={`${data.id}-${data.type}`} data={data} remove={remove} />
			))}
			{isEditing && (
				<Button {...pushProps}>
					<Grid.Row sizes='auto 1fr' gap='s' style={{ display: 'flex', alignItems: 'center' }}>
						<Icon icon='zmdi:plus-circle' />
						<Text as='span'>{pushText}</Text>
					</Grid.Row>
				</Button>
			)}
		</Grid.Section>
	)
}
