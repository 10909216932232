import cx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'

import { Flicker } from '@creditclubteam/kit/legacy-ui-components'

import './Context.scss'

const Context = ({ id, title, date, children, avatar, className, fetching, controls }) => (
	<div
		id={id && id}
		className={cx('history-item', {
			[className]: !!className,
		})}
	>
		<div className='history-item__avatar'>
			{fetching ? (
				<Flicker className='history-item__avatar-flicker' />
			) : avatar ? (
				avatar
			) : (
				avatar === null && <i className={cx('zmdi zmdi-account-box')} />
			)}
		</div>
		<div className='history-item__wrapper'>
			<div className='history-item__header'>
				<div className='history-item__header-title'>
					{fetching ? (
						<Flicker className='history-item__header-title-flicker' />
					) : title ? (
						title
					) : (
						'Без названия'
					)}
				</div>
				<div className='history-item__header-date'>{date ? moment(date).format('LT') : 'н/д'}</div>
				{controls && controls}
			</div>
			<div className='history-item__body'>{children && children}</div>
		</div>
	</div>
)

Context.propTypes = {
	id: PropTypes.string,
	fetching: PropTypes.bool,
	className: PropTypes.string,
	date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	controls: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default Context
