import {
	carryFileFromQueueToBucket,
	deleteFileInBucket,
	fileInspectorMainSetField,
	initMetadataForm,
	resetSelectedList,
	setBucketData,
	setFileBucketInfoAfterPost,
	toggleSelectionFiles,
} from 'actions/filesInspector'
import { addDownloadFileStatus, hideNotice, pushNotice } from 'actions/notices'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import { pipe } from 'ramda'
import { actions } from 'reducers/filesInspector/inspector'
import { actions as mainActions } from 'reducers/filesInspector/main'

import { downloadFile, fileUtils } from '@creditclubteam/helpers'
import { utils } from 'helpers'

export const resetInspector = actions.reset

export const removeFileInCategory = actions.removeFile

export const pushCategory = actions.pushCategory

// Получает массив файлов в категории по zoneId
export const getFilesList =
	(categoryId, isContextAction = false) =>
	(dispatch, getState) =>
		Array.from(
			!isContextAction
				? categoryId === 'bucket'
					? getState().filesInspector.bucket.files
					: getState().filesInspector.inspector.categories.find(
							(category) => category.id === categoryId
					  ).$$files
				: getState().filesInspector.bucket.files
		)

// Возращает все файлы такого же типа в своей категории
export const getSimilarFilesInCategory = (categoryId, file) => (dispatch, getState) => {
	const results = []

	// Берём все файлы из этой категории
	const filesList = Array.from(
		categoryId === 'bucket'
			? getState().filesInspector.bucket.files
			: dispatch(getFilesList(categoryId))
	)

	const restTypes = pipe(
		fileUtils.getFileExtension,
		fileUtils.getSimilarExtensions.bind(fileUtils)
	)(file.title)

	// Формируем список
	if (restTypes) {
		restTypes.forEach((type) => {
			const list = filesList.filter(
				(item) => fileUtils.getFileExtension(item.title) === type
			)

			if (utils.hasObjectLength(list)) {
				results.push(...list)
			}
		})
	} else {
		return false
	}

	return results
}

// Меняет индексы местами в одном списке
const reIndex = (categoryId, startIndex, endIndex) => (dispatch) => {
	const files = dispatch(getFilesList(categoryId))

	const [removed] = files.splice(startIndex, 1)
	files.splice(endIndex, 0, removed)

	dispatch(actions.setFiles({ categoryId, files }))
}

const moveFiles =
	({
		source,
		destination,
		draggableId,
		entityId,
		selectedFiles: _selectedFiles,
		metadata,
		isContextMenuAction = false,
	}) =>
	async (dispatch, getState) => {
		const selectedFiles = _selectedFiles ?? getState().filesInspector.main?.selectedFiles

		// Если взяли из корзины получаем данные из неё
		const fromList =
			source.droppableId === 'bucket'
				? Array.from(getState().filesInspector.bucket.files)
				: dispatch(getFilesList(source.droppableId))

		// Новый владелец документа, если тащим в корзину то владелец заявка
		const newOwnerId =
			typeof entityId !== 'string'
				? destination.droppableId === 'bucket'
					? getState().filesInspector.main.entityId
					: getState().filesInspector.main.currentParticipant
				: entityId

		// Старый владелец документа, если тащим из корзины то владелец заяка
		const ownerId =
			source.droppableId === 'bucket'
				? getState().filesInspector.main.entityId
				: getState().filesInspector.main.currentParticipant

		const toList = dispatch(getFilesList(destination.droppableId, typeof entityId === 'string'))
		const dispatchPostMoveFile = (documentId) =>
			dispatch(
				postMoveFile(
					documentId,
					isContextMenuAction,
					{
						categoryId: destination.droppableId,
						newOwnerId,
						ownerId,
					},
					metadata
				)
			)

		// Если выбранно несколько файлов
		if (utils.hasObjectLength(selectedFiles)) {
			selectedFiles.forEach((file) => {
				const itemIndex = fromList.findIndex((item) => item.id === file.id)

				const [removed] = fromList.splice(itemIndex, 1)

				const removedWithProcess = { ...removed, processing: true }

				toList.splice(destination.index, 0, removedWithProcess)
				// Отдаём серверу
				dispatchPostMoveFile(file.id)
			})
		}

		// Если выбран один файл
		else {
			const [removed] = fromList.splice(source.index, 1)

			const removedWithProcess = { ...removed, processing: true }

			toList.splice(destination.index, 0, removedWithProcess)
			// Отдаём серверу
			dispatchPostMoveFile(draggableId)
		}

		// Если после перемещения файл не нужно отображать
		if (typeof entityId === 'string') {
			return dispatch(setBucketData(fromList))
		}

		// Если взяли из корзины то записываем в неё новые данные
		if (source.droppableId === 'bucket') {
			dispatch(setBucketData(fromList))
		} else {
			dispatch(actions.setFiles({ categoryId: source.droppableId, files: fromList }))
		}

		dispatch(actions.setFiles({ categoryId: destination.droppableId, files: toList }))
	}

export const onDragStart = (results) => (dispatch, getState) => {
	const { updateFileId, selectedFiles } = getState().filesInspector.main

	dispatch(fileInspectorMainSetField({ field: 'dragging', value: true }))

	if (updateFileId) dispatch(fileInspectorMainSetField({ field: 'updateFileId', value: null }))

	if (results.draggableId) {
		const wasSelected = selectedFiles.find((file) => file.id === results.draggableId)

		if (!utils.hasObjectLength(wasSelected)) dispatch(toggleSelectionFiles())
	}
}

export const onRefreshFile = (file, zoneId) => (dispatch, getState) => {
	const { categories } = getState().filesInspector.inspector
	const categoryIndex = categories.findIndex((c) => c.id === zoneId)

	if (categoryIndex >= 0) {
		const fileIndex = categories[categoryIndex].$$files.findIndex((f) => f.id === file.id)

		if (fileIndex >= 0) {
			dispatch(actions.setFileUrl({ categoryId: zoneId, fileId: file.id, newUrl: file.url }))
		}
	}
}

export const onDragEnd =
	(results, entityId, isContextMenuAction = false) =>
	(dispatch, getState) => {
		dispatch(fileInspectorMainSetField({ field: 'dragging', value: false }))

		const { source, destination, draggableId } = results
		const { selectedFiles } = getState().filesInspector.main

		if (destination) {
			// Если работа была в одном списке
			if (source.droppableId === destination.droppableId) {
				if (source.droppableId !== 'bucket') {
					dispatch(reIndex(source.droppableId, source.index, destination.index))
				}

				if (selectedFiles.length > 0) dispatch(resetSelectedList())
			}

			// Если переносим в другой список
			else {
				const category = getState().filesInspector.inspector.categories.find(
					({ id }) => id === destination.droppableId
				)

				const handleMove = () => {
					dispatch(moveFiles({ ...results, entityId, isContextMenuAction }))

					if (selectedFiles.length > 0) dispatch(resetSelectedList())
				}

				if (category?.metadataFields) {
					dispatch(
						initMetadataForm({
							moveFilesParameters: {
								...results,
								entityId,
								isContextMenuAction,
							},
							destinationCategory: category,
							selectedFilesIds: selectedFiles.length
								? selectedFiles.map(({ id }) => id)
								: [draggableId],
						})
					)
				} else {
					handleMove()
				}
			}
		}

		// Когда dnd отработал есть вероятность что была загрузка
		// файлов, загруженные файлы в момент переноса попадают в очередь
		// настало время её проверить и переместить файлы на место
		const bucketQueue = getState().filesInspector.bucket.queue
		if (utils.hasObjectLength(bucketQueue))
			bucketQueue.forEach((file) => {
				dispatch(carryFileFromQueueToBucket(file))
			})
	}

export const deleteFile = (documentId, category) => (dispatch, getState) => {
	const { selectedFiles } = getState().filesInspector.main

	if (utils.hasObjectLength(selectedFiles)) {
		selectedFiles.forEach(({ id, zoneId }) => dispatch(removeFile(id, zoneId)))
		dispatch(toggleSelectionFiles())
	} else {
		dispatch(removeFile(documentId, category))
	}
}

export const onFileDownload = (fileUrl, fileTitle) => (dispatch, getState) => {
	const { selectedFiles } = getState().filesInspector.main

	const download = (url, id, title) => {
		return downloadFile({
			url,
			title,
			onError() {
				dispatch(
					addServerError({
						text: `Ошибка загрузки файла ${title}`,
					})
				)
				dispatch(hideNotice(id))
			},
			onDownloadProgress: (e) => dispatch(addDownloadFileStatus(e, id, title)),
		})
	}

	if (utils.hasObjectLength(selectedFiles)) {
		selectedFiles.forEach((file) => {
			const currentFile = dispatch(getFilesList(file.zoneId)).find((f) => f.id === file.id)

			if (currentFile) {
				download(currentFile.url, currentFile.id, currentFile.title)
			}
		})
	} else {
		download(fileUrl, fileUrl, fileTitle)
	}
}

export const returnFileToBucket = (zoneId, fileId, fileIndex) => (dispatch, getState) => {
	const { selectedFiles } = getState().filesInspector.main

	const ownerId = getState().filesInspector.main.currentParticipant
	const newOwnerId = getState().filesInspector.main.entityId

	const fromList = Array.from(dispatch(getFilesList(zoneId)))
	const bucketFiles = Array.from(getState().filesInspector.bucket.files)

	const dispatchPostMoveFile = (documentId) =>
		dispatch(
			postMoveFile(documentId, false, {
				categoryId: 'bucket',
				newOwnerId,
				ownerId,
			})
		)

	// Если выбранно несколько файлов
	if (utils.hasObjectLength(selectedFiles)) {
		selectedFiles.forEach((file) => {
			const itemIndex = fromList.findIndex((item) => item.id === file.id)

			const [removed] = fromList.splice(itemIndex, 1)

			const removedWithProcess = { ...removed, processing: true }

			bucketFiles.splice(0, 0, removedWithProcess)

			// Отдаём серверу
			dispatchPostMoveFile(file.id)
		})
	}

	// Если выбран один файл
	else {
		const [removed] = fromList.splice(fileIndex, 1)

		const removedWithProcess = { ...removed, processing: true }
		bucketFiles.splice(0, 0, removedWithProcess)

		// Отдаём серверу
		dispatchPostMoveFile(fileId)
	}

	dispatch(actions.setFiles({ categoryId: zoneId, files: fromList }))
	dispatch(setBucketData(bucketFiles))
}

const removeFile = (documentId, category) => (dispatch, getState) => {
	const { currentParticipant, entityId } = getState().filesInspector.main

	category === 'bucket'
		? dispatch(deleteFileInBucket(documentId))
		: dispatch(removeFileInCategory({ categoryId: category, fileId: documentId }))

	const ownerId = category === 'bucket' ? entityId : currentParticipant

	return api.document.delete(ownerId, documentId).then(
		(response) => response,
		() => {
			dispatch(
				addServerError({
					text: 'Ошибка удаления файла, изменения не будут сохранены',
				})
			)
		}
	)
}

export const fetchInspector = (ownerId, ownerType, sortFn) => async (dispatch) => {
	dispatch(actions.setField({ field: 'fetching', value: true }))

	return axios
		.all([
			api.document.getCategories({
				filter: {
					ownerTypes: [ownerType],
					out: 'ROSREESTR_DOCUMENTS',
				},
			}),
			api.document.search({
				filter: { owners: [ownerId] },
			}),
		])
		.then((res) => {
			let results = []
			const files = res[1].data.content
			const categories = res[0].data.content

			if (utils.hasObjectLength(categories)) {
				results = categories.map((category) => {
					let filesInCategory = files.filter((file) => file.categoryId === category.id)

					// Если файлы в категории есть то каждому
					// даём дополнительное свойство processing
					// для отслеживания того работает ли с ним сервер
					if (utils.hasObjectLength(filesInCategory)) {
						filesInCategory = filesInCategory.map((file) => ({
							...file,
							processing: false,
						}))
					}

					return {
						...category,
						metadataFields: category.metadataFields?.length ? category.metadataFields : null,
						$$files: filesInCategory,
					}
				})
			}

			dispatch(actions.setField({ field: 'categories', value: sortFn ? sortFn(results) : results }))
			dispatch(actions.setField({ field: 'fetching', value: false }))
		})
		.catch((error) => {
			dispatch(
				addServerError({
					text: `Ошибка загрузки испектора`,
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(actions.setField({ field: 'fetching', value: false }))
		})
}

const postMoveFile =
	(documentId, isContextmenuAction, request, metadata) => (dispatch, getState) => {
		return api.document
			.replace(documentId, request)
			.then(() => {
				;(async () => {
					try {
						const { data } = await api.document.get(documentId)

						if (isContextmenuAction) {
							return dispatch(
								pushNotice({
									message: `Файл ${data.title} перемещен!`,
								})
							)
						}

						if (request.categoryId === 'bucket') {
							dispatch(setFileBucketInfoAfterPost({ fileId: documentId, newTitle: data.title }))
						} else if (metadata) {
							api.document
								.updateMetadata(documentId, { metadata: metadata[documentId] })
								.then(async () => {
									const { data } = await api.document.get(documentId)

									dispatch(
										actions.setFileInfoAfterPost({
											categoryId: request.categoryId,
											fileId: documentId,
											newTitle: data.title,
											metadata: data.metadata,
										})
									)

									dispatch(mainActions.resetMetadata())
								})
						} else {
							dispatch(
								actions.setFileInfoAfterPost({
									categoryId: request.categoryId,
									fileId: documentId,
									newTitle: data.title,
								})
							)
						}
					} catch {
						//
					}
				})()
			})
			.catch(() => {
				dispatch(
					addServerError({
						text: 'Ошибка переноса файлов между категориями, изменения не будут сохранены',
					})
				)
			})
	}

export const updateFileName = (title, fileId, categoryId) => (dispatch) => {
	const fileCategory = dispatch(getFilesList(categoryId))
	const file = fileCategory.find((item) => item.id === fileId)

	const prevTitle = fileUtils.removeFileExtension(file.title)
	const newTitle = (title || '').trim()

	if (prevTitle === newTitle || !newTitle) {
		dispatch(fileInspectorMainSetField({ field: 'updateFileId', value: null }))
		return
	}

	const fileType = fileUtils.getFileExtension(file.title)
	const formatTitle = `${newTitle}.${fileType}`

	dispatch(actions.setFileInfoAfterPost({ categoryId, fileId, newTitle: formatTitle }))

	dispatch(fileInspectorMainSetField({ field: 'updateFileId', value: null }))

	return api.document.rename(fileId, newTitle).then(
		(response) => response,
		() => {
			dispatch(fileInspectorMainSetField({ field: 'updateFileId', value: null }))
			dispatch(
				addServerError({
					text: 'Ошибка изменения имени файла',
				})
			)
		}
	)
}

export const updateMetadataWithMovingFiles = (payload) => async (dispatch, getState) => {
	try {
		const { moveFilesParameters, files } = getState().filesInspector.main.metadata

		await dispatch(
			moveFiles({
				...moveFilesParameters,
				selectedFiles: files,
				metadata: payload,
			})
		)
	} catch (error) {
		dispatch(
			addServerError({
				text: `Ошибка при обновлении данных документа`,
				details: utils.getDetailsFromError(error),
			})
		)
	}
}
export const updateMetadataWithinCategory =
	({ id, metadata, categoryId }) =>
	async (dispatch) => {
		try {
			await api.document.updateMetadata(id, { metadata })

			const { data } = await api.document.get(id)

			dispatch(
				actions.setFileInfoAfterPost({
					categoryId,
					fileId: id,
					newTitle: data.title,
					metadata: data.metadata,
				})
			)

			dispatch(mainActions.resetMetadata())
		} catch (error) {
			dispatch(
				addServerError({
					text: `Ошибка при обновлении данных документа`,
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}
