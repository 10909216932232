export const getWorkerProfile = (metadata, workersList) => {
	let title = 'Неизвестный',
		avatarUrl = null

	if (metadata.workerId) {
		const worker = workersList?.data.find((w) => w.id === metadata.workerId)

		if (worker) {
			title = `${worker.surname} ${worker.name}`

			if (worker.avatar) {
				avatarUrl = worker.avatar
			}
		}
	} else if (metadata.author) {
		title = metadata.author
	}

	return {
		title,
		avatar: avatarUrl && <img alt={title} src={avatarUrl} />,
	}
}

export const extractMention = (text, data = []) => {
	const regex = /<\$([^>]+)>|<@([^>]+)>/g
	const _text = text.split(regex).filter(Boolean)

	return _text.map((word) => {
		const [, entityId] = word.split('|')

		const user = data.find(({ id }) => id === entityId || id === word)

		return user ?? word
	})
}
