import { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { recognizePassport } from 'actions/person'
import { addServerError } from 'actions/serverErrors'
import cx from 'clsx'
import moment from 'moment'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { Input } from '@creditclubteam/kit/legacy-ui-components'
import { utils } from 'helpers'

import Controls from './Controls'
import SignCountInfo from './SignCountInfo'

import './DocumentItem.scss'
import styles from './DocumentItem.module.scss'

const removeFileType = (value) => {
	if (typeof value !== 'string') return ''

	const typeAt = value.indexOf('.')

	return value.substr(0, typeAt)
}

class DocumentItem extends Component {
	static propTypes = {
		data: PropTypes.shape({
			id: PropTypes.string.isRequired,
			objectKey: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			signatures: PropTypes.array.isRequired,
		}),

		participants: PropTypes.shape({
			data: PropTypes.array.isRequired,
			fetching: PropTypes.bool.isRequired,
		}),

		allowSign: PropTypes.bool,
		isSelected: PropTypes.bool,
		isVirtualCategory: PropTypes.bool,
		filesList: PropTypes.array,
		index: PropTypes.number,
		ownerTypes: PropTypes.array,
		metadataFields: PropTypes.array,
		customers: PropTypes.array,
		customersForSimpleSignature: PropTypes.array,
		category: PropTypes.object,
		subTitle: PropTypes.string,
		constructorSpec: PropTypes.object,
		signable: PropTypes.bool.isRequired,
		allowCreateDocuments: PropTypes.bool,
		entityId: PropTypes.string.isRequired,
		entityType: PropTypes.string,

		onEdit: PropTypes.func,
		onDelete: PropTypes.func,
		onAddSigners: PropTypes.func,
		onClick: PropTypes.func.isRequired,
		getMetadata: PropTypes.func.isRequired,
		onSignersController: PropTypes.func,
		renameDocument: PropTypes.func.isRequired,
	}

	cancelablePromise = null

	state = {
		title: removeFileType(this.props.data.title),
		isRenamingTitle: false,
		fetching: {
			renamingTitle: false,
			recognizePassport: false,
		},
	}

	isDraggable = !this.props.isVirtualCategory

	componentDidUpdate(prevProps) {
		const { data } = this.props

		if (data.title !== prevProps.data.title) {
			this.setState({ title: removeFileType(data.title) })
		}
	}

	componentWillUnmount() {
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel()
		}
	}

	handleClick = (event) => {
		const { onClick, data } = this.props

		!this.isSignedByAll() && onClick(event, data.id)
	}

	handleSubmitRename = () => {
		const { title } = this.state
		const { data, renameDocument } = this.props

		const prevTitle = removeFileType(data.title)
		const newTitle = (title || '').trim()

		if (prevTitle === newTitle || !newTitle) {
			this.setState({ isRenamingTitle: false, title: prevTitle })
			return
		}

		this.setFetching('renamingTitle', true)

		this.cancelablePromise = utils.makeCancelable(renameDocument(data.id, newTitle))

		this.cancelablePromise.promise
			.then(() => {
				this.toggleNameChanging()
				this.setFetching('renamingTitle', false)
			})
			.catch((err) => {
				if (err && err.isCanceled) return

				this.setFetching('renamingTitle', false)
			})
	}

	defineAdditionalControls = (dragHandleProps) => {
		const {
			data,
			onEdit,
			ownerTypes,
			category,
			getMetadata,
			onAddSigners,
			metadataFields,
			signable,
			constructorSpec,
			allowCreateDocuments,
		} = this.props

		const { fetching } = this.state

		const actions = []

		if (this.isDraggable) {
			actions.push({
				tip: 'Перетащить',
				icon: (
					<div className={cx(styles.icon)} {...dragHandleProps}>
						<i className='zmdi zmdi-more' />
						<i className='zmdi zmdi-more' />
					</div>
				),
			})
		}

		if (metadataFields && !isEmpty(metadataFields)) {
			actions.push({
				tip: 'Данные документа',
				onClick: () =>
					getMetadata({ document: data, metadataFields, categoryId: category.id, mode: 'edit' }),
				icon: <i className='zmdi zmdi-assignment' />,
			})
		}

		const extensionLower = data.extension.toLowerCase()
		if (
			(onAddSigners && signable && (extensionLower === 'pdf' || extensionLower === 'xml')) ||
			extensionLower === 'zip'
		) {
			actions.push({
				tip: 'Отправить на подпись',
				onClick: () => onAddSigners(data),
				icon: <i className='zmdi zmdi-check-circle-u' />,
			})
		}

		if (allowCreateDocuments && onEdit && constructorSpec?.templateType) {
			actions.push({
				tip: 'Редактировать',
				onClick: () => onEdit(data.id, category),
				icon: <i className='zmdi zmdi-edit' />,
			})
		}

		if (category.id === 'PASSPORT_MAIN' && ownerTypes.includes('PERSON')) {
			actions.push({
				tip: fetching.recognizePassport ? 'Идет распознавание...' : 'Распознать',
				onClick: this.handleRecognizePassport,
				icon: fetching.recognizePassport ? (
					<i className='zmdi zmdi-spinner' />
				) : (
					<i className='zmdi zmdi-center-focus-strong' />
				),
			})
		}

		return actions
	}

	// PASSPORT BEGIN

	handleRecognizePassport = () => {
		const { data, addServerError, recognizePassport, entityId } = this.props

		const allowedExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'tiff']

		if (!allowedExtensions.includes(data.extension)) {
			addServerError({
				text: 'Неподдерживаемый формат документа. Распознание доступно для: pdf, png, jpg, jpeg, tiff',
				id: nanoid(),
			})

			return
		}

		this.setFetching('recognizePassport', true, this.defineAdditionalControls)

		recognizePassport({ documentId: data.id, entityId }).then(() =>
			this.setFetching('recognizePassport', false, this.defineAdditionalControls)
		)
	}

	// PASSPORT END

	// SIGN BEGIN

	handleSignersController = () => {
		const { data, onSignersController, signable, participants } = this.props

		const allow = isEmpty(
			[signable, !!onSignersController, !this.isSignedByAll(), !isEmpty(participants)].filter(
				(e) => !e
			)
		)

		allow && onSignersController(data.id)
	}

	// SIGN END

	// HELPERS BEGIN

	parseName = (person) => {
		const name = person.name ? `${person.name[0].toUpperCase()}.` : ''
		const surname = person.surname ? person.surname : ''
		const patronymic = person.patronymic ? `${person.patronymic[0].toUpperCase()}.` : ''

		return surname ? `${surname} ${name}${patronymic}`.trim() : utils.getFullName(person)
	}

	isSignedByAll = () => {
		const { data } = this.props

		if (isEmpty(data.signatures)) return false

		return !data.signatures.find((e) => !e.signAt)
	}

	isThereNoSign = () => {
		const { data } = this.props

		if (isEmpty(data.signatures)) return true

		return !data.signatures.find((e) => e.signAt)
	}

	setFetching = (param, value, callback) => {
		this.setState(
			{
				fetching: {
					...this.state.fetching,
					[param]: value,
				},
			},
			() => callback && callback()
		)
	}

	toggleNameChanging = () => {
		if (!this.props.isVirtualCategory) {
			this.setState(({ isRenamingTitle }) => ({ isRenamingTitle: !isRenamingTitle }))
		}
	}

	handleChangeName = (title) => {
		if (title.match(/[.,!@$%^&*{};'"?+=—~\]/\\|[<>]/g)) {
			return
		}

		this.setState({ title })
	}

	// HELPERS END

	render() {
		const {
			data,
			index,
			onEdit,
			onDelete,
			entityId,
			filesList,
			customers,
			isSelected,
			customersForSimpleSignature,
		} = this.props

		const { title, isRenamingTitle, fetching } = this.state

		const controlsOptions = {
			onEdit,
			onDelete,
			entityId,
			filesList,
			id: data.id,
			objectKey: data.objectKey,
			title: data.title,
			SIGFiles: data.signatures.filter((item) => !!item.objectKey).map((item) => item.objectKey),
		}

		const isSignedByAll = this.isSignedByAll()

		const inputProps = {
			value: title,
			autoFocus: true,
			placeholder: 'Имя документа',
			onBlur: this.handleSubmitRename,
			disabled: fetching.renamingTitle,
			onChange: this.handleChangeName,
			className: 'document-module-files-category__item-input',
			onKeyUp: (e) => e.key === 'Enter' && this.handleSubmitRename(),
		}

		return (
			<Draggable isDragDisabled={!this.isDraggable} index={index} draggableId={data.id}>
				{(provided) => (
					<div
						id={data.id}
						ref={provided.innerRef}
						{...provided.draggableProps}
						onClick={this.handleClick}
						className={cx('document-module-files-category__item', {
							isSelected,
						})}
					>
						<div
							className={cx('document-module-files-category__item-title', {
								isRenamingTitle,
							})}
						>
							{isRenamingTitle ? (
								<Input {...inputProps} />
							) : (
								<div className='document-module-files-category__item-title-text'>
									<span onClick={this.toggleNameChanging}>
										{`${data.title.split('.')[0]} от ${moment(data.createdAt).format(
											'DD MMMM YYYY'
										)}`}
									</span>

									{!isEmpty(data.signatures) && isSignedByAll && (
										<div className='document-module-files-category__item-sign'>
											<i
												className={cx('zmdi', {
													'zmdi-check-circle': isSignedByAll,
												})}
											/>
										</div>
									)}
								</div>
							)}
							{provided.placeholder}
						</div>
						{!isRenamingTitle && (
							<Controls
								{...controlsOptions}
								additionalControls={this.defineAdditionalControls(provided.dragHandleProps)}
							/>
						)}
						{!isRenamingTitle && (
							<SignCountInfo
								documentId={data.id}
								customers={customers || []}
								signatures={data.signatures}
								simpleSignatures={data.simpleSignatures}
								customersForSimpleSignature={customersForSimpleSignature}
							/>
						)}
					</div>
				)}
			</Draggable>
		)
	}
}

const mapDispatchToPros = {
	recognizePassport,
	addServerError,
}

export default connect(null, mapDispatchToPros)(DocumentItem)
