import { connectRouter } from 'connected-react-router'
import type { History } from 'history'
import { combineReducers } from 'redux'

import application from './application'
import assessment from './assessment'
import bonusApplication from './bonusApplication'
import breadcrumbs from './breadcrumbs'
import calculatorSchedule from './calculatorSchedule'
import cashbackPayment from './cashbackPayment'
import court from './court'
import creditPolicyReport from './creditPolicyReport'
import creditProgram from './creditProgram'
import decision from './decision'
import delivery from './delivery'
import deposit from './deposit'
import dictionary from './dictionary'
import document from './document'
import entrepreneur from './entrepreneur'
import externalData from './externalData'
import facility from './facility'
import filesInspector from './filesInspector'
import filesViewer from './filesViewer'
import financialData from './financialData'
import history from './history'
import insurance from './insurance'
import interceptor from './interceptor'
import interestPayment from './interestPayment'
import investmentLead from './investmentLead'
import lead from './lead'
import listParameters from './listParameters'
import loan from './loan'
import loanSellApplication from './loanSellApplication'
import localFilters from './localFilters'
import microsoft from './microsoft'
import modalDialogs from './modalDialogs'
import moneyMovements from './moneyMovements'
import notices from './notices'
import organization from './organization'
import partner from './partner'
import person from './person'
import pipeline from './pipeline'
import refinancing from './refinancing'
import representative from './representative'
import rosreestr from './rosreestr'
import rosreestrRegistration from './rosreestrRegistration'
import search from './search'
import serverErrors from './serverErrors'
import sign from './sign'
import uploadFiles from './uplaodFiles'
import worker from './worker'

export const reducers = {
	person,
	cashbackPayment,
	organization,
	entrepreneur,
	facility,
	investmentLead,
	interestPayment,
	decision,
	partner,
	lead,
	application,
	loan,
	rosreestrRegistration,
	deposit,
	delivery,
	dictionary,
	rosreestr,
	pipeline,
	worker,
	search,
	refinancing,
	notices,
	history,
	court,
	document,
	creditPolicyReport,
	creditProgram,
	uploadFiles,
	breadcrumbs,
	microsoft,
	assessment,
	filesViewer,
	bonusApplication,
	interceptor,
	localFilters,
	modalDialogs,
	serverErrors,
	filesInspector,
	listParameters,
	calculatorSchedule,
	representative,
	loanSellApplication,
	insurance,
	financialData,
	moneyMovements,
	sign,
	externalData,
}

const rootReducer = (browserHistory: History) =>
	combineReducers({
		...reducers,
		router: connectRouter(browserHistory),
	})

export default rootReducer
