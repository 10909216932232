import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	default: false,
	confirm: false,
	conflict: false,
	brokerForm: false,
	addSigner: false,
	addFacility: false,
	addCategory: false,
	incompleteData: false,
	requestDocsForm: false,
	financialProduct: false,
	fullNameConflict: false,
	signersController: false,
	calculatorSchedule: false,
	documentConstructor: false,
}

type State = typeof initialState

const modalDialogs = createSlice({
	name: 'modalDialogs',
	initialState,
	reducers: {
		setShow(
			state,
			{ payload: { dialog, show } }: PayloadAction<{ dialog: keyof State; show: boolean }>
		) {
			state[dialog] = show
		},
	},
})

const { actions } = modalDialogs

export { actions }
export default modalDialogs.reducer
