import { setDefaultApplicationFilter } from 'actions/application/list'
import { getApplicationWorkers } from 'actions/application/workers'
import { decisionActions } from 'actions/decision'
import {
	fetchCategories,
	fetchFiles,
	resetDocumentCategories,
	resetDocumentFiles,
} from 'actions/document'
import { fetchEntrepreneursWithMerge, resetEntrepreneurList } from 'actions/entrepreneur'
import { fetchFacilitiesWithMerge, resetFacilityList } from 'actions/facility'
import { resetFilesInspector } from 'actions/filesInspector'
import { resetHistory } from 'actions/history'
import { interceptor } from 'actions/interceptor'
import { fetchOrganizationsWithMerge, resetOrganizationList } from 'actions/organization'
import { fetchPersonsWithMerge, resetPersonList } from 'actions/person'
import { getPipeline } from 'actions/pipeline'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { push } from 'connected-react-router'
import { OWNER_TYPES } from 'const/ownerTypes'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import { saveAs } from 'file-saver'
import { isEmpty } from 'ramda'
import { actions } from 'reducers/application/single'
import { applicationWorkersSelector } from 'reducers/application/workers'
import url from 'routes/urls'

import { utils } from 'helpers'

import { getCreditProgram } from '../creditProgram'
import { getInsurance } from '../insurance'

const applicationSingleResolveNextStatus = actions.resolveNextStatus

export const resetApplicationPage = () => (dispatch) => {
	dispatch(actions.reset())
	dispatch(resetHistory())
	dispatch(resetPersonList())
	dispatch(resetFacilityList())
	dispatch(resetDocumentFiles())
	dispatch(resetFilesInspector())
	dispatch(resetEntrepreneurList())
	dispatch(resetOrganizationList())
	dispatch(resetDocumentCategories())
}

export const getApplicationSingle = (id) => (dispatch, getState) => {
	dispatch(actions.setFetchingStatus(true))
	dispatch(actions.setFetchingByField({ field: 'fetchingParticipants', value: true }))

	return api.application
		.get(id)
		.then(async ({ data }) => {
			const pipeline = getState().pipeline.statuses.application
			const { participants, facilities } = data
			const workers = applicationWorkersSelector.selectIds(getState())

			const persons = participants.filter(({ type }) => type === PARTICIPANTS_TYPES.PERSON)
			const organizations = participants.filter(
				({ type }) => type === PARTICIPANTS_TYPES.ORGANIZATION
			)
			const entrepreneurs = participants.filter(
				({ type }) => type === PARTICIPANTS_TYPES.ENTREPRENEUR
			)

			await dispatch(getCreditProgram(data.calculationV2?.creditProgramId))
			await dispatch(getInsurance())
			await dispatch(decisionActions.main.get({ entity: 'applications', entityId: id }))

			dispatch(actions.setData(data))
			dispatch(actions.setFetchingStatus(false))

			if (isEmpty(workers)) {
				await dispatch(getApplicationWorkers())
			}

			if (isEmpty(pipeline)) {
				await dispatch(getPipeline('application'))

				dispatch(setDefaultApplicationFilter())
			}

			// Получаем и записываем документы с категориями
			dispatch(fetchCategories([OWNER_TYPES.APPLICATION]))
			dispatch(fetchFiles(id))

			// Собираем и записываем участников по их местам в сторе
			await Promise.all([
				dispatch(fetchPersonsWithMerge(persons)),
				dispatch(fetchFacilitiesWithMerge(facilities)),
				dispatch(fetchOrganizationsWithMerge(organizations)),
				dispatch(fetchEntrepreneursWithMerge(entrepreneurs)),
			]).finally(() =>
				dispatch(actions.setFetchingByField({ field: 'fetchingParticipants', value: false }))
			)

			return data
		})
		.catch((error) => {
			dispatch(actions.setFetchingStatus(false))
			dispatch(
				addServerError({
					text: 'Не удалось загрузить заявку',
					details: utils.getDetailsFromError(error),
				})
			)
		})
}

export const applicationResolveNextStatus = (id, request) => (dispatch, getState) => {
	return api.application
		.resolveNextStatus(id, request)
		.then(async (response) => {
			const application = getState().application.single.data
			const nextStatusID = utils.take(response, 'data.id', '')

			const resolveNextStatus = () => {
				dispatch(applicationSingleResolveNextStatus(response.data))

				return response
			}

			/**
			 * Если заявка перешла в займ
			 * осуществляем редирект на займ, но бывают случаи когда займ не создался сразу
			 * поэтому сначала проверяем что он есть
			 */
			if (nextStatusID === 'LOAN') {
				// eslint-disable-next-line no-async-promise-executor
				const goToLoan = new Promise(async (resolve, reject) => {
					await api.application
						.get(application.id)
						.then(({ data }) => {
							const loanId = utils.take(data, 'loan.id')

							if (loanId) {
								setTimeout(() => {
									api.loan
										.get(loanId)
										.then(({ data }) => {
											dispatch(push(url.loan.path(data.id)))
											resolve()
										})
										.catch(reject)
								}, 1000)
							} else {
								reject()
							}
						})
						.catch(reject)
				})

				await goToLoan.catch(resolveNextStatus)
			} else {
				return resolveNextStatus()
			}
		})
		.catch((error) => {
			if (
				error.response?.data?.status === 400 &&
				error.response?.data?.errorCode?.includes('VALIDATION')
			) {
				dispatch(interceptor('incompleteData', error.response.data.errors))
			} else {
				dispatch(
					addServerError({
						text: 'Не удалось сменить статус',
						details: utils.getDetailsFromError(error),
					})
				)
			}

			throw error
		})
}

export const updateApplication = (form) => async (dispatch, getState) => {
	const application = getState().application.single.data

	try {
		await api.application.update(application.id, form)

		try {
			const { data } = await api.application.get(application.id)

			dispatch(actions.setData(data))
		} catch {
			//
		}
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось обновить заявку',
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const changeFinancialProduct =
	({ financialProduct, firstPayment }) =>
	(dispatch) => {
		dispatch(actions.updateData({ financialProduct, firstPayment }))
	}

export const fetchDeliveries = (id) => async (dispatch) => {
	try {
		const { data } = await api.delivery.search({
			page: 0,
			size: 100,
			sort: 'desc',
			filter: { externalId: id },
		})

		dispatch(actions.setDeliveries(data.content))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось загрузить доставки для заявки',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

export const uploadSchedule = (payload) => async (dispatch, getState) => {
	try {
		const id = payload.id ?? getState().application.single.data.id

		const { data } = await api.application.uploadSchedule(id, payload)

		dispatch(actions.updatePreliminarySchedule(data))
		dispatch(getApplicationSingle(id))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось загрузить график платежей',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

export const requestSchedule = (payload) => async (dispatch, getState) => {
	try {
		const id = payload.id ?? getState().application.single.data.id
		await api.application.requestSchedule(id, payload.date)
		dispatch(getApplicationSingle(id))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось запросить график платежей',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

export const deleteSchedule = (id) => async (dispatch, getState) => {
	try {
		await api.application.deleteSchedule(id ?? getState().application.single.data.id)

		dispatch(actions.updateData({ preliminarySchedule: null }))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось удалить график платежей',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

export const getSchedule = (id) => async (dispatch, getState) => {
	try {
		const { data } = await api.application.getSchedule(id ?? getState().application.single.data.id)

		dispatch(actions.updatePreliminarySchedule(data))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось удалить график платежей',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

export const sendApplicationEsiaAuthLink = (payload) => async (dispatch) => {
	try {
		await api.application.sendEsiaAuthLink(payload)
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось отправить ссылку на верификацию',
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const downloadFinPosition = (id) => async (dispatch) => {
	try {
		const { data } = await api.application.getFinPosition(id)

		saveAs(data, 'Финансовое положение')
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось скачать данные для Фин. положения',
				details: await utils.getDetailsFromErrorBlob(error),
			})
		)
	}
}

export const downloadPaymentSchedule = (id) => async (dispatch) => {
	try {
		const { data } = await api.finalDecision.getApplicationPaymentSchedule(id)

		saveAs(data, 'Предварительный график платежей')
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось скачать предварительный график платежей',
				details: await utils.getDetailsFromErrorBlob(error),
			})
		)
	}
}
