import { type ErrorInfo, Component } from 'react'
import CriticalError from 'components/Layout/CriticalError'
import type { Maybe } from 'types/util'

export class ErrorBoundary extends Component<
	{},
	{ error: Maybe<Error>; isError: boolean; errorInfo: Maybe<ErrorInfo> }
> {
	state = {
		isError: false,
		error: null,
		errorInfo: null,
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		// eslint-disable-next-line no-console
		console.dir({ error, errorInfo })

		this.setState({ isError: true, error, errorInfo })
	}

	render() {
		const { children } = this.props
		const { isError, error, errorInfo } = this.state

		return <>{isError ? <CriticalError error={error} errorInfo={errorInfo} /> : children}</>
	}
}
