import { type SelectProps, Select } from '@creditclubteam/kit/legacy-ui-components'

interface ConfidantSelectProps {
	onChange: SelectProps['onChange']
	value: string
	options: SelectProps['options']
	mode?: SelectProps['mode']
	disabled?: boolean
	title?: string
}

export const ConfidantSelect = ({
	onChange,
	value,
	options,
	disabled,
	title,
	mode,
}: ConfidantSelectProps): JSX.Element => {
	const _onChange: typeof onChange = (v) => onChange(v.id)
	return (
		<Select
			onChange={_onChange}
			value={value}
			options={options}
			disabled={disabled}
			title={title}
			mode={mode}
		/>
	)
}
