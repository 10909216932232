import type { ScoringDocuments } from 'converters/scoring/person/documents/types'
import type { TUtils } from 'types'

import { date, moneyUtils } from '@creditclubteam/helpers'
import type { SelectOption } from '@creditclubteam/kit/ui-components'
import { DefaultValues } from 'helpers/convertHelper'

const nameOptions: SelectOption[] = [
	{
		id: '0401',
		label:
			'Пособие по временной нетрудоспособности в связи с несчастным случаем на производстве или профессиональным заболеванием',
		payload: { code: '0401' },
	},
	{ id: '0402', label: 'Пособие по временной нетрудоспособности', payload: { code: '0402' } },
	{ id: '0403', label: 'Пособие по беременности и родам', payload: { code: '0403' } },
	{ id: '0406', label: 'Ежемесячное пособие по уходу за ребенком', payload: { code: '0406' } },
	{ id: '0409', label: 'Пособие по безработице', payload: { code: '0409' } },
	{
		id: '0410',
		label:
			'Стипендия в период прохождения профессионального обучения и получения дополнительного профессионального образования по направлению органов службы занятости',
		payload: { code: '0410' },
	},
	{
		id: '0411',
		label:
			'Материальная помощь безработным гражданам, утратившим право на пособие по безработице в связи с истечением установленного периода его выплаты и гражданам в период профессионального обучения и получения дополнительного профессионального образования по направлению органов службы занятости',
		payload: { code: '0411' },
	},
	{
		id: '0412',
		label:
			'Финансовая поддержка безработным гражданам при переезде в другую местность для трудоустройства по направлению органов службы занятости, а также безработным гражданам и членам их семей при переселении в другую местность на новое место жительства для трудоустройства по направлению органов службы занятости',
		payload: { code: '0412' },
	},
	{
		id: '0414',
		label:
			'Дополнительное ежемесячное материальное обеспечение (ДЕМО) инвалидов вследствие военной травмы',
		payload: { code: '0414' },
	},
	{
		id: '0415',
		label:
			'Дополнительное ежемесячное материальное обеспечение (дополнительное материальное обеспечение)  (ДЕМО) граждан Российской Федерации за выдающиеся достижения и особые заслуги перед Российской Федерацией',
		payload: { code: '0415' },
	},
	{
		id: '0417',
		label:
			'Дополнительное ежемесячное пожизненное материальное обеспечение (дополнительное обеспечение) специалистов ядерного оружейного комплекса',
		payload: { code: '0417' },
	},
	{
		id: '0419',
		label:
			'Доплата до размера прежнего заработка при переводе по медицинским показаниям на нижеоплачиваемую работу до восстановления трудоспособности или до установления инвалидности',
		payload: { code: '0419' },
	},
	{
		id: '0420',
		label:
			'Выплата пособия по временной нетрудоспособности в размере 100 процентов среднего заработка, учитываемого при начислении страховых взносов на обязательное социальное страхование на случай временной нетрудоспособности и в связи с материнством в Фонд социального страхования Российской Федерации, независимо от продолжительности страхового стажа, в том числе при направлении на врачебную консультацию в другой населенный пункт',
		payload: { code: '0420' },
	},
	{
		id: '0422',
		label:
			'Дополнительное (ежемесячное) пособие гражданам, постоянно проживающим на территории зоны радиоактивного загрязнения вследствие катастрофы на Чернобыльской АЭС и зарегистрированным в установленном порядке в качестве безработных',
		payload: { code: '0422' },
	},
	{
		id: '0426',
		label: 'Ежемесячное пособие на ребенка военнослужащего, проходящего военную службу по призыву',
		payload: { code: '0426' },
	},
	{ id: '0429', label: 'Ежемесячное социальное пособие', payload: { code: '0429' } },
	{ id: '0430', label: 'Ежемесячное пособие на ребенка', payload: { code: '0430' } },
	{ id: '0431', label: 'Ежемесячное пособие на питание', payload: { code: '0431' } },
	{
		id: '0433',
		label:
			'Ежемесячное пособие на питание ребенка в государственной или муниципальной образовательной организации',
		payload: { code: '0433' },
	},
	{
		id: '0434',
		label: 'Ежемесячная доплата по уходу за ребенком-инвалидом',
		payload: { code: '0434' },
	},
	{
		id: '0435',
		label: 'Ежемесячное социальное пособие по социальному контракту',
		payload: { code: '0435' },
	},
	{
		id: '0439',
		label:
			'Ежемесячное пособие детям военнослужащих сотрудников некоторых федеральных органов исполнительной власти, погибших (умерших, объявленных умершими, признанных безвестно отсутствующими) при исполнении обязанностей военной службы (служебных обязанностей) и детям лиц, умерших вследствие военной травмы после увольнения с военной службы (службы в органах и учреждениях) в части сотрудников, пенсионное обеспечение которых осуществляется Пенсионным фондом Российской Федерации',
		payload: { code: '0439' },
	},
	{
		id: '0444',
		label:
			'Ежемесячная выплата при рождении третьего ребенка или последующих детей до достижения ребенком возраста трех лет',
		payload: { code: '0444' },
	},
	{
		id: '0445',
		label:
			'Выплата на содержание детей-сирот и детей, оставшихся без попечения родителей в семье опекуна (попечителя), приемной семье',
		payload: { code: '0445' },
	},
	{
		id: '0447',
		label:
			'Ежемесячное пособие (вознаграждение) гражданам, усыновившим детей/принявшим детей на патронатное воспитание',
		payload: { code: '0447' },
	},
	{ id: '0448', label: 'Ежемесячная денежная выплата', payload: { code: '0448' } },
	{
		id: '0449',
		label: 'Ежемесячное пособие на ребенка одинокой матери',
		payload: { code: '0449' },
	},
	{
		id: '0450',
		label: 'Ежемесячное пособие на детей военнослужащих, проходящих военную службу по призыву',
		payload: { code: '0450' },
	},
	{
		id: '0451',
		label: 'Ежемесячное пособие на детей  разыскиваемых родителей',
		payload: { code: '0451' },
	},
	{
		id: '0453',
		label:
			'Доплата к пенсии гражданам, имеющим особые заслуги перед Российской Федерацией и субъектом Российской Федерации',
		payload: { code: '0453' },
	},
	{
		id: '0454',
		label:
			'Пособие на проведение летнего оздоровительного отдыха детей отдельных категорий военнослужащих и сотрудников некоторых федеральных органов исполнительной власти, погибших (умерших), пропавших без вести, ставших инвалидами в связи с выполнением задач в условиях вооруженного конфликта немеждународного характера в Чеченской Республике и на непосредственно прилегающих к ней территориях Северного Кавказа, отнесенных к зоне вооруженного конфликта, а также в связи с выполнением задач в ходе контртеррористических операций на территории Северо-Кавказского региона',
		payload: { code: '0454' },
	},
	{
		id: '0455',
		label:
			'Финансовая поддержка гражданам, признанным в установленном порядке безработными, направленным органами службы занятости для прохождения профессионального обучения или получения дополнительного профессионального образования в другую местность',
		payload: { code: '0455' },
	},
	{
		id: '0457',
		label:
			'Материальная поддержка безработным гражданам, несовершеннолетним гражданам в возрасте от 14 до 18 лет в период участия в оплачиваемых общественных работах, временном трудоустройстве',
		payload: { code: '0457' },
	},
	{
		id: '0458',
		label:
			'Оплата четырех дополнительных выходных дней в месяц одному из работающих родителей (опекуну, попечителю) для ухода за детьми - инвалидами',
		payload: { code: '0458' },
	},
	{
		id: '0461',
		label:
			'Пожизненное материальное обеспечение ветеранам ВОВ, проживающим в Латвии, Литве и Эстонии (ПМО)',
		payload: { code: '0461' },
	},
	{
		id: '0462',
		label:
			'Ежемесячная доплата к государственной пенсии граждан Российской Федерации из числа бывших сотрудников международных организаций системы Организации Объединенных Наций',
		payload: { code: '0462' },
	},
	{
		id: '0463',
		label:
			'Ежемесячная доплата к трудовой пенсии по старости (по инвалидности) отдельным категориям граждан (на основании поручения ПФР)',
		payload: { code: '0463' },
	},
	{
		id: '0464',
		label: 'Ежемесячная доплата к пенсии гражданам, проживающим в Абхазии',
		payload: { code: '0464' },
	},
	{ id: '0465', label: 'Ежемесячная доплата к пенсии', payload: { code: '0465' } },
	{ id: '0466', label: 'Выплата на приобретение продуктов питания', payload: { code: '0466' } },
	{
		id: '0467',
		label: 'Пособие работникам организаций социальной сферы',
		payload: { code: '0467' },
	},
	{ id: '0468', label: 'Ежеквартальная выплата на ребенка', payload: { code: '0468' } },
	{
		id: '0472',
		label: 'Социальная выплата для приобретения (строительства) жилья',
		payload: { code: '0472' },
	},
	{ id: '0473', label: 'Ежеквартальная выплата', payload: { code: '0473' } },
	{ id: '0474', label: 'Ежемесячная специальная стипендия', payload: { code: '0474' } },
	{ id: '0476', label: 'Ежемесячная региональная выплата', payload: { code: '0476' } },
	{
		id: '0477',
		label:
			'Осуществление доплаты одному из приемных родителей в приемной семье, имеющей в пользовании личный автотранспорт и воспитывающей не менее пяти приемных детей, помимо родных детей, за совмещение должности водителя',
		payload: { code: '0477' },
	},
	{
		id: '0478',
		label:
			'Ежемесячная выплата по содержанию обучающихся в общеобразовательных учреждениях в части обеспечения питанием, одеждой, обувью, другими предметами вещевого довольствия',
		payload: { code: '0478' },
	},
	{
		id: '0479',
		label: 'Дополнительное ежемесячное денежное содержание',
		payload: { code: '0479' },
	},
	{
		id: '0480',
		label: 'Пожизненное ежемесячное материальное вознаграждение',
		payload: { code: '0480' },
	},
	{
		id: '0481',
		label:
			'Выплата недополученных денежных средств лицам, являвшимся получателями пенсий, назначенных им в связи с прохождением военной службы или приравненной к ней по пенсионному обеспечению службы',
		payload: { code: '0481' },
	},
	{
		id: '0482',
		label: 'Социальная выплата на приобретение автотранспорта или сельскохозяйственной техники',
		payload: { code: '0482' },
	},
	{
		id: '0483',
		label: 'Доплата к стипендии в период профессионального обучения',
		payload: { code: '0483' },
	},
	{
		id: '0484',
		label: 'Доплата к должностному окладу за фактически отработанное время',
		payload: { code: '0484' },
	},
	{ id: '0485', label: 'Государственная социальная стипендия', payload: { code: '0485' } },
	{
		id: '0486',
		label:
			'Ежемесячная денежная выплата на детей, не посещающих государственные или муниципальные дошкольные образовательные организации',
		payload: { code: '0486' },
	},
	{
		id: '0487',
		label: 'Ежемесячная социальная выплата на дополнительное лекарственное обеспечение',
		payload: { code: '0487' },
	},
	{
		id: '0488',
		label:
			'Ежемесячная денежная выплаты на приобретение продуктов питания, одежды и обуви, мягкого инвентаря и оборудования',
		payload: { code: '0488' },
	},
	{
		id: '0489',
		label: 'Денежная выплата на приобретение твёрдого топлива (при наличии печного отопления)',
		payload: { code: '0489' },
	},
	{
		id: '0490',
		label:
			'Денежная выплата на проезд к месту нахождения лечебного учреждения для проведения пренатальной (дородовой) диагностики нарушений развития ребёнка и обратно',
		payload: { code: '0490' },
	},
	{
		id: '0495',
		label:
			'Региональная компенсационная выплата потерявшим работу в связи с коронавирусной инфекцией (COVID-19)',
		payload: { code: '0495' },
	},
	{
		id: '0496',
		label:
			'Компенсация  части платы за коммунальные услуги  размер которой превышает значения предельных (максимальных) индексов',
		payload: { code: '0496' },
	},
	{
		id: '4403',
		label: 'Дополнительное ежемесячное пособие по уходу за ребенком-инвалидом',
		payload: { code: '4403' },
	},
	{
		id: '4404',
		label:
			'Специальная социальная выплата работникам медицинских организаций, в том числе в связи с распространением новой коронавирусной инфекции (COVID-2019)',
		payload: { code: '4404' },
	},
	{
		id: '4407',
		label:
			'Специальная социальная выплата работникам социальных организаций в связи с распространением новой коронавирусной инфекции (COVID-2019)',
		payload: { code: '4407' },
	},
	{
		id: '4409',
		label: 'Ежемесячная денежная выплата на оплату жилого помещения и коммунальных услуг',
		payload: { code: '4409' },
	},
	{
		id: '4410',
		label:
			'Ежемесячная специальная выплата гражданам, принявшим на сопровождаемое или временное проживание (под временную опеку) инвалидов, престарелых граждан, детей-сирот и детей, оставшихся без попечения родителей',
		payload: { code: '4410' },
	},
	{
		id: '4411',
		label:
			'Дополнительное ежемесячное материальное обеспечение (ДЕМО) лицам, замещавшим должности торговых представителей Союза ССР и торговых представителей Российской Федерации',
		payload: { code: '4411' },
	},
	{
		id: '4413',
		label:
			'Ежемесячное пособие членам семей умершего сенатора Совета Федерации или депутата Государственной Думы Федерального собрания Российской Федерации',
		payload: { code: '4413' },
	},
	{
		id: '4414',
		label:
			'Оплата образовательной организации стоимости обучения граждан в возрасте старше 50 лет, не зарегистрированных в органах службы занятости в качестве безработных, а также выплата суточных гражданам в возрасте старше 50 лет за период обучения',
		payload: { code: '4414' },
	},
	{
		id: '4419',
		label: 'Ежемесячное пособие на ребенка в возрасте от 8 до 17 лет',
		payload: { code: '4419' },
	},
	{
		id: '4420',
		label:
			'Ежемесячное пособие женщинам, вставшим на учет в медицинских организациях в ранние сроки беременности',
		payload: { code: '4420' },
	},
	{
		id: '4421',
		label: 'Пособие на оплату проезда (кроме проезда на такси)',
		payload: { code: '4421' },
	},
	{
		id: '4425',
		label:
			'Дополнительная мера социальной поддержки работникам государственных учреждений здравоохранения',
		payload: { code: '4425' },
	},
	{
		id: '4426',
		label: 'Выплата ежемесячного пожизненного содержания судьям',
		payload: { code: '4426' },
	},
	{
		id: '4428',
		label:
			'Ежемесячная выплата на каждого ребенка до достижения им возраста 3 лет гражданам, подвергшимся воздействию радиации вследствие катастрофы на Чернобыльской АЭС ',
		payload: { code: '4428' },
	},
	{
		id: '4429',
		label:
			'Пособие на погребение  членам семей или лицам, взявшим на себя организацию похорон граждан, погибших в результате катастрофы на Чернобыльской АЭС, умерших вследствие лучевой болезни и других заболеваний, возникших в связи с чернобыльской катастрофой, а также умерших граждан из числа инвалидов вследствие чернобыльской катастрофы ',
		payload: { code: '4429' },
	},
	{
		id: '4431',
		label:
			'Материальная помощь гражданам, которым государствами-членами Европейского союза отказано в установлении пенсий за стаж, приобретенный на их территориях',
		payload: { code: '4431' },
	},
	{
		id: '4432',
		label: 'Ежемесячная денежная выплата на ребенка в возрасте от 8 до 17 лет',
		payload: { code: '4432' },
	},
	{
		id: '4435',
		label: 'Выплата районного материнского (семейного) капитала',
		payload: { code: '4435' },
	},
	{
		id: '4437',
		label:
			'Ежемесячная денежная выплата семьям при рождении (усыновлении) второго ребенка, в период, начиная с 1 января 2022 года по 30 ноября 2024 года включительно',
		payload: { code: '4437' },
	},
	{
		id: '4438',
		label:
			'Ежемесячная социальная выплата спасателям городских аварийно-спасательных служб и формирований, пожарным и иным работникам противопожарной службы , не получающим пенсию',
		payload: { code: '4438' },
	},
	{
		id: '4440',
		label:
			'Ежеквартальная денежная выплата сотрудникам правоохранительных органов и работникам военных организаций ',
		payload: { code: '4440' },
	},
	{
		id: '4445',
		label: 'Ежемесячная социальная выплата в размере 10 000 рублей',
		payload: { code: '4445' },
	},
	{
		id: '4454',
		label: 'Ежемесячное пособие в связи с рождением и воспитанием ребенка',
		payload: { code: '4454' },
	},
	{
		id: '4455',
		label: 'Ежемесячная выплата в связи с рождением (усыновлением) первого ребенка ',
		payload: { code: '4455' },
	},
	{
		id: '4457',
		label:
			'Ежемесячная денежная выплата на проезд для обучающихся, получающих образование в общеобразовательных организациях или профессиональных образовательных организациях либо образовательных организациях высшего образования, а также вне организаций, осуществляющих образовательную деятельность (в форме семейного образования и самообразования)',
		payload: { code: '4457' },
	},
	{
		id: '4462',
		label: 'Дополнительное ежемесячное пособие на второго и каждого следующего ребенка',
		payload: { code: '4462' },
	},
]

const getPayments = (data: Record<string, any>): ScoringDocuments.Field[] => {
	const model = new DefaultValues(data)

	const startDate = date.format(model.string(['startDate']))
	const endDate = date.format(model.string(['endDate']))
	const amount = model.null<number>(['amount'])

	return [
		{
			collection: 'payments',
			type: 'DATE',
			key: 'startDate',
			value: startDate,
			readonlyValue: startDate,
			title: 'Действует с',
		},
		{
			type: 'DATE',
			key: 'endDate',
			value: endDate,
			readonlyValue: endDate,
			notRequired: true,
			title: 'Действует по',
		},
		{
			type: 'MASK',
			key: 'code',
			mask: '_'.repeat(4),
			value: model.string(['code']),
			dependentChange: (value) => ({ valueIndex: 3, value }),
			title: 'Код ЕГИССО',
		},
		{
			type: 'SELECT',
			key: 'name',
			options: nameOptions,
			disabled: true,
			span: '4/6',
			value: model.string(['code']),
			readonlyValue: model.string(['name']),
			title: 'Наименование меры защиты',
		},
		{
			type: 'NUM',
			key: 'amount',
			value: amount,
			readonlyValue: moneyUtils.RUB(amount),
			title: 'Сумма',
		},
	]
}

export const paymentEgisso = <T extends Record<string, any>>(
	data: T,
	isTemplate?: boolean
): TUtils.Maybe<ScoringDocuments.SourceDocument> => {
	if (!isTemplate && !data) return null

	const model = new DefaultValues(data)

	const rows = model.array(['payments'])

	return {
		id: 'paymentEgisso',
		collections: [['payments', getPayments]],
		rows: rows.map(getPayments),
	}
}
