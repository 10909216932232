import { Icon } from '@iconify/react/dist/iconify.js'
import cx from 'clsx'
import PropTypes from 'prop-types'

import styles from './Item.module.scss'

const Item = ({ name, active, getRef, surname, onClick, avatar }) => {
	const fullName = [name, surname].filter(Boolean).join(' ')

	return (
		<div
			tabIndex={1}
			onClick={onClick}
			ref={(el) => getRef && getRef(el)}
			className={cx(styles.item, {
				[styles.active]: active,
			})}
		>
			{avatar ? (
				<img className={styles.avatar} src={avatar} />
			) : (
				<Icon icon='zmdi-account-box' className={styles.avatar} />
			)}
			<div className={styles.fullName}>{fullName}</div>
		</div>
	)
}

Item.propTypes = {
	active: PropTypes.bool,
	name: PropTypes.string,
	working: PropTypes.bool,
	avatar: PropTypes.string,
	surname: PropTypes.string,

	getRef: PropTypes.func,
	onClick: PropTypes.func.isRequired,
}

export default Item
