import styled from 'styled-components'

import { Button } from '@creditclubteam/kit/ui-components'

export const Wrap = styled.div({
	display: 'flex',
	flexDirection: 'column',
})

export const Tabs = styled.div(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	width: 'auto',
	position: 'relative',
	padding: `0 ${theme.spaces.xl}px`,
	boxSizing: 'content-box',
	gap: theme.spaces.xl,
	rowGap: 0,
	flexWrap: 'wrap',
	justifyContent: 'flex-start',

	'&:after': {
		position: 'absolute',
		bottom: -2,
		display: 'block',
		height: 2,
		left: -20,
		width: `calc(100% + 40px)`,
		backgroundColor: theme.colors.border,
		content: 'close-quote',
	},
}))

export const TabItem = styled(Button)<{ selected?: boolean }>(({ theme, selected }) => ({
	color: theme.colors[selected ? 'active' : 'textSecondary'],
	background: 'transparent',
	boxSizing: 'content-box',
	padding: 0,
	borderRadius: 0,
	cursor: !selected ? 'pointer' : 'unset',

	'@media (hover: hover)': {
		'&:active': {
			transform: selected ? 'none' : undefined,
		},
	},
}))

export const Border = styled.div<{ xPos: number; width: number }>(({ theme, xPos, width }) => ({
	display: 'block',
	position: 'absolute',
	bottom: -2,
	zIndex: 1,
	height: 2,
	borderRadius: theme.borderRadius.l,
	width,
	backgroundColor: theme.colors.active,
	transform: `translateX(${xPos - theme.spaces.xl}px)`,
	transition: `transform .5s ease-in-out, width .5s ease-in-out`,
}))

export const Content = styled.div`
	padding: ${({ theme }) => `${theme.spaces.xl}px`};
`
