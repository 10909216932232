import { Component } from 'react'
import { connect } from 'react-redux'
import { addFacilityToEntity, searchAssessmentFacilities } from 'actions/facility'
import { addFacilityLead } from 'actions/lead'
import { setModalShow } from 'actions/modalDialogs'
import { keycloak } from 'client/keycloak/instance'
import PropTypes from 'prop-types'
import { flatten, isEmpty, mergeRight } from 'ramda'

import { FACILITY_TYPES_OPTIONS } from '@creditclubteam/helpers'
import { AddressComplete, DeepDropdown } from '@creditclubteam/kit/legacy-ui-components'
import { ModalDialog } from 'components/common'
import { Button, Input } from 'components/common'
import { utils } from 'helpers'
import { isValidAddress } from 'helpers/address'
import { numberOrNull } from 'helpers/convertHelper'

import PopupForm from './PopupForm'

import './AddForm.scss'

const LEAD_TYPES = ['FLAT', 'OFFICE', 'MALL', 'COMMON', 'HOUSE', 'ROOM']

const convertTypesToOptionsLead = () => {
	const options = flatten(FACILITY_TYPES_OPTIONS.map((item) => item.types))
		.filter((item) => LEAD_TYPES.includes(item.name))
		.map((item) => ({
			label: item.title,
			value: item.name,
			options: [],
		}))

	return options
}

const convertTypesToOptions = () => {
	const options = []

	FACILITY_TYPES_OPTIONS.forEach((option) => {
		const onlyValue = option.types.find((e) => e.title === option.title)

		options.push({
			label: option.title,
			value: onlyValue ? onlyValue.name : option.title,
			options: option.types
				.filter((e) => e !== onlyValue)
				.map((e) => ({ label: e.title, value: e.name })),
		})
	})

	return options
}

class AddForm extends Component {
	static propTypes = {
		modalDialogsShow: PropTypes.object.isRequired,
		entity: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),

		setModalShow: PropTypes.func.isRequired,
		searchAssessmentFacilities: PropTypes.func.isRequired,
		addFacilityToEntity: PropTypes.func.isRequired,
		addFacilityLead: PropTypes.func,
	}

	state = {
		form: {
			type: '',
			area: '',
			address: null,
		},
		fetching: {
			submit: false,
			popupSubmit: false,
		},
		base: [],
		rosreestr: [],
		// Для автокомплита чтобы он видел выбранную опцию
		initialFacility: {},
	}

	onSuccess = () => {
		this.setState({
			form: {
				type: '',
				area: '',
				address: null,
			},
			fetching: {
				submit: false,
				popupSubmit: false,
			},
			base: [],
			rosreestr: [],
		})
	}

	handleSubmit = async (e) => {
		e.preventDefault()

		const { form } = this.state

		const {
			entity,
			setModalShow,
			addFacilityToEntity,
			addFacilityLead,
			searchAssessmentFacilities,
		} = this.props

		this.setFetching('submit', true)

		const query = form.address.mergedAddress

		/**
		 * @description
		 *
		 * есть несколько кейсов в добавлении объекта:
		 * если юзер вводит только кадастр - тогда объект сразу добавляем;
		 * если юзер ввел адрес - ищем по нему в сервисе Facility и Assessment.
		 * Результатов нет - добавляем объект, если есть, то показываем попап.
		 * Юзер должен выбрать один объект и нажать на сабмит. Как только он
		 * это сделал - добавляем тот объект, который выбрали.
		 */

		if (utils.isCadaster(query)) {
			try {
				if (entity.name === 'lead') {
					await addFacilityLead({
						base: null,
						rosreestr: null,
						custom: {
							type: form.type,
							cadastralId: query,
						},
					})
				} else {
					await addFacilityToEntity(
						{
							requestData: {
								area: numberOrNull(form.area),
								type: form.type,
								cadastralId: query,
							},
							entity,
						},
						true
					)
				}

				this.onSuccess()
			} catch (error) {
				this.setFetching('submit', false)
			}
		} else {
			const results = await searchAssessmentFacilities({
				area: numberOrNull(form.area),
				type: form.type,
				address: form.address,
			})

			if (
				(isEmpty(results.base) &&
					results.base &&
					isEmpty(results.rosreestr) &&
					results.rosreestr) ||
				(isEmpty(results.base) && !results.rosreestr)
			) {
				try {
					if (entity.name === 'lead') {
						await addFacilityLead({
							base: null,
							rosreestr: null,
							custom: results.facility,
						})
					} else {
						await addFacilityToEntity(
							{
								requestData: results.facility,
								entity,
							},
							true
						)
					}

					this.onSuccess()
				} catch (error) {
					this.setFetching('submit', false)
				}
			} else if (
				(isEmpty(results.rosreestr) && !results.base) ||
				(!results.rosreestr && !results.base)
			) {
				this.setState({
					fetching: {
						...this.state.fetching,
						submit: false,
					},
				})
			} else {
				this.setState(
					{
						fetching: {
							...this.state.fetching,
							submit: false,
						},
						base: results.base || [],
						rosreestr: results.rosreestr || [],
					},
					() => setModalShow(true, 'addFacility')
				)
			}
		}
	}

	handlePopupSubmit = async (selectedFacility = null, from, cadastralId = null) => {
		const { form } = this.state

		const { addFacilityToEntity, addFacilityLead, setModalShow, entity } = this.props

		this.setFetching('popupSubmit', true)

		let params = {}
		let leadParams = {}

		if (from === 'rosreestr') {
			params = {
				requestData: {
					cadastralId,
					type: form.type,
					area: numberOrNull(form.area),
					rosreestrAddress: selectedFacility.address,
					address: form.address,
				},
			}

			leadParams = {
				rosreestr: {
					cadastralId,
					type: form.type,
					area: numberOrNull(form.area),
					rosreestrAddress: selectedFacility.address,
					address: form.address,
				},
				base: null,
				custom: null,
			}
		} else if (from === 'base') {
			params = {
				requestData: {
					facilityId: selectedFacility.id,
					owners: selectedFacility.owners,
				},
				facility: selectedFacility,
			}

			leadParams = {
				rosreestr: null,
				base: {
					id: selectedFacility.id,
				},
				custom: null,
			}
		} else if (from === 'initial') {
			params = {
				requestData: {
					type: form.type,
					area: numberOrNull(form.area),
					address: form.address,
				},
			}

			leadParams = {
				rosreestr: null,
				base: null,
				custom: {
					type: form.type,
					area: numberOrNull(form.area),
					address: form.address,
				},
			}
		}

		try {
			if (entity.name === 'lead') {
				await addFacilityLead(leadParams)
			} else {
				await addFacilityToEntity(mergeRight(params, { entity }), true)
			}

			setModalShow(false, 'addFacility')

			this.onSuccess()
		} catch (error) {
			this.setFetching('popupSubmit', false)
		}
	}

	setField = (key, value, callback, others = {}) => {
		this.setState(
			{
				...others,
				form: {
					...this.state.form,
					[key]: value,
				},
			},
			callback
		)
	}

	setFetching = (prop, value, callback) => {
		this.setState(
			{
				fetching: {
					...this.state.fetching,
					[prop]: value,
				},
			},
			callback
		)
	}

	onCloseModalDialog = () => {
		const { setModalShow } = this.props

		setModalShow(false, 'addFacility')
	}

	render() {
		const { modalDialogsShow, entity } = this.props

		const { form, base, fetching, rosreestr } = this.state

		const deepDropdownProps = {
			value: form.type,
			disabled: fetching.submit,
			placeholder: 'Тип объекта',
			options: entity.name === 'lead' ? convertTypesToOptionsLead() : convertTypesToOptions(),
			onChange: (e) => this.setField('type', e.value),
		}

		const addressProps = {
			onReset: () => this.setField('address', null),
			placeholder: 'Адрес объекта или кадастровый номер',
			baseURL: window.__env__.BASE_URL,
			value: form.address,
			token: keycloak.token ?? '',
			onChange: (value) => {
				this.setField('address', value)
			},
		}

		const areaInputProps = {
			value: form.area,
			placeholder: 'Площадь',
			disabled: fetching.submit,
			onChange: (e) => (Number(e) || !e) && this.setField('area', e),
		}

		const submitProps = {
			title: 'Добавить',
			buttonType: 'submit',
			loading: fetching.submit,
			disabled:
				!form.area ||
				(!isValidAddress(form.address) &&
					!utils.isCadaster(form.address?.mergedAddress)) ||
				!form.type,
		}

		const modalDialogProps = {
			onClose: this.onCloseModalDialog,
			title: 'Мы нашли похожие объекты',
			className: 'facilities-modal-dialog',
			opened: modalDialogsShow.addFacility,
		}

		const popupFormProps = {
			base,
			rosreestr,
			area: form.area,
			fetching: fetching.popupSubmit,
			onSubmit: this.handlePopupSubmit,
			address: form.address?.mergedAddress,
		}

		return (
			<>
				<ModalDialog {...modalDialogProps}>
					<PopupForm {...popupFormProps} />
				</ModalDialog>

				<form onSubmit={this.handleSubmit}>
					<div className='facility-block-add-form'>
						<div data-test-id='facility-type' className='facility-block-add-form__type'>
							<DeepDropdown {...deepDropdownProps} />
						</div>
						<div data-test-id='facility-address' className='facility-block-add-form__address'>
							<AddressComplete {...addressProps} />
						</div>
						<div data-test-id='facility-area' className='facility-block-add-form__area'>
							<Input {...areaInputProps} />
						</div>
						<div data-test-id='facility-add-btn' className='facility-block-add-form__button'>
							<Button {...submitProps} />
						</div>
					</div>
				</form>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	modalDialogsShow: state.modalDialogs,
})

const mapDispatchToProps = {
	setModalShow,
	searchAssessmentFacilities,
	addFacilityToEntity,
	addFacilityLead,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddForm)
