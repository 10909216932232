import { useState } from 'react'
import { connect } from 'react-redux'
import { deleteHistoryComment, editHistoryComment } from 'actions/history'
import PropTypes from 'prop-types'
import { is } from 'ramda'
import { workerSelector } from 'reducers/worker'

import { authorizedWorker } from 'helpers/authorizedWorker'

import Context from '../../Context'

import Controls from './Controls'
import EditComment from './EditComment'
import { extractMention, getWorkerProfile } from './helpers'
import Mention from './Mention'

import styles from './Styles.module.scss'

const Comment = (props) => {
	const [editMode, setEditMode] = useState(false)

	const {
		id,
		user,
		$$date,
		workers,
		participants,
		metadata,
		$$isProcessed,
		isHistoryScrolled,
		editHistoryComment,
		deleteHistoryComment,
	} = props

	const controlsProps = {
		user,
		metadata,
		$$isProcessed,
		hideControls: editMode || isHistoryScrolled,

		onShowEditComponent: () => setEditMode(true),
		onDeleteComment: () => deleteHistoryComment(id),
	}

	const participantsAndWorkers = [...participants, ...workers.data]

	const contextProps = {
		date: $$date.fullDate,
		fetching: workers.fetching,
		className: 'history-comment-el',
		controls: <Controls {...controlsProps} />,
		...getWorkerProfile(metadata, workers),
	}

	const text = extractMention(metadata.text, participantsAndWorkers)

	const editCommentProps = {
		oldText: metadata.text,
		oldMentions: text.filter((word) => is(Object, word)),

		workers: participantsAndWorkers,
		onClose: () => setEditMode(false),
		onEdit: (newText) => editHistoryComment(id, newText),
	}

	return (
		<Context {...contextProps}>
			{editMode ? (
				<EditComment {...editCommentProps} />
			) : (
				<div className={styles.wrapper}>
					<pre>
						{text.map((word, index) =>
							is(Object, word) ? <Mention key={index} user={user} worker={word} /> : word
						)}
					</pre>
				</div>
			)}
		</Context>
	)
}

Comment.propTypes = {
	id: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	$$date: PropTypes.object.isRequired,
	$$isProcessed: PropTypes.bool.isRequired,
	isHistoryScrolled: PropTypes.bool.isRequired,
	metadata: PropTypes.shape({
		text: PropTypes.string.isRequired,
	}),
	workers: PropTypes.shape({
		data: PropTypes.array.isRequired,
		fetching: PropTypes.bool.isRequired,
	}),

	editHistoryComment: PropTypes.func.isRequired,
	deleteHistoryComment: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	user: workerSelector.selectById(state, authorizedWorker.getId()),
	participants: [
		...state.organization.list.data,
		...state.person.list.data,
		...state.entrepreneur.list.data,
	].map(({ id, name = null, surname = null, $$type }) => ({
		id,
		name,
		surname,
		type: $$type.toLowerCase(),
	})),
	workers: {
		data: workerSelector.selectAll(state),
		fetching: state.worker.fetching,
	},
})

const mapDispatchToProps = {
	editHistoryComment,
	deleteHistoryComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment)
