import { useCallback } from 'react'
import { useTheme } from 'styled-components'

import { Button } from '@creditclubteam/kit/legacy-ui-components'
import { type Spaces } from '@creditclubteam/kit/styled'
import { FormLayout } from 'components/common'

export interface ControlsProps {
	readOnly?: boolean
	indent?: Spaces
	submitButtonProps?: Partial<React.ComponentProps<typeof Button>>
	cancelButtonProps?: Partial<React.ComponentProps<typeof Button>>
	editButtonProps?: Partial<React.ComponentProps<typeof Button>>
}

const Controls = (props: React.PropsWithChildren<ControlsProps>) => {
	const { readOnly, indent, submitButtonProps, cancelButtonProps, editButtonProps, children } =
		props
	const { spaces } = useTheme()

	const editDefaults: React.ComponentProps<typeof Button> = {
		title: editButtonProps?.title || 'Редактировать',
		mode: editButtonProps?.mode || 'min',
		buttonType: editButtonProps?.buttonType || 'button',
		attributes: {
			'data-test-id': 'controls-edit-button',
		} as Record<string, any>,
	}

	const cancelDefaults: React.ComponentProps<typeof Button> = {
		title: cancelButtonProps?.title || 'Отмена',
		theme: cancelButtonProps?.theme || 'text-red',
		buttonType: cancelButtonProps?.buttonType || 'button',
		attributes: {
			'data-test-id': 'controls-cancel-button',
		} as Record<string, any>,
	}

	const submitDefaults: React.ComponentProps<typeof Button> = {
		title: submitButtonProps?.title || 'Сохранить',
		buttonType: submitButtonProps?.buttonType || 'submit',
		attributes: {
			'data-test-id': 'controls-submit-button',
		} as Record<string, any>,
	}

	const handleEdit = useCallback(
		(event: any) => {
			if ('preventDefault' in event) event.preventDefault()

			if (editButtonProps?.onClick) editButtonProps.onClick(event)
		},
		[editButtonProps]
	)

	const editBtnProps: React.ComponentProps<typeof Button> = editButtonProps
		? { ...editButtonProps, ...editDefaults, onClick: handleEdit }
		: editDefaults

	const submitBtnProps: React.ComponentProps<typeof Button> = submitButtonProps
		? { ...submitButtonProps, ...submitDefaults }
		: submitDefaults

	const cancelBtnProps: React.ComponentProps<typeof Button> = cancelButtonProps
		? { ...cancelButtonProps, ...cancelDefaults }
		: cancelDefaults

	if (children) return <>{children}</>

	return (
		<div
			className={FormLayout.styles.controls}
			style={{ margin: indent ? spaces[indent] : undefined }}
		>
			{readOnly ? (
				<Button {...editBtnProps} />
			) : (
				<>
					<Button {...submitBtnProps} />
					<Button {...cancelBtnProps} />
				</>
			)}
		</div>
	)
}

export default Controls
