import type { ScoringDocuments } from 'converters/scoring/person/documents/types'
import type { TUtils } from 'types'

import { moneyUtils } from '@creditclubteam/helpers'
import { DefaultValues, numberOrNull } from 'helpers/convertHelper'

export const ndfl3 = <T extends Record<string, any>>(
	data: T,
	isTemplate?: boolean
): TUtils.Maybe<ScoringDocuments.SourceDocument> => {
	if (!isTemplate && !data) return null

	const model = new DefaultValues(data)

	const amount = model.null<number>(['amount'])

	return {
		id: 'ndfl3',
		rows: [
			[
				{
					type: 'MASK',
					key: 'year',
					mask: '_'.repeat(4),
					transformValue: numberOrNull,
					value: model.string(['year']),
					title: 'Год',
				},
				{
					type: 'MASK',
					key: 'periodCode',
					span: '2/4',
					mask: '_'.repeat(2),
					value: model.string(['periodCode']),
					title: 'Налоговый период',
				},
				{
					key: 'amount',
					value: amount,
					readonlyValue: moneyUtils.RUB(amount),
					type: 'NUM',
					title: 'Сумма дохода',
				},
			],
		],
	}
}
