import { Component, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Viewer from 'react-viewer'
import { changeImageIndex } from 'actions/filesViewer'
import cx from 'clsx'
import { useFetch } from 'components/hooks'
import PropTypes from 'prop-types'

import './ViewImages.scss'

const pos = {
	start: null,
	end: null,
}

const getImageCount = () => {
	const imageWidth = 54
	const margin = 4
	const totalImageWidth = imageWidth + margin

	const count = Math.floor(window.innerWidth / totalImageWidth)
	return count > 50 ? 50 : count
}

const preparePreview = (arr, index) => {
	if (arr.length === 0 || index < 0 || index >= arr.length) return { items: arr, activeIndex: 0 }

	const amountOfRenderedImages = getImageCount()

	if (arr.length <= amountOfRenderedImages) {
		return { items: arr, activeIndex: Math.max(0, arr.indexOf(arr[index])) }
	}

	const idx = Math.min(index, arr.length - 1)
	let start = pos.start ?? 0
	let end = pos.end ?? 0

	const isWithinRange = (num, [min, max]) => num >= min && num <= max

	if (!isWithinRange(idx, [start, end]) || (pos.start === null && pos.end === null)) {
		start = Math.max(idx - amountOfRenderedImages, 0)
		end = Math.min(start + amountOfRenderedImages, arr.length)
	} else if (idx <= start && idx > 0) {
		start = Math.max(start - 5, 0)
		end = Math.min(start + amountOfRenderedImages, arr.length)
	} else if (idx + 1 >= end) {
		start = Math.min(start + 5, arr.length - amountOfRenderedImages)
		end = Math.min(end + 5, arr.length)
	}

	pos.end = end
	pos.start = start

	const slice = arr.slice(start, end)

	const activeIndex = slice.indexOf(arr[index])

	return { items: slice, activeIndex: activeIndex >= 0 ? activeIndex : 0 }
}

class SrcErrorHandler extends Component {
	componentDidCatch() {
		//
	}

	static getDerivedStateFromError() {
		return
	}

	render() {
		return <>{this.props.children}</>
	}
}

function customToolbar({ actions, callbacks, addition, clsx }) {
	const customActions = actions.map((action) => {
		let onClick = {}

		if (action.key !== 'rotateLeft' && action.key !== 'rotateRight' && action.key !== 'reset') {
			return action
		}

		if (action.key === 'rotateLeft') {
			onClick = {
				onClick: () => callbacks.handleRotate(addition.rotateDegree - 90),
			}
		} else if (action.key === 'rotateRight') {
			onClick = {
				onClick: () => callbacks.handleRotate(addition.rotateDegree + 90),
			}
		} else if (action.key === 'reset') {
			onClick = {
				onClick: () => callbacks.handleRotate(0),
			}
		}

		return {
			...action,
			...onClick,
		}
	})

	customActions.push({
		key: 'applyRotation',
		render: <i className={clsx.applyRotationClassName} />,
		onClick:
			!addition.isFetching && !addition.disabled && addition.rotateDegree % 360 !== 0
				? callbacks.handleRotationApply
				: null,
	})

	customActions.push({
		key: 'downloadFile',
		render: <i className='zmdi zmdi-download' />,
		onClick: !addition.isFetching && callbacks.handleDownloadImage,
	})

	customActions.push({
		key: 'deleteFile',
		render: <i className='zmdi zmdi-delete' />,
		onClick: !addition.isFetching && callbacks.handleDelete,
	})

	return customActions
}

const timeout = 0

const ViewImages = ({
	opened,
	images,
	index = 0,
	allowSaveRotation,
	rotateImage,
	downloadImage,
	deleteImage,
	entityId,
}) => {
	const [isRotationApplied, setRotateApply] = useState(false)
	const [rotateDegree, setRotateDegree] = useState(0)
	const [disabled, setDisable] = useState(true)
	const currentImage = images[index]

	const dispatch = useDispatch()

	useEffect(
		() => () => {
			clearTimeout(timeout)

			return () => {
				pos.end = null
				pos.start = null
			}
		},
		[]
	)

	const handleRotate = useCallback((degree) => {
		setRotateDegree(degree)
		setRotateApply(false)

		if (degree % 360 === 0) {
			setDisable(true)
		} else {
			setDisable(false)
		}
	}, [])

	const getImageIndex = useCallback(
		(imageId) => images.findIndex(({ id }) => id === imageId),
		[images]
	)

	const handleDownloadImage = useCallback(() => {
		downloadImage(index)
	}, [index, downloadImage])

	const { request: handleRotationApply, isFetching } = useFetch(
		() => rotateImage(index, currentImage.id, rotateDegree, entityId),
		{
			onSuccess: () => {
				const resetAction = document.querySelector('[data-key=reset]')
				if (resetAction) resetAction.click()

				setRotateApply(true)
				setRotateDegree(0)
			},
		}
	)

	const handleChangeImage = useCallback(
		(image) => {
			handleRotate(0)
			dispatch(changeImageIndex(getImageIndex(image.id)))
		},
		[handleRotate, dispatch, getImageIndex]
	)

	const applyRotationClassName = cx('zmdi', {
		disabled,
		'zmdi-spinner': isFetching,
		'zmdi-check': !isRotationApplied,
		'zmdi-check-all': isRotationApplied,
	})

	const { items, activeIndex } = useMemo(() => preparePreview(images, index), [images, index])

	if (!items.length) return null

	return (
		<SrcErrorHandler>
			<Viewer
				noClose
				images={items}
				loop={false}
				scalable={false}
				visible={opened}
				showTotal={false}
				activeIndex={activeIndex}
				onChange={handleChangeImage}
				customToolbar={(toolbar) =>
					allowSaveRotation
						? customToolbar({
								actions: toolbar,
								callbacks: {
									handleRotate,
									handleRotationApply,
									handleDownloadImage,
									handleDelete: deleteImage,
								},
								clsx: {
									applyRotationClassName,
								},
								addition: {
									disabled,
									isFetching,
									rotateDegree,
								},
						  })
						: toolbar
				}
			/>
		</SrcErrorHandler>
	)
}

ViewImages.propTypes = {
	index: PropTypes.number,
	opened: PropTypes.bool.isRequired,
	images: PropTypes.array.isRequired,
	entityId: PropTypes.string.isRequired,

	rotateImage: PropTypes.func.isRequired,
	downloadImage: PropTypes.func.isRequired,
	allowSaveRotation: PropTypes.bool.isRequired,
	deleteImage: PropTypes.func.isRequired,
}

export default ViewImages
