import { fetchBrokersNames } from 'actions/common/broker'
import { resetEntrepreneurList } from 'actions/entrepreneur'
import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { resetOrganizationList } from 'actions/organization'
import { resetPersonList } from 'actions/person'
import { getPipeline } from 'actions/pipeline'
import {
	searchBrokerEmployee,
	searchEntrepreneur,
	searchFacility,
	searchOrganization,
	searchPerson,
	setSearchResults,
} from 'actions/search'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import { filter, flatten, isEmpty, map, pipe, prop } from 'ramda'
import { loanListReducerActions } from 'reducers/loan/list'

import { utils } from 'helpers'

export const resetLoanList = loanListReducerActions.reset
export const loanListChangeFilterParameter = loanListReducerActions.setFilter

// сброс для ухода с таба займов
export const resetLoans = () => (dispatch) => {
	dispatch(resetLoanList())
	dispatch(resetPersonList())
	dispatch(resetOrganizationList())
	dispatch(resetEntrepreneurList())
}

export const setDefaultLoanFilter = () => (dispatch, getState) => {
	const pipeline = getState().pipeline.statuses.loan

	if (isEmpty(pipeline)) return

	// все возможные статусы для фильтра займов
	const statuses = pipeline.map((e) => e.id)

	// статусы которые отключаем по-умолчанию
	const disabledByDefaultStatuses = ['CLOSED', 'SOLD', 'SOLD_TO_THIRD']

	// формируем массив активных статусов
	const defaultStatuses = statuses.filter((status) => !disabledByDefaultStatuses.includes(status))

	if (defaultStatuses && !isEmpty(defaultStatuses)) {
		dispatch(loanListReducerActions.setFilter({ key: 'status', value: defaultStatuses }))
	}
}

export const getLoanList = () => async (dispatch, getState) => {
	dispatch(loanListReducerActions.setStatus('pending'))
	const pipeline = getState().pipeline.statuses.loan

	if (isEmpty(pipeline)) {
		await dispatch(getPipeline('loan'))

		dispatch(setDefaultLoanFilter())
	}

	const requestParameters = {
		...dispatch(solveParamsPagination('createdAt')),
		filter: getState().loan.list.filter,
	}

	try {
		const { data } = await api.loan.search(requestParameters)
		const { totalElements, number, totalPages } = data

		dispatch(
			setPagination({
				totalPages,
				totalElements,
				paged: true,
				currentPage: number,
			})
		)
		dispatch(loanListReducerActions.setData(data.content))
		dispatch(loanListReducerActions.setStatus('fulfilled'))

		fetchBrokersNames({ data: data.content, key: 'loan' })
	} catch (error) {
		dispatch(loanListReducerActions.setStatus('rejected'))

		dispatch(
			addServerError({
				text: 'Не удалось загрузить займы',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

export const searchLoanByQuery = (query) => (dispatch) => {
	const requestOptions = {
		query,
		params: {
			size: 10,
			sort: 'desc',
			page: 0,
		},
	}

	return axios
		.all([
			dispatch(searchPerson(requestOptions)),
			dispatch(searchFacility(requestOptions)),
			dispatch(searchOrganization(requestOptions)),
			dispatch(searchEntrepreneur(requestOptions)),
			dispatch(searchBrokerEmployee(requestOptions)),
		])
		.then((data) => {
			const normalizeResponse = pipe(map(prop('content')), flatten, filter(Boolean))
			const participants = normalizeResponse(data)

			dispatch(setSearchResults('internal', participants))
		})
}
