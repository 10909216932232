import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { dictionarySignatureReducerActions } from 'reducers/dictionary/signatures'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'

export const getDictionarySignatures = (): TAction<Promise<void>> => async (dispatch, getState) => {
	try {
		if (
			getState().dictionary.signatures.qualifiedSignatureProviders.length &&
			getState().dictionary.signatures.applicationTypes.length
		)
			return

		dispatch(dictionarySignatureReducerActions.setStatus('pending'))

		const { data: qualifiedSignatureProviders } =
			await api.dictionary.signatures.getQualifiedSignatureProviders()
		const { data: applicationTypes } = await api.dictionary.signatures.getApplicationTypes()

		dispatch(
			dictionarySignatureReducerActions.set({ key: 'applicationTypes', value: applicationTypes })
		)
		dispatch(
			dictionarySignatureReducerActions.set({
				key: 'qualifiedSignatureProviders',
				value: qualifiedSignatureProviders,
			})
		)
		dispatch(dictionarySignatureReducerActions.setStatus('fulfilled'))
	} catch (error: unknown) {
		dispatch(
			addServerError({
				text: 'Не удалось загрузить провайдеров',
				details: utils.getDetailsFromError(error),
			})
		)
		dispatch(dictionarySignatureReducerActions.setStatus('rejected'))
	}
}
