import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ROLES_VALUES } from 'const'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { workerSelector } from 'reducers/worker'

import WorkersList from './WorkerList'

const WorkersPicker = ({
	style,
	opened,
	positions,
	handlers: { onChange, onClose, onFocus },
	reset,
}) => {
	const workers = useSelector(workerSelector.selectAll)
	const participants = useSelector((state) =>
		[]
			.concat(state.person.list.data, state.entrepreneur.list.data, state.organization.list.data)
			.filter(({ roles }) => roles.includes(ROLES_VALUES.INVESTOR))
	)
	const fetching = useSelector((state) => state.worker.fetching)

	const [position, setPosition] = useState({ y: 0, x: 0, isBiggerThanWindowHeight: false })

	const handleFocus = useCallback(() => {
		if (onFocus) {
			onFocus()
		}
	}, [onFocus])

	const handleClose = useCallback(() => {
		reset()

		if (onClose) {
			onClose()
		}
	}, [onClose, reset])

	const handleChange = useCallback(
		(item) => {
			if (onChange) {
				onChange(item)
			}

			handleClose()
		},
		[onChange, handleClose]
	)

	const determineWindow = useCallback((positions) => {
		const workerPickerWidth = 380
		const workerPickerHeight = 260

		const positionBiggerThanWindowWidth = positions.left + workerPickerWidth > window.innerWidth
		const positionBiggerThanWindowHeight = positions.top + workerPickerHeight > window.innerHeight

		const left = positionBiggerThanWindowWidth ? positions.left - workerPickerWidth : positions.left
		const top = positionBiggerThanWindowHeight ? positions.top - workerPickerHeight : positions.top

		return {
			top,
			left,
			isBiggerThanWindowHeight: positionBiggerThanWindowHeight,
		}
	}, [])

	const handleSetPosition = useCallback(
		(e) => {
			const positions = {
				top: e.pageY,
				left: e.pageX,
			}

			const { top, left, isBiggerThanWindowHeight } = determineWindow(positions)

			setPosition({ y: top, x: left, isBiggerThanWindowHeight })
		},
		[determineWindow]
	)

	useEffect(() => {
		if (!opened) {
			window.addEventListener('mousemove', handleSetPosition)
		} else {
			window.addEventListener('resize', reset)
		}

		if (opened && !isEmpty(positions)) {
			const { top, left, isBiggerThanWindowHeight } = determineWindow(positions)

			setPosition({ y: top, x: left, isBiggerThanWindowHeight })
			return
		}

		return () => {
			window.removeEventListener('mousemove', handleSetPosition)

			window.removeEventListener('resize', reset)
		}
	}, [opened, reset, handleSetPosition, positions, determineWindow])

	return (
		opened && (
			<WorkersList
				style={style}
				workers={workers}
				participants={participants}
				fetching={fetching}
				positions={position}
				onFocus={handleFocus}
				onChange={handleChange}
				onBlur={reset}
			/>
		)
	)
}

WorkersPicker.propTypes = {
	opened: PropTypes.bool,
	fetching: PropTypes.bool,
	workers: PropTypes.array,
	handlers: PropTypes.object,
	positions: PropTypes.object,

	reset: PropTypes.func,
}

export default WorkersPicker
