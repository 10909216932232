import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import type { updateDelivery } from 'actions/delivery'
import { addServerError } from 'actions/serverErrors'
import clsx from 'clsx'
import { useFormik } from 'formik'
import type { RootState } from 'types/redux'
import type { Maybe } from 'types/util'

import { Button } from '@creditclubteam/kit/legacy-ui-components'
import { utils } from 'helpers'
import type { CancelableThunkPromise } from 'helpers/cancelablePromise'
import { makeCancelable } from 'helpers/cancelablePromise'

import styles from './SelectSignType.module.scss'

interface SelectSignTypeProps {
	onSubmit: (selectedSignType: Maybe<string>) => void
	onModalSelectClose: () => void
	isShouldDisableButton: (signatureProvider: Maybe<string>) => boolean
}

export const SelectSignType = (props: SelectSignTypeProps) => {
	const { onSubmit, onModalSelectClose, isShouldDisableButton } = props
	const initialSignatureProvider = useSelector(
		(state: RootState) => state.person.single.spec.qualifiedSignatureProvider
	)

	const handleSubmitSignType = async (selectedSignType: Maybe<string>) => {
		try {
			await onSubmit(selectedSignType)
			onModalSelectClose()
		} catch (err) {
			addServerError({
				details: utils.getDetailsFromError(err),
				text: `Ошибка при создании подписи`,
			})
			throw err
		}
	}

	const submitPromise = useRef<CancelableThunkPromise<typeof updateDelivery>>(null)

	useEffect(() => {
		return () => {
			submitPromise.current?.cancel()
		}
	}, [])

	const { handleSubmit, setFieldValue, values, isSubmitting } = useFormik({
		initialValues: {
			signatureProvider: initialSignatureProvider,
		},
		onSubmit: ({ signatureProvider }, { setSubmitting }) => {
			setSubmitting(true)

			submitPromise.current = makeCancelable(handleSubmitSignType(signatureProvider!))

			submitPromise.current.promise.catch((error) => {
				if (error?.isCanceled) return
				setSubmitting(false)
			})
		},
		validateOnChange: false,
		validateOnBlur: false,
	})

	const isSubmitButtonDisabled =
		isSubmitting ||
		values.signatureProvider === null ||
		(window.__env__.BUILD_MODE !== 'production' && isShouldDisableButton(values.signatureProvider!))

	return (
		<form onSubmit={handleSubmit}>
			{window.__env__.BUILD_MODE !== 'production' &&
				values.signatureProvider !== null &&
				isShouldDisableButton(values.signatureProvider!) && (
					<div className={styles.error}>
						Требования для тестовой подписи: телефон должен начинаться с кода города 343 или 800,
						почта должна быть в домене sign.me
					</div>
				)}

			<div className={styles.checkboxBlock}>
				<div>
					<div
						className={clsx(
							styles.checkbox,
							values.signatureProvider === 'SIGN_ME_BUSINESS_TECH' && styles.checkboxActive
						)}
						onClick={() => setFieldValue('signatureProvider', 'SIGN_ME_BUSINESS_TECH')}
					>
						Подпись Sign.Me от Бизнес технологии
					</div>
				</div>
				<div>
					<div
						className={clsx(
							styles.checkbox,
							values.signatureProvider === 'SIGN_ME_CONSULT' && styles.checkboxActive
						)}
						onClick={() => setFieldValue('signatureProvider', 'SIGN_ME_CONSULT')}
					>
						Подпись Sign.Me от КК.Консалт
					</div>
				</div>
				<div>
					<div
						className={clsx(
							styles.checkbox,
							values.signatureProvider === 'SIGN_ME_CREDIT_CLUB' && styles.checkboxActive
						)}
						onClick={() => setFieldValue('signatureProvider', 'SIGN_ME_CREDIT_CLUB')}
					>
						Подпись Sign.Me от Кредит.Клаб
					</div>
				</div>
				<div>
					<div
						className={clsx(
							styles.checkbox,
							values.signatureProvider === 'KONTUR_CREDIT_CLUB' && styles.checkboxActive
						)}
						onClick={() => setFieldValue('signatureProvider', 'KONTUR_CREDIT_CLUB')}
					>
						Подпись Контура от Кредит.Клаб
					</div>
				</div>
			</div>
			<Button disabled={isSubmitButtonDisabled} buttonType={'submit'} title='Выпустить' />
		</form>
	)
}
