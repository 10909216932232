import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { REQUEST_VERSION } from 'const/api'
import type { Facility } from 'converters/facility'
import { facility } from 'converters/facility'
import { mergeDeepRight } from 'ramda'
import { uniq } from 'ramda'
import type { FacilityFilter } from 'reducers/facility/list'
import { ge, le } from 'rsql-builder'

import type { Options } from './types/Options'
import type { Pageable } from './types/Pageable'

export default {
	get: (id: EntityId) =>
		axios.get<Facility.Main>(`/v1/facilities/${id}`).then((response) =>
			Object.assign({}, response, {
				data: facility.main(response.data),
			})
		),

	update: (id: EntityId, data: Record<string, unknown>) =>
		axios.patch(`/v1/facilities/${id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	getEgrnValues: () => axios.get('/v1/facilities/rosreestr/egrn/values'),

	addFacilityToEntity: (entity: any, request: any) => {
		const data = {
			facilityId: request.facilityId,
			owners: request.owners,
		}

		return axios({
			data,
			url: `/${entity.name}/${entity.id}/facility`,
			method: 'POST',
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		})
	},

	searchObjectsByAddress: (data: unknown) =>
		axios.post('/v1/facilities/rosreestr/search', data, {
			timeout: 10000,
		}),

	deleteFacilityFromEntity: (entity: any, facilityId: EntityId) =>
		axios.delete(`/${entity.name}/${entity.id}/facility/${facilityId}`, {
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		}),

	create: (data: Record<string, unknown>) =>
		axios.post<Facility.Main>('/v1/facilities', data).then((response) =>
			Object.assign({}, response, {
				data: facility.main(response.data),
			})
		),

	search: ({ filter, size, sort, page }: Options<FacilityFilter>) => {
		const params = new URLBuilder()

		if (filter) {
			const re = (value: string) =>
				`=re="(?i)(?=.*${value.trim().replace(/\s+/g, ')(?=.*').replace(/"/g, '\\"')}).+"`

			params
				.addFilter('address.mergedAddress', filter.address, re)
				.addFilter('owners', filter.owners, 'inList')
				.addFilter('cadastralId', filter.cadastralId, 'eq')
				.addFilter('type', filter.type, 'inList')
				.addFilter('form.area', filter.minArea, ge)
				.addFilter('form.area', filter.maxArea, le)

			if (filter.id) {
				params.addFilter('id', uniq(filter.id.map((id) => id)), 'inList')
			}
		}

		return axios
			.get('/v1/facilities', {
				$$requestName: 'facility.search',
				params: {
					sort,
					size,
					page,
					filter: params.build('RSQL'),
				},
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(facility.main),
						},
					}) as AxiosResponse<Pageable<Facility.Main>>
			)
	},

	updateAssessment: (id: EntityId) => axios.post(`/v1/facilities/${id}/assess`),

	requestStatement: (body: unknown) => axios.post(`/v1/rosreestr/object-extracts`, body),

	getStatement: (packageId: EntityId) => axios.get(`/v1/rosreestr/object-extracts/${packageId}`),

	searchStatement: (id: EntityId, type: unknown) => {
		const params = new URLBuilder()

		params.addFilter('facilityId', [id], 'inList')
		params.addFilter('type', type, 'eq')

		return axios.get('/v1/rosreestr/object-extracts/search', {
			params: {
				sort: 'createdAt,desc',
				size: 100,
				filter: params.build('RSQL'),
			},
		})
	},
}
