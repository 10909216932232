import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { addSignerDocument, sendExternalSign, signAgreement } from 'actions/document'
import { searchEveryplace } from 'actions/search'
import api from 'api'
import cx from 'clsx'
import copy from 'copy-to-clipboard'
import { addMonths } from 'date-fns/esm'
import PropTypes from 'prop-types'
import { isEmpty } from 'rambda'

import { defaultOr } from '@creditclubteam/kit/helpers'
import { Button } from '@creditclubteam/kit/legacy-ui-components'
import { Button as KitButton, Input, Select, Text } from '@creditclubteam/kit/ui-components'
import { ChoiceBox } from 'components/common'
import { Grid } from 'components/common/Grid'
import { utils } from 'helpers'

import { Esia } from './Esia'
import { ManualSigning } from './ManualSigning'

import styles from './SendDocumentsToSign.module.scss'

const SendDocumentsToSign = (props) => {
	const { context, customers, documentsToSign, onClose, customersForSimpleSignature } = props

	const fileRef = useRef(null)

	const [isAddSignerFetching, setAddSignerFetching] = useState(false)
	const [isDocumentUrlFetching, setIsDocumentUrlFetching] = useState(false)
	const [isUrlCopied, setIsUrlCopied] = useState(false)
	const [isSendDocumentsToSignFetching, setSendDocumentsToSignFetching] = useState(false)
	const [signType, setSignType] = useState('STANDARD')
	const [isDocumentSignedBySms, setIsDocumentSignedBySms] = useState(false)
	const [isDocumentSignedBySmsFetching, setIsDocumentSignedBySmsFetching] = useState(false)
	const [file, setFile] = useState(null)
	const [signer, setSigner] = useState({})
	const { applicationTypes, qualifiedSignatureProviders } = useSelector(
		(state) => state.dictionary.signatures
	)
	const [query, setQuery] = useState('')
	const [signatureApplication, setSignatureApplication] = useState(null)
	const searchResult = useSelector((state) => state.search.internal)
	const dispatch = useDispatch()
	const [signerSignatureProvider, setSignerSignatureProvider] = useState(null)

	useEffect(() => {
		if (!signer?.payload) return

		api.sign.getPersonQualifiedSpec({ personId: signer.payload.customerId }).then(({ data }) => {
			setSignerSignatureProvider(data.signatureProvider)
			setSignatureApplication(data.application)
		})
	}, [signer])

	useEffect(() => {
		setSigner({})
	}, [signType])

	const handleOnFileInput = (event) => {
		const files = event.dataTransfer?.files || event.currentTarget?.files

		if (files) {
			const [file] = files

			setFile(file)

			event.dataTransfer?.clearData?.()
		}
	}

	const handleOnClick = () => fileRef.current.click()
	const handleRemoveFile = () => setFile(null)

	const handleSubmit = (fn, form, setFetching) => async () => {
		try {
			setFetching(true)
			await dispatch(fn(form))

			setFetching(false)

			onClose()
		} catch {
			setFetching(false)
		}
	}

	const handleSignWithFile = handleSubmit(
		sendExternalSign,
		{
			file,
			context,
			signer,
			signatureApplication,
			documentId: documentsToSign[0]?.id,
			activeFrom: new Date(file?.lastModified),
			activeTo: addMonths(new Date(file?.lastModified), 12),
		},
		setSendDocumentsToSignFetching
	)

	const handleSign = handleSubmit(
		addSignerDocument,
		{
			type: signType,
			context,
			signatureApplication,
			signer: {
				customerId: signer.payload?.customerId,
				customerType: signer.payload?.customerType,
			},
			documents: documentsToSign.map((item) => item.id),
		},
		setAddSignerFetching
	)

	const choiceBoxProps = {
		className: styles.switch,
		value: signType,
		disabled: !!file,
		onSelect: (option) => {
			setSignType(option.value)
			setSigner({})
			setQuery('')
			setFile(null)
		},
		options: [
			{ label: 'КЭП', value: 'STANDARD' },
			{ label: 'ПЭП', value: 'SIMPLE' },
		],
	}

	const simpleSubmitBtnProps = {
		disabled: isEmpty(signer) || !!file,
		onClick: handleSign,
		mode: 'min',
		loading: isAddSignerFetching,
		className: styles.submit,
		title: 'Отправить',
	}

	const standardSubmitBtnProps = {
		disabled: !file || !!signatureApplication,
		onClick: handleSignWithFile,
		mode: 'min',
		loading: isSendDocumentsToSignFetching,
		className: styles.submit,
		title: 'Подписать файлом',
	}

	const fileInputProps = {
		type: 'file',
		name: 'files',
		ref: fileRef,
		accept: '.sig',
		key: file?.name,
		onChange: handleOnFileInput,
		className: styles.input,
	}

	const manualSigningProps = {
		type: signType,
		context,
		signer: customersForSimpleSignature[0],
		documents: documentsToSign.map((item) => item.id),
	}

	const participantProps = {
		label: 'Участник',
		labelPlacing: 'out',
		placeholder: 'Иванов Иван Иванович',
		scale: 'min',
		value: query,
		onChange: ({ target }) => {
			setQuery(target.value)
			setSigner({})

			dispatch(
				searchEveryplace({
					query: target.value,
					searchIn: { internal: true },
					methodType: 'PERSON,ORGANIZATION',
					params: { size: 5 },
				})
			)
		},
		autocompleteConfig: {
			categories: [
				!!customers.length && { id: 'fast-access', label: 'Быстрый доступ' },
				!!searchResult.length && { id: 'search', label: 'Поиск по базе' },
			].filter(Boolean),
			selected: signer,
			onSelect: (option) => {
				setSigner(option)
				setQuery(option.label)
			},
			options: [
				...customers.map(({ customerId, signatureProvider, name, customerType }) => ({
					categoryId: 'fast-access',
					id: customerId,
					value: customerId,
					label: name,
					payload: {
						customerId,
						customerType,
						signatureProvider,
					},
				})),
				...searchResult.map(({ id, signatureProvider, ...rest }) => ({
					id,
					label: utils.getFullName(rest),
					value: id,
					categoryId: 'search',
					payload: {
						customerId: id,
						customerType: rest.$$type,
						signatureProvider,
					},
				})),
			],
		},
	}

	const copyUrlButtonProps = {
		fullWidth: false,
		indent: 'l',
		type: 'button',
		onClick: async () => {
			try {
				if (isUrlCopied) return

				setIsDocumentUrlFetching(true)

				const { data } = await api.document.getAgreementSharedUrl(documentsToSign[0]?.id)

				copy(data.url)
				setIsUrlCopied(true)
			} catch (error) {
				// eslint-disable-next-line no-console
				console.dir(error)
			} finally {
				setIsDocumentUrlFetching(false)
			}
		},
		styleOverride: { padding: 0 },
		variant: 'transparent-blue',
		size: 'inline',
		disabled: isDocumentUrlFetching || isUrlCopied,
	}

	const isSignedBySms = documentsToSign?.[0].simpleSignatures.some(
		({ context: ctx, signer: comparableSigner }) =>
			ctx.id === context.id && comparableSigner.subject.id === signer.payload?.customerId
	)

	const signedSmsProps = {
		mode: 'min',
		className: styles.signedSmsBtn,
		disabled:
			isDocumentSignedBySms ||
			isDocumentSignedBySmsFetching ||
			documentsToSign.length !== 1 ||
			isSignedBySms,
		onClick: async () => {
			try {
				setIsDocumentSignedBySmsFetching(true)

				await dispatch(
					signAgreement({
						documents: [documentsToSign[0].id],
						context,
						signer: {
							subject: {
								id: signer.payload?.customerId,
								type: 'PERSON',
							},
						},
					})
				)

				setIsDocumentSignedBySms(true)
			} catch {
				//
			} finally {
				setIsDocumentSignedBySmsFetching(false)
			}
		},
		title: 'Документ подписан СМС',
	}

	const signatureApplicationProps = {
		label: 'Приложение',
		labelPlacing: 'out',
		scale: 'min',
		disabled: !applicationTypes.length,
		value: signatureApplication,
		placeholder: 'Выберите сервис',
		onChange: ({ value }) => setSignatureApplication(value === signatureApplication ? null : value),
		options: applicationTypes.map(({ id, title }) => ({ id, label: title })),
	}

	const provider =
		qualifiedSignatureProviders.find(({ id }) => id === signerSignatureProvider)?.title ??
		(signerSignatureProvider ? 'Неизвестное значение' : null)

	return (
		<>
			{documentsToSign.length === 1 &&
				documentsToSign[0].categoryId === 'UNIFIED_PERSONALIZED_CONSENT' && (
					<KitButton {...copyUrlButtonProps}>
						{isUrlCopied
							? 'Ссылка на документ скопирована'
							: isDocumentUrlFetching
							? 'Создаем ссылку...'
							: 'Скопировать документ'}
					</KitButton>
				)}
			<ChoiceBox {...choiceBoxProps} />
			<div className={styles.wrap}>
				{signType === 'STANDARD' ||
				(customersForSimpleSignature.length && signType === 'SIMPLE') ? (
					<div className={styles.col}>
						<div className={styles.item}>
							{signType === 'STANDARD' && (
								<Grid.Section gap='l'>
									<Input {...participantProps} />
									<Select {...signatureApplicationProps} />
								</Grid.Section>
							)}
							{signType === 'SIMPLE' && (
								<>
									<Text font='small' indent='xs'>
										Участник
									</Text>
									{customersForSimpleSignature.map((customer, index) => (
										<div
											className={cx(styles.customer, {
												[styles.active]: customer.customerId === signer.payload?.customerId,
											})}
											key={index}
											onClick={() => setSigner({ payload: customer })}
										>
											{customer.name}
										</div>
									))}
								</>
							)}
						</div>
						<div className={styles.controls}>
							<Button {...simpleSubmitBtnProps} />
						</div>
					</div>
				) : (
					<div>Не найдены участники для подписи</div>
				)}
				{signType === 'SIMPLE' && (
					<div className={styles.simpleWrap}>
						<div>
							<Esia documentId={documentsToSign[0].id} signer={signer} />
							{documentsToSign.length === 1 &&
								documentsToSign[0].categoryId === 'UNIFIED_IMPERSONAL_CONSENT' && (
									<ManualSigning {...manualSigningProps} />
								)}
						</div>
						{documentsToSign.length === 1 &&
							signer.payload &&
							documentsToSign[0].categoryId === 'UNIFIED_PERSONALIZED_CONSENT' && (
								<Button {...signedSmsProps} />
							)}
					</div>
				)}
				{signType === 'STANDARD' && documentsToSign.length === 1 && (
					<Grid.Section gap='l' style={{ alignContent: 'space-between' }}>
						<Grid.Section gap='xs'>
							<Text font='small'>Провайдер подписания</Text>
							<Text>{defaultOr(provider)}</Text>
						</Grid.Section>
						<Grid.Section gap='xs' style={{ alignContent: 'flex-start' }}>
							<Text indent='xs' weight='bold' font='small'>
								Загрузить SIG файл подписи
							</Text>
							<div onDrop={handleOnFileInput}>
								<input {...fileInputProps} />
								<Grid.Row sizes='auto 1fr' style={{ alignItems: 'center' }} gap='s'>
									<KitButton
										type='button'
										onClick={handleOnClick}
										variant='transparent-blue'
										size='inline'
										styleOverride={{ padding: 0, justifySelf: 'flex-start' }}
									>
										Выбрать
									</KitButton>
									{file?.name && (
										<Text as='div' variant='secondary'>
											{file.name}
											<i
												onClick={handleRemoveFile}
												className={cx('zmdi zmdi-close', styles.close)}
											/>
										</Text>
									)}
								</Grid.Row>
							</div>
						</Grid.Section>
						<Button {...standardSubmitBtnProps} />
					</Grid.Section>
				)}
			</div>
		</>
	)
}

SendDocumentsToSign.propTypes = {
	id: PropTypes.string,
	customers: PropTypes.array,
	customersForSimpleSignature: PropTypes.array,
	context: PropTypes.object,
	categoryId: PropTypes.string,

	onClose: PropTypes.func.isRequired,
	addSignerDocument: PropTypes.func.isRequired,
	sendExternalSign: PropTypes.func.isRequired,
}

export default SendDocumentsToSign
