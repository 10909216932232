import type { ScoringDocuments } from 'converters/scoring/person/documents/types'
import type { TUtils } from 'types'

import { moneyUtils } from '@creditclubteam/helpers'
import { DefaultValues, numberOrNull } from 'helpers/convertHelper'

const getPeriods = (data?: Record<string, any>): ScoringDocuments.Field[] => {
	const model = new DefaultValues(data)

	const amount = model.null<number>(['amount'])

	return [
		{
			collection: 'periods',
			type: 'MASK',
			key: 'month',
			value: model.string(['month']),
			title: 'Номер месяца',
			transformValue: numberOrNull,
			mask: '_'.repeat(2),
		},
		{
			key: 'amount',
			value: amount,
			readonlyValue: moneyUtils.RUB(amount),
			type: 'NUM',
			title: 'Сумма дохода',
		},
	]
}

export const knd1122036 = <T extends Record<string, any>>(
	data: T,
	isTemplate?: boolean
): TUtils.Maybe<ScoringDocuments.SourceDocument> => {
	if (!isTemplate && !data) return null

	const model = new DefaultValues(data)

	const periods = model.array(['periods'])

	return {
		id: 'knd1122036',
		collections: [['periods', getPeriods]],
		rows: [
			[
				{
					type: 'MASK',
					key: 'year',
					span: '1/4',
					mask: '_'.repeat(4),
					transformValue: numberOrNull,
					value: model.string(['year']),
					title: 'Год',
				},
			],

			...periods.reduce((acc: ScoringDocuments.Field[][], curr) => {
				acc.push(getPeriods(curr))

				return acc
			}, []),
		],
	}
}
