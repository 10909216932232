import type { DetailedHTMLProps, ReactNode, TextareaHTMLAttributes } from 'react'
import styled, { type CSSProperties } from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { Text } from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'

export type TextareaV2Props = Omit<
	DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
	'ref'
> &
	Pick<CSSProperties, 'resize'> & { error?: boolean; label?: ReactNode }

const Styled = {
	textarea: styled.textarea<{
		$resize: TextareaV2Props['resize']
		$error: TextareaV2Props['error']
	}>(({ theme, $resize, $error }) => ({
		border: `1px solid ${theme.colors.border}`,
		borderRadius: theme.borderRadius.xs,
		resize: $resize,
		width: '100%',
		minHeight: 48,
		padding: `${theme.spaces.s}px ${theme.spaces.m}px`,
		transition: 'border-color .3s ease-in-out',

		'::placeholder': {
			color: theme.colors.textSecondary,
		},

		':focus': {
			borderColor: theme.colors.active,
		},

		...mixins.when($error, {
			borderColor: theme.colors.negative,

			':focus': {
				borderColor: theme.colors.negative,
			},
		}),

		...theme.fonts.body,
	})),
}

export const TextareaV2 = ({ resize, error, label, ...props }: TextareaV2Props) => {
	return label ? (
		<Grid.Section gap='xs'>
			<Text font='small'>{label}</Text>
			<Styled.textarea {...props} $resize={resize} $error={error} />
		</Grid.Section>
	) : (
		<Styled.textarea {...props} $resize={resize} $error={error} />
	)
}
