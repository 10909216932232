import type { FormikProps } from 'formik'

import { type ButtonProps, Button } from '@creditclubteam/kit/ui-components'
import FormLayout from 'components/common/FormLayout'

interface Props {
	form: FormikProps<any>
	buttonLabel: string
}

const Controls = ({ form, buttonLabel }: Props) => {
	const submitButton: ButtonProps = {
		children: buttonLabel,
		disabled: form.isSubmitting,
	}

	return (
		<div className={FormLayout.styles.controls}>
			<Button {...submitButton} />
		</div>
	)
}

export default Controls
