import { DefaultValues } from 'helpers/convertHelper'

import { beneficiary } from './beneficiary'
import { contactPerson } from './contactPerson'
import { creditHistoryAgreement } from './creditHistoryAgreement'
import { dependent } from './dependent'
import { marriageInfo } from './marriageInfo'
import { nationality } from './nationality'
import { passport } from './passport'
import { passportHistory } from './passportHistory'
import { publicAccount } from './publicAccount'
import { workInfo } from './workInfo'

type RealEstateType = 'APARTMENT' | 'COUNTRY_HOUSE' | 'OTHER' | 'PARCEL' | 'SHARE_APARTMENT'
type MovablePropertyType = 'BOAT' | 'ONE_CAR_OR_MOTORCYCLE' | 'OTHER' | 'SEVERAL_CAR_OR_MOTORCYCLE'
type OtherAssetType = 'BONDS' | 'STOCKS'
type SocialStatusType =
	| 'ENTREPRENEUR'
	| 'LEADING_CIVIL_SERVANT'
	| 'PENSIONER'
	| 'QUALIFIED_CIVIL_SERVANT'
	| 'QUALIFIED_EMPLOYEE'
	| 'WORKER'

export const form = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		snils: model.string(['snils']),
		taxId: model.string(['taxId']),
		gender: model.string(['gender']),
		email: model.string(['email']),
		verificationStatus: model.string(['verificationStatus']),
		internationalPassportDecision: model.string(['form', 'internationalPassportDecision']),
		education: model.string(['form', 'education']),
		educationalInstitution: model.string(['form', 'educationalInstitution']),
		specialty: model.string(['form', 'specialty']),
		mainWorkplace: model.string(['form', 'mainWorkplace']),
		gosuslugiAccount: model.string(['form', 'gosuslugiAccount']),
		accountNumber: model.string(['bankForm', 'accountNumber']),
		bic: model.string(['bankForm', 'bic']),
		bankName: model.string(['bankForm', 'bankName']),
		correspondentAccount: model.string(['bankForm', 'correspondentAccount']),
		businessGoals: model.string(['form', 'businessGoals']),
		natureBusinessRelationships: model.string(['form', 'natureBusinessRelationships']),
		financialActivity: model.string(['form', 'financialActivity']),
		businessReputation: model.string(['form', 'businessReputation']),
		financialSituation: model.string(['form', 'financialSituation']),
		representativeExists: model.null<boolean>(['form', 'representativeExists']),
		mobilizationRisk: model.null<boolean>(['form', 'mobilizationRisk']),
		beneficiaryExists: model.null<boolean>(['form', 'beneficiaryExists']),
		bornInForeignCountry: model.null<boolean>(['form', 'bornInForeignCountry']),
		phoneFromForeignCountryExists: model.null<boolean>(['form', 'phoneFromForeignCountryExists']),
		citizenshipForeignCountryExists: model.null<boolean>([
			'form',
			'citizenshipForeignCountryExists',
		]),
		taxResidenceForeignCountryExists: model.null<boolean>([
			'form',
			'taxResidenceForeignCountryExists',
		]),
		addressForeignCountryExists: model.null<boolean>(['form', 'addressForeignCountryExists']),
		representativeFromForeignCountryExists: model.null<boolean>([
			'form',
			'representativeFromForeignCountryExists',
		]),
		militaryRegistrationDocument: model.null<boolean>(['form', 'militaryRegistrationDocument']),
		infoPassageMilitaryDepartment: model.null<boolean>(['form', 'infoPassageMilitaryDepartment']),
		disabilityBefore18Years: model.null<boolean>(['form', 'disabilityBefore18Years']),
		recordOfMilitaryAlternative: model.null<boolean>(['form', 'recordOfMilitaryAlternative']),
		militarySpecialty: model.string(['form', 'militarySpecialty']),
		militaryRank: model.string(['form', 'militaryRank']),
		negativeInPublicAccounts: model.null(['form', 'negativeInPublicAccounts']),
		income: model.null<number>(['form', 'income']),
		officialMonthlyIncome: model.null<number>(['form', 'officialMonthlyIncome']),
		familyMonthlyIncome: model.null<number>(['form', 'familyMonthlyIncome']),
		monthlyExpenses: model.null<number>(['form', 'monthlyExpenses']),
		pfrCertificate: model.null<boolean>(['form', 'pfrCertificate']),
		dependents: model.null<number>(['form', 'dependents']),
		totalContinuousWorkExperienceMonths: model.null<number>([
			'form',
			'totalContinuousWorkExperienceMonths',
		]),
		residenceAddress: model.null<Record<string, any>>(['form', 'residenceAddress']),
		passportHistory: model.array(['form', 'passportHistory']).map(passportHistory),
		publicAccounts: model.array(['form', 'publicAccounts']).map(publicAccount),
		marriageInfos: model.array(['form', 'marriageInfos']).map(marriageInfo),
		incomeSources: model.array<string[]>(['form', 'incomeSources']),
		dependentsList: model.array(['form', 'dependentsList']).map(dependent),
		nationality: nationality(data.nationality),
		passport: passport(data.passport),
		creditHistoryAgreement: creditHistoryAgreement(data.form.creditHistoryAgreement),
		contactPerson: contactPerson(data.form.contactPerson),
		efrsbNegative: data.form.efrsbNegative,
		outOfCourtBankruptcy: data.form.outOfCourtBankruptcy,
		publicOfficial: model.string(['form', 'publicOfficial']),
		publicOfficialRelationKind: model.string(['form', 'publicOfficialRelationKind']),
		publicOfficialJobTitle: model.string(['form', 'publicOfficialJobTitle']),
		beneficiary: beneficiary(data.form.beneficiary),
		workInfo: workInfo(data.form.workInfo),
		publicOfficialRelative: model.string(['form', 'publicOfficialRelative']),
		residenceAddressDate: model.string(['form', 'residenceAddressDate']),
		ownershipStatus: model.string(['form', 'ownershipStatus']),
		familyStatus: model.const(data.form.familyStatus, {
			MARRIED: 'MARRIED',
			SINGLE: 'SINGLE',
			CIVIL_MARRIAGE: 'CIVIL_MARRIAGE',
			DIVORCED: 'DIVORCED',
			WIDOW: 'WIDOW',
		}),
		alimony: model.null<number>(['form', 'alimony']),
		realEstateOwnership: model.array<RealEstateType[]>(['form', 'realEstateOwnership']),
		movablePropertyOwnership: model.array<MovablePropertyType[]>([
			'form',
			'movablePropertyOwnership',
		]),
		otherAssetsOwnership: model.array<OtherAssetType[]>(['form', 'otherAssetsOwnership']),
		socialStatus: model.string<SocialStatusType>(['form', 'socialStatus']),
		bankDepositsAmount: model.null<number>(['form', 'bankDepositsAmount']),
	}
}
