import clsx from 'clsx'
import { useTheme } from 'styled-components'

import { mixins } from '@creditclubteam/kit/styled'
import { Button } from '@creditclubteam/kit/ui-components'

import styles from './Actions.module.scss'

export const Actions = (props) => {
	const { actions, hoverClass } = props
	const { colors } = useTheme()

	return (
		<div className={styles.wrap}>
			{actions
				.filter(({ isVisible = true }) => isVisible)
				.map(({ onClick, color, isDisabled, styles: actionStyles, title }, index) => (
					<Button
						key={index}
						title={title}
						onClick={onClick}
						size='inline'
						type='button'
						styleOverride={Object.assign(
							{ padding: 0 },
							mixins.ellipsis(),
							isDisabled && { color: colors.textSecondary }
						)}
						disabled={isDisabled}
						variant={color ?? 'transparent-blue'}
						className={clsx(styles.btn, actionStyles, hoverClass)}
					>
						{title}
					</Button>
				))}
		</div>
	)
}
