import { ENTITY_TYPES } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

import { calculation } from './calculation'
import { calculationV2 } from './calculationV2'
import { commission } from './commission'
import { entityMetadata } from './entityMetadata'
import { insurance } from './insurance'
import { participant } from './participant'
import { preliminarySchedule } from './preliminarySchedule'
import { share } from './share'
import { status } from './status'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		traceNumber: model.string(['traceNumber']).toLowerCase().replace(/g/ig, ''),
		createdAt: model.string(['createdAt']),
		yandexClientId: model.string(['yandexClientId']),
		financialProduct: model.string(['financialProduct']),
		product: model.string(['product']),
		oneCLoanId: model.string(['oneCLoanId']),
		channel: model.string(['channel']),
		purpose: model.string(['purpose']),
		detailedPurpose: model.string(['detailedPurpose']),
		city: model.string(['city']),
		contractDurationMonths: model.string(['contractDurationMonths']),
		forInvestment: model.null<boolean>(['forInvestment']),
		loanIssueMethod: model.string<'MKK_CHECKING_ACCOUNT' | 'NOMINAL_ACCOUNT' | 'KPK'>([
			'loanIssueMethod',
		]),
		guarantees: model.array<string[]>(['guarantees']),
		participants: model.array(['participants']).map(participant),
		share: model.array(['share']).map(share),
		commissions: model.array(['commissions']).map(commission),
		facilities: model.array<string[]>(['facilities']),
		workers: model.null<Record<string, string>>(['workers']),
		collection: model.null<boolean>(['collection']),
		firstPayment: model.null<number>(['firstPayment']),
		totalLoanAmount: model.null<number>(['totalLoanAmount']),
		insurance: model.array(['insurance']).map(insurance),
		totalIssueCount: model.null<number>(['totalIssueCount']),
		platformCommission: model.null<number>(['platformCommission']),
		marketingData: model.null<Record<string, any>>(['marketingData']),
		preliminarySchedule: data.preliminarySchedule
			? preliminarySchedule(data.preliminarySchedule)
			: null,
		calculation: data.calculation ? calculation(data.calculation) : null,
		calculationV2: data.calculationV2 ? calculationV2(data.calculationV2) : null,
		lead: data.lead ? entityMetadata(data.lead) : null,
		application: data.application ? entityMetadata(data.application) : null,
		sourceLoan: data.sourceLoan ? entityMetadata(data.sourceLoan) : null,
		soldLoan: data.soldLoan ? entityMetadata(data.soldLoan) : null,
		status: status(data.status),
		$$type: ENTITY_TYPES.LOAN,
	}
}
