import type { Facility } from 'converters/facility'

import { join } from '@creditclubteam/kit/helpers'

export const getFacilityInfo = (facility?: Facility.Main): { info: string; address?: string } => {
	return {
		info: join(
			[
				join([facility?.typeRus, facility?.form.area && `${facility.form.area} м²`]),
				facility?.cadastralId,
			],
			', '
		),
		address: facility?.address?.mergedAddress,
	}
}
