export const DEPOSIT_STATUSES_RUS = {
	SIGNING: 'Подписание',
	OPEN: 'Открыт',
	ON_REGISTRATION: 'Оформление',
	CLOSED: 'Закрыт',
	REFUSED: 'Отменен',
}

export type DepositStatuses = keyof typeof DEPOSIT_STATUSES_RUS

export const DEPOSIT_SORT_RUS = {
	newest: { label: 'Сначала новые', value: 'newest' },
	oldest: { label: 'Сначала поздние', value: 'oldest' },
	youngest: { label: 'Сначала ближайшие к закрытию', value: 'youngest' },
	maturest: { label: 'Сначала поздние к закрытию', value: 'maturest' },
} as const

export type DepositSort = keyof typeof DEPOSIT_SORT_RUS

export const DEPOSIT_INTEREST_PAYMENT_RUS: Record<'AT_MATURITY' | 'MONTHLY', string> = {
	AT_MATURITY: 'В конце срока',
	MONTHLY: 'Ежемесячно',
}

export const DURATION_VALUES = {
	'6': 6,
	'12': 12,
	'24': 24,
}

export const DEPOSIT_MONEY_MOVEMENT_TYPE_RUS: Record<string, string> = {
	DEPOSIT_REFILL: 'Внесение сбережений',
	INTEREST_ACCRUAL: 'Начисление процентов',
	PARTIAL_DEPOSIT_WITHDRAWAL: 'Частичное снятие сбережения',
	DEPOSIT_WITHDRAWAL: 'Сбережение закрыто',
	INTEREST_PAYMENT: 'Выплата процентов',
	CASHBACK: 'Начисление кэшбэка',
	CASHBACK_PAYMENT: 'Выплата кэшбэка',
}

export const DEPOSIT_TYPES_TO_REQUEST = {
	INNER: 'Внутренний',
	CASHBACK: 'Повышенный кешбек',
}
