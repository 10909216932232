import {
	type ComponentPropsWithoutRef,
	type PropsWithChildren,
	type ReactNode,
	useCallback,
	useState,
} from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { type CSSObject, type StyledComponentInnerOtherProps, useTheme } from 'styled-components'

import type { Spaces } from '@creditclubteam/kit/styled'
import { Text, Tooltip } from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'

interface AccordionProps {
	isDefaultExpanded?: boolean
	label: ReactNode
	padding?: Spaces
	hintText?: ReactNode
}

export const Accordion = ({
	isDefaultExpanded = true,
	label,
	children,
	padding,
	hintText,
}: PropsWithChildren<AccordionProps>) => {
	const [isExpanded, setIsExpanded] = useState(isDefaultExpanded)
	const { spaces, borderRadius } = useTheme()

	const btnStyle: CSSObject = {
		borderRadius: borderRadius.xs,
		backgroundColor: '#eff1f2',
		padding: spaces.l,
	}

	const accordionTitleProps: StyledComponentInnerOtherProps<typeof Text> &
		ComponentPropsWithoutRef<typeof Text> = {
		styleOverride: {
			...btnStyle,
			fontWeight: 'bold',
			cursor: children ? 'pointer' : 'default',
			textAlign: 'left',
			width: '100%',
			border: 'none',
		},
		type: 'button',
		weight: 'bold',
		pointer: true,
		font: 'buttonLarge',
		onClick: useCallback(() => setIsExpanded((p) => !p), []),
	}

	return (
		<Grid.Section gap='l'>
			<Text as='button' {...accordionTitleProps}>
				<Grid.Row sizes='1fr auto' style={{ alignItems: 'center' }}>
					<Grid.Row sizes='auto 1fr' gap='s' style={{ alignItems: 'center' }}>
						{label}
						{hintText && (
							<Tooltip
								element={hintText}
								triggerElement={({ setTriggerRef }) => (
									<Icon ref={setTriggerRef as any} icon='zmdi-help-outline' />
								)}
							/>
						)}
					</Grid.Row>
					{children && <Icon icon={isExpanded ? 'zmdi-chevron-up' : 'zmdi-chevron-down'} />}
				</Grid.Row>
			</Text>
			{isExpanded && children && (
				<Grid.Section gap='l' style={padding ? { padding: `0 ${spaces[padding]}px` } : undefined}>
					{children}
				</Grid.Section>
			)}
		</Grid.Section>
	)
}
