import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addSignerDocument, confirmSms, sendSms } from 'actions/document'
import clsx from 'clsx'
import { useFormik } from 'formik'
import type { TRedux } from 'types'

import { Input } from '@creditclubteam/kit/legacy-ui-components'
import { FormLayout, Loading } from 'components/common'
import type { CancelableThunkPromise } from 'helpers/cancelablePromise'
import { makeCancelable } from 'helpers/cancelablePromise'

import styles from './ManualSigning.module.scss'

interface Props {
	type: 'STANDARD' | 'SIMPLE'
	context?: Record<string, any>
	signer?: Record<string, any>
	documents: Record<string, any>[]
}

export const ManualSigning = (props: Props) => {
	const dispatch = useDispatch<TRedux.TDispatch>()

	const addSignerDocumentPromise = useRef<CancelableThunkPromise<typeof addSignerDocument>>(null)
	const sendSmsPromise = useRef<CancelableThunkPromise<typeof sendSms>>(null)
	const confirmSmsPromise = useRef<CancelableThunkPromise<typeof confirmSms>>(null)

	useEffect(() => {
		return () => {
			addSignerDocumentPromise.current?.cancel()
			sendSmsPromise.current?.cancel()
			confirmSmsPromise.current?.cancel()
		}
	}, [])

	const [isFetching, setIsFetching] = useState(false)
	const [transactionId, setTransactionId] = useState(null)

	const { values, resetForm, handleChange, handleSubmit, submitForm, status } = useFormik({
		initialValues: {
			code: '',
		},
		onSubmit: ({ code }, { resetForm, setStatus }) => {
			setIsFetching(true)

			confirmSmsPromise.current = makeCancelable(
				dispatch(
					confirmSms({
						transactionId,
						documentId: props.documents[0],
						code,
						signer: props.signer,
						context: props.context,
					})
				)
			)

			confirmSmsPromise.current.promise
				.then(() => {
					setStatus('confirmed')
					setTransactionId(null)
				})
				.catch((error) => {
					if (error?.isCanceled) return
					setIsFetching(false)
					resetForm()
				})
		},
	})

	const onError = useCallback((error: any) => {
		if (error?.isCanceled) return
		setIsFetching(false)
	}, [])

	const init = () => {
		setIsFetching(true)

		addSignerDocumentPromise.current = makeCancelable(
			dispatch(
				addSignerDocument({
					type: props.type,
					context: props.context,
					signer: props.signer,
					documents: props.documents,
				})
			)
		)

		addSignerDocumentPromise.current.promise.then(handleSendSms).catch(onError)
	}

	const handleSendSms = () => {
		if (!isFetching) setIsFetching(true)
		if (values.code) resetForm()

		sendSmsPromise.current = makeCancelable(
			dispatch(
				sendSms({
					context: props.context,
					signer: props.signer,
				})
			)
		)

		sendSmsPromise.current.promise
			.then(({ id }) => {
				setTransactionId(id)
				setIsFetching(false)
			})
			.catch(onError)
	}

	const codeProps: React.ComponentProps<typeof Input> = {
		value: values.code,
		onChange: (_, event) => handleChange(event),
		name: 'code',
		mode: 'min',
		className: clsx(FormLayout.styles.cell, styles.input),
	}

	useEffect(() => {
		if (values.code.length === 4) {
			submitForm()
		}
	}, [values.code, submitForm])

	return (
		<div className={styles.wrap}>
			<div className={styles.title}>Вручную</div>
			{status === 'confirmed' ? (
				<span>Подтверждено</span>
			) : isFetching ? (
				<Loading min className={styles.loader} />
			) : !transactionId ? (
				<span className={styles.send} onClick={init}>
					Отправить СМС с кодом
				</span>
			) : (
				<form autoComplete='off' onSubmit={handleSubmit}>
					<Input {...codeProps} />
					<span className={styles.send} onClick={handleSendSms}>
						Отправить СМС ещё раз
					</span>
				</form>
			)}
		</div>
	)
}
