import axios from 'axios'
import { nanoid } from 'nanoid'
import { remove, set } from 'reducers/serverErrors'

const setServerError = set
export const removeServerError = remove

/**
 * @param {any} errorData.err - ошибка
 * @param {string} errorData.text - текст ошибки
 * @param {string} errorData.details - текст ошибки
 * @param {boolean} errorData.system - выводить ли на ui
 */
export const addServerError = (errorData) => (dispatch) => {
	const { text, details = {}, system = true } = errorData

	if (axios.isCancel(details.originError)) {
		return
	}

	console.error(errorData)

	const error = {
		text,
		system,
		details,
		id: nanoid(),
	}

	dispatch(setServerError(error))
}

// Удалить всё ошибки с флагом system = true
export const removeServerErrorsFromUi = () => (dispatch, getState) => {
	const { errors } = getState().serverErrors

	errors.forEach(({ system, id }) => {
		if (system) dispatch(removeServerError(id))
	})
}
