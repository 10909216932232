import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { findIndex, move, or, pathEq } from 'ramda'
import { actions as deliveryActions } from 'reducers/delivery/single'
import { actions as leadInvestmentActions } from 'reducers/investmentLead/single'
import { loanSingleReducerActions } from 'reducers/loan/single'
import { actions as loanSellApplication } from 'reducers/loanSellApplication/single'
import { actions } from 'reducers/pipeline'
import type { TRedux, TUtils } from 'types'

import { utils } from 'helpers'

export const resetPipeline = actions.reset

const setActions = {
	investmentLead: leadInvestmentActions.setData,
	delivery: deliveryActions.setData,
	loan: loanSingleReducerActions.setData,
	loanSellApplication: loanSellApplication.setData,
} as const

type Request<T extends 'pipeline' | 'transitions' | 'status'> = T extends 'status'
	? `update${Capitalize<T>}`
	: `get${Capitalize<T>}`

type Api = typeof api

type Services<T, U extends { [key in keyof T]: keyof T[key] }[keyof T]> = keyof {
	[P in keyof T as T[P][U] extends {} ? P : never]: never
}

type PipelineOptions<T extends Api[IncludePipeline]> = TUtils.ArrayElement<
	Parameters<T[Request<'pipeline'>]>
>

type IncludePipeline = Services<Api, Request<'pipeline'>>

type GetPipelineParameters<S extends IncludePipeline> = PipelineOptions<Api[S]>

/**
 * @TODO
 * Когда все сущности перейдут на новую модель пайплайнов,
 * надо переделать метод так, чтобы он принимал только объект
 * в качестве параметра { service, target: 'list' | 'single', options }
 */
export const getPipeline =
	<S extends IncludePipeline, O extends GetPipelineParameters<S>>(
		service: S,
		options?: O
	): TRedux.TAction<Promise<void>> =>
	async (dispatch, getState) => {
		const { isFetching } = getState().pipeline

		if (!isFetching) dispatch(actions.setFetchingStatus(true))

		try {
			// @ts-expect-error
			const { data: value } = await api[service].getPipeline(options)

			dispatch(
				service === 'investmentLead' || service === 'delivery' || service === 'loanSellApplication' // новые пайплайны
					? actions.setPipeline({ key: options === 'ALL' ? 'list' : 'single', value })
					: actions.setStatuses({
							key: service,
							value,
					  })
			)

			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить статусы',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const getTransitions =
	<S extends Services<Api, Request<'transitions'>>>(
		service: S,
		entityId: string
	): TRedux.TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			const { data } = await api[service].getTransitions(entityId)

			const lastTransitionIdx = findIndex(
				or(pathEq(['event', 'id'], ['REFUSE']) as any, pathEq(['event', 'id'], ['CANCEL']) as any),
				data
			)

			const reordered =
				lastTransitionIdx >= 0 ? move(lastTransitionIdx, data.length - 1, data) : data

			dispatch(actions.setTransitions(reordered))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось получить переходы статусов',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const updateStatus =
	<S extends Services<Api, Request<'status'>>>(
		service: S,
		{ entityId, eventId }: { entityId: string; eventId: string }
	): TRedux.TAction<Promise<any>> =>
	async (dispatch) => {
		try {
			const body = service !== 'loanSellApplication' ? { event: eventId } : { eventId }
			// @ts-expect-error
			const { data } = await api[service].updateStatus(entityId, body)

			// @ts-expect-error
			await dispatch(getTransitions(service, entityId))

			// @ts-expect-error
			dispatch(setActions[service](data))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось обновить статус',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}
