import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import clsx from 'clsx'
import type { O } from 'ts-toolbelt'

import { join } from '@creditclubteam/kit/helpers'
import { Button, Grid, Text } from '@creditclubteam/kit/ui-components'
import ModalDialog from 'components/common/ModalDialog'
import { utils } from 'helpers'

import { Tabs } from './Tabs'

import styles from './CommentHints.module.scss'

const CALL_CENTER_HASHTAG_TITLES = {
	FIRST_CONTACT_RESULTS: 'Итоги первого контакта',
} as const

const LEADGEN_HASHTAG_TITLES = {
	OBJECT: 'Объект',
	ADDITIONAL_ON_THE_OBJECT: 'Дополнительно по квартире',
	TRANSFERRED_TO_PARTNER: 'Передан партнёру',
	CURRENT_WORK: 'Текущая работа',
	INFORMATION_ON_THE_BORROWER: 'Информация по заёмщику',
	INFORMATION_ON_THE_PLEDGER: 'Информация по залогодателю',
} as const

const CKB_HASHTAG_TITLES = {
	CREDITORS: 'Кредиторы',
	CLIENT_INFO: 'Информация по клиенту',
} as const

const CALL_CENTER_HASHTAGS = new Map(
	Object.entries({
		[CALL_CENTER_HASHTAG_TITLES.FIRST_CONTACT_RESULTS]: [
			'Не подходит платеж',
			'Не подходит ставка',
			'Не хочет в Credit.Club',
			'Хочет в офис',
			'Не хочет под залог',
			'Получил одобрение в банке',
			'Пока неактуально',
			'Не оставлял заявку',
			'Не интересовался кредитованием',
		],
	})
)

const CKB_HASHTAGS = new Map(
	Object.entries({
		[CKB_HASHTAG_TITLES.CREDITORS]: [
			'Совкомбанк',
			'МКБ',
			'Т-Банк',
			'БЖФ',
			'Ипотека24',
			'МKK_Интернет_решения',
			'ПКБ',
			'БаренцФинанс',
			'CarMoney',
			'CreditClub',
			'АТБ',
			'УралСиб',
		],
		[CKB_HASHTAG_TITLES.CLIENT_INFO]: [
			'Отказ брокеру',
			'Без залога',
			'Неликвидный объект',
			'Отказ по юр оценке',
		],
	})
)

const LEADGEN_HASHTAGS = new Map(
	Object.entries({
		[LEADGEN_HASHTAG_TITLES.OBJECT]: [
			'Без залога',
			'Квартира',
			'Авто',
			'Загородная',
			'Комната',
			'Земля',
			'Коммерческая',
			'Доля',
			'Гараж',
			'Стоянка',
		],
		[LEADGEN_HASHTAG_TITLES.ADDITIONAL_ON_THE_OBJECT]: [
			'Не подходит город',
			'Не проходит по требованиям',
			'Маткап',
			'Несовершеннолетние',
			'Собственники не дают согласий',
			'Целевая',
			'Есть обременение',
			'Приобретался в браке',
		],
		[LEADGEN_HASHTAG_TITLES.TRANSFERRED_TO_PARTNER]: ['ЦКБ', 'Святошенко', 'МСК'],
		[LEADGEN_HASHTAG_TITLES.CURRENT_WORK]: [
			'Нет согласий',
			'Неактуально',
			'Возвращаю на КЦ',
			'Не наше ГЕО',
			'Не подходящий объект',
			'Запросил информацию о собственниках',
		],
		[LEADGEN_HASHTAG_TITLES.INFORMATION_ON_THE_BORROWER]: [
			'Хочет ставку ниже',
			'Не подходит платёж',
			'Не устроил процесс',
			'Не проходит по КИ',
			'Не проходит по ФССП',
			'Не проходит по возрасту',
			'Получил деньги у другого кредитора',
			'Неактуально',
			'Сбрасывает',
			'Недозвон',
			'Не может подтвердить доход',
			'Банкрот',
			'Нет «Госуслуг»',
			'Не хочет проходить «Госуслуги»',
			'Отказался рефинансироваться из-за ставки',
			'Не устраивает сумма',
			'Отказался от передачи партнеру',
			'Не хочет под залог',
			'Не хочет в Credit.Club',
			'Получил одобрение в банке',
			'Отказ СБ',
			'Отказ по подконтрольным юрлицам',
			'Отказ на встрече',
		],
		[LEADGEN_HASHTAG_TITLES.INFORMATION_ON_THE_PLEDGER]: [
			'Не проходит по кредитной истории',
			'Не проходит по ФССП',
			'Не проходит по возрасту',
			'Сбрасывает',
			'Недозвон',
			'Не может подтвердить доход',
			'Банкрот',
			'Нет «Госуслуг»',
		],
	})
)

interface LabelProps {
	hint?: string
	onSelectHint?: (value: string) => void
	value?: string
	category?: string
	subCategory?: string
}

const Label = ({
	subCategory,
	value,
	hint,
	category,
	onSelectHint,
	children,
}: PropsWithChildren<LabelProps>) => {
	const formattedHint = hint?.replace(/[\s]/g, '_').replace(/[,.]/g, '')
	const fullHint = `#${utils.join([category, subCategory, formattedHint], '/')}\n`

	const handleSelect = () => {
		onSelectHint?.(
			value?.includes(fullHint!)
				? value.replaceAll(fullHint, '')
				: utils.join([value, fullHint], '')
		)
	}

	if (!hint && subCategory) {
		return (
			<Text font='body' weight='bold'>
				{subCategory}
			</Text>
		)
	}

	if (!hint && !subCategory) {
		return (
			<Text font='body' weight='bold'>
				{children}
			</Text>
		)
	}

	return (
		<Button
			variant='transparent'
			size='inline'
			type='button'
			styleOverride={{ width: 'fit-content', padding: 0, lineHeight: 'unset', textAlign: 'left' }}
			className={clsx({
				[styles.active]: value?.includes(`${category}/${formattedHint}`),
			})}
			onClick={handleSelect}
		>
			<Text as='span' font='body'>
				{hint}
			</Text>
		</Button>
	)
}

export interface CommentHintsProps {
	isOpen: boolean
	text: string
	onAddHints: (value: string) => void
	onClose: () => void
}

export const CommentHints = ({ isOpen, onAddHints, text, onClose }: CommentHintsProps) => {
	const [value, setValue] = useState('')

	const modalProps = {
		large: true,
		opened: isOpen,
		onAfterClose: () => {
			setValue('')
		},
		title: 'Подсказки к комментариям',
		subTitle: 'Выберите подсказки через клик и подтвердите выбор, чтобы вставить их в комментарий',
		onClose,
	}

	const handleAddHints = () => {
		onAddHints(
			join(
				[
					text,
					text && '\n',
					Object.entries(
						value.match(/#.+\n/g)?.reduce((acc, curr) => {
							const [category, ...rest] = curr.split('/')

							if (acc[category]) {
								acc[category].push(...rest)
							} else {
								acc[category] = [...rest]
							}

							return acc
						}, {} as Record<string, string[]>) ?? {}
					).reduce((acc, [category, hashtags]) => {
						acc = join(
							[
								acc,
								acc && '\n',
								category.replace('#', ''),
								join(
									hashtags.map((v) => `#${v}`.replace('\n', '')),
									' '
								),
							],
							'\n'
						)
						return acc
					}, ''),
				],
				'\n'
			)
		)
		onClose()
	}

	const commonHintProps = (
		category:
			| keyof O.Invert<typeof LEADGEN_HASHTAG_TITLES>
			| keyof O.Invert<typeof CALL_CENTER_HASHTAG_TITLES>
			| keyof O.Invert<typeof CKB_HASHTAG_TITLES>
	): Pick<LabelProps, 'onSelectHint' | 'value' | 'category'> => ({
		onSelectHint: setValue,
		value,
		category,
	})

	return (
		<ModalDialog {...modalProps}>
			<Tabs
				items={[
					{
						content: (
							<>
								<Grid.Row className={styles.content} $gap='xl' $sizes='1fr 2fr'>
									<Grid.Section style={{ height: 'fit-content' }}>
										<Grid.Section style={{ gridTemplateRows: 'min-content' }} $gap='xs'>
											<Label>{CALL_CENTER_HASHTAG_TITLES.FIRST_CONTACT_RESULTS}</Label>
											{CALL_CENTER_HASHTAGS.get(
												CALL_CENTER_HASHTAG_TITLES.FIRST_CONTACT_RESULTS
											)?.map((v) => (
												<Label
													key={v}
													hint={v}
													{...commonHintProps(CALL_CENTER_HASHTAG_TITLES.FIRST_CONTACT_RESULTS)}
												/>
											))}
										</Grid.Section>
									</Grid.Section>
								</Grid.Row>
							</>
						),
						label: 'КЦ',
					},
					{
						content: (
							<Grid.Row className={styles.content} $gap='xl' $sizes='1fr 2fr'>
								<Grid.Section style={{ height: 'fit-content' }}>
									<Grid.Section $gap='xs' style={{ gridTemplateRows: 'min-content' }}>
										<Label>{LEADGEN_HASHTAG_TITLES.CURRENT_WORK}</Label>
										{LEADGEN_HASHTAGS.get(LEADGEN_HASHTAG_TITLES.CURRENT_WORK)?.map((v) => (
											<Label
												key={v}
												hint={v}
												{...commonHintProps(LEADGEN_HASHTAG_TITLES.CURRENT_WORK)}
											/>
										))}
									</Grid.Section>
									<Grid.Section $gap='xs' style={{ gridTemplateRows: 'min-content' }}>
										<Label>{LEADGEN_HASHTAG_TITLES.ADDITIONAL_ON_THE_OBJECT}</Label>
										{LEADGEN_HASHTAGS.get(LEADGEN_HASHTAG_TITLES.ADDITIONAL_ON_THE_OBJECT)?.map(
											(v) => (
												<Label
													key={v}
													hint={v}
													{...commonHintProps(LEADGEN_HASHTAG_TITLES.ADDITIONAL_ON_THE_OBJECT)}
												/>
											)
										)}
									</Grid.Section>
									<Grid.Section $gap='xs' style={{ gridTemplateRows: 'min-content' }}>
										<Label>{LEADGEN_HASHTAG_TITLES.INFORMATION_ON_THE_BORROWER}</Label>
										{LEADGEN_HASHTAGS.get(LEADGEN_HASHTAG_TITLES.INFORMATION_ON_THE_BORROWER)?.map(
											(v) => (
												<Label
													key={v}
													hint={v}
													{...commonHintProps(LEADGEN_HASHTAG_TITLES.INFORMATION_ON_THE_BORROWER)}
												/>
											)
										)}
									</Grid.Section>
								</Grid.Section>
								<Grid.Section style={{ height: 'fit-content' }}>
									<Grid.Section style={{ gridTemplateRows: 'min-content' }} $gap='xs'>
										<Label>{LEADGEN_HASHTAG_TITLES.OBJECT}</Label>
										{LEADGEN_HASHTAGS.get(LEADGEN_HASHTAG_TITLES.OBJECT)?.map((v) => (
											<Label key={v} hint={v} {...commonHintProps(LEADGEN_HASHTAG_TITLES.OBJECT)} />
										))}
									</Grid.Section>
									<Grid.Section $gap='xs' style={{ gridTemplateRows: 'min-content' }}>
										<Label>{LEADGEN_HASHTAG_TITLES.TRANSFERRED_TO_PARTNER}</Label>
										{LEADGEN_HASHTAGS.get(LEADGEN_HASHTAG_TITLES.TRANSFERRED_TO_PARTNER)?.map(
											(v) => (
												<Label
													key={v}
													hint={v}
													{...commonHintProps(LEADGEN_HASHTAG_TITLES.TRANSFERRED_TO_PARTNER)}
												/>
											)
										)}
									</Grid.Section>
									<Grid.Section $gap='xs' style={{ gridTemplateRows: 'min-content' }}>
										<Label>{LEADGEN_HASHTAG_TITLES.INFORMATION_ON_THE_PLEDGER}</Label>
										{LEADGEN_HASHTAGS.get(LEADGEN_HASHTAG_TITLES.INFORMATION_ON_THE_PLEDGER)?.map(
											(v) => (
												<Label
													key={v}
													hint={v}
													{...commonHintProps(LEADGEN_HASHTAG_TITLES.INFORMATION_ON_THE_PLEDGER)}
												/>
											)
										)}
									</Grid.Section>
								</Grid.Section>
							</Grid.Row>
						),
						label: 'Лидген',
					},
					{
						content: (
							<>
								<Grid.Row className={styles.content} $gap='xl'>
									<Grid.Section style={{ height: 'fit-content' }}>
										<Grid.Section style={{ gridTemplateRows: 'min-content' }} $gap='xs'>
											<Label>{CKB_HASHTAG_TITLES.CREDITORS}</Label>
											{CKB_HASHTAGS.get(CKB_HASHTAG_TITLES.CREDITORS)?.map((v) => (
												<Label
													key={v}
													hint={v}
													{...commonHintProps(CKB_HASHTAG_TITLES.CREDITORS)}
												/>
											))}
										</Grid.Section>
									</Grid.Section>
									<Grid.Section style={{ height: 'fit-content' }}>
										<Grid.Section style={{ gridTemplateRows: 'min-content' }} $gap='xs'>
											<Label>{CKB_HASHTAG_TITLES.CLIENT_INFO}</Label>
											{CKB_HASHTAGS.get(CKB_HASHTAG_TITLES.CLIENT_INFO)?.map((v) => (
												<Label
													key={v}
													hint={v}
													{...commonHintProps(CKB_HASHTAG_TITLES.CLIENT_INFO)}
												/>
											))}
										</Grid.Section>
									</Grid.Section>
								</Grid.Row>
							</>
						),
						label: 'ЦКБ',
					},
				]}
			/>
			<Grid.Section $gap='xl'>
				<div className={styles.border} />
				<Grid.Row $sizes='auto auto 1fr'>
					<Button size='min' onClick={handleAddHints} type='button'>
						Добавить
					</Button>
					<Button size='min' variant='gray' onClick={onClose}>
						Отмена
					</Button>
				</Grid.Row>
			</Grid.Section>
		</ModalDialog>
	)
}
