import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import cx from 'clsx'
import { useModalParams } from 'components/hooks'
import { ROLES_VALUES } from 'const'
import HISTORY from 'const/history'
import ArrowDown from 'images/arrow-down.svg'
import PropTypes from 'prop-types'

import { join } from '@creditclubteam/kit/helpers'
import { Mentions } from '@creditclubteam/kit/legacy-ui-components'
import { CommentHints } from 'components/common/CommentHints'

import WorkerPicker from './WorkersPicker'

import styles from './AddComment.module.scss'

const initialState = {
	opened: false,
	positions: {},
	style: {},
	filter: {
		terminated: false,
	},
	handlers: {
		onChange: null,
		onClose: null,
		onFocus: null,
	},
}

const AddComment = ({ onAddComment, workers }) => {
	const [text, setText] = useState('')
	const [originalValue, setOriginalValue] = useState('')
	const participants = useSelector((state) =>
		[...state.person.list.data, ...state.entrepreneur.list.data, ...state.organization.list.data]
			.filter(({ roles }) => roles.includes(ROLES_VALUES.INVESTOR))
			.map((participant) => ({
				id: participant.id,
				name: participant.name,
				surname: participant.surname,
				type: participant.$$type.toLowerCase(),
			}))
	)
	const commentHintsModal = useModalParams()
	const [state, setState] = useState({
		opened: false,
		positions: {},
		style: {},
		handlers: {
			onChange: null,
			onClose: null,
			onFocus: null,
		},
	})

	const reset = useCallback(() => setState(initialState), [])

	const handleSubmit = () => {
		const _text = originalValue.trim()

		if (_text.length) {
			onAddComment(_text)
		}

		setText('')
		setOriginalValue('')
	}

	const handleOnTriggered = useCallback((positions) => {
		const regex = new RegExp('@')

		const noStyles = window.innerWidth < 500

		const style = {
			noStyles,
			top: `71%`,
		}

		setState((state) => ({
			...state,
			opened: true,
			style,
			positions,
			handlers: {
				...state.handlers,
				onChange: ({ surname, name }) => {
					setText((text) => `${text}${join([name, surname], ' ')} `.replace(regex, ''))
				},
			},
		}))
	}, [])

	const handleOnInput = useCallback((value) => {
		setOriginalValue(value)
	}, [])

	const handleOnChange = useCallback((text) => {
		setText(text)
	}, [])

	const textareaProps = {
		value: text,
		autoFocus: true,
		originalValue: originalValue,
		placeholder: 'Новый комментарий',
		minHeight: 50,
		maxHeight: 200,
		autoSize: true,

		/**
		 * DON'T REMOVE
		 * Used while history block expand
		 */
		onInput: handleOnInput,
		mentionsData: [...workers, ...participants],
		onChange: handleOnChange,
		onPressEnter: handleSubmit,
		onTriggered: handleOnTriggered,
	}

	const workerPickerProps = {
		...state,
		reset,
	}

	return (
		<div
			data-test-id='history-add-comment'
			className={cx(styles.wrapper, HISTORY.SELECTORS.HISTORY_ADD_COMMENT_WRAPPER)}
		>
			<CommentHints
				text={text}
				onAddHints={setText}
				isOpen={commentHintsModal.isOpen}
				onClose={commentHintsModal.onClose}
			/>
			<img className={styles.arrow} onClick={commentHintsModal.onOpen} src={ArrowDown} />
			<WorkerPicker {...workerPickerProps} />
			<Mentions {...textareaProps} />
		</div>
	)
}

AddComment.propTypes = {
	onAddComment: PropTypes.func.isRequired,
}

export default AddComment
