import PropTypes from 'prop-types'

import styles from './Headline.module.scss'

const Headline = ({ title, children }) => (
	<div className={styles.wrap}>
		<div id='entityHeadlineTitle' className={styles.title}>
			{title || '—'}
		</div>
		{children && <div className={styles.additional}>{children}</div>}
	</div>
)

Headline.propTypes = {
	children: PropTypes.any,
	title: PropTypes.any,
}

export default Headline
