import { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import {
	deleteFile,
	getContextMenuOptions,
	getSimilarFilesInCategory,
	onDragEnd,
	onRefreshBucketElement,
	rangeSelectionFiles,
	toggleSelectionFiles,
} from 'actions/filesInspector'
import { showFilesPreview } from 'actions/filesViewer'
import cx from 'clsx'
import PropTypes from 'prop-types'
import { ContextMenu, ContextMenuItem, ContextMenuTrigger, Submenu } from 'rctx-contextmenu'

import { utils } from 'helpers'

import Preview from './Preview'

import './FileItem.scss'

class FileItem extends Component {
	static propTypes = {
		onlyBucket: PropTypes.bool,
		id: PropTypes.string.isRequired,
		objectKey: PropTypes.string.isRequired,
		index: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		dragging: PropTypes.any.isRequired,
		processing: PropTypes.bool.isRequired,
		selectedFiles: PropTypes.array.isRequired,

		deleteFile: PropTypes.func.isRequired,
		showFilesPreview: PropTypes.func.isRequired,
		rangeSelectionFiles: PropTypes.func.isRequired,
		toggleSelectionFiles: PropTypes.func.isRequired,
		onRefreshBucketElement: PropTypes.func.isRequired,
		getSimilarFilesInCategory: PropTypes.func.isRequired,
	}

	async componentDidMount() {
		const { getContextMenuOptions } = this.props

		await getContextMenuOptions()
	}

	handleClick = (event) => {
		const { id, rangeSelectionFiles, toggleSelectionFiles } = this.props

		event.stopPropagation()

		if (utils.ctrlClick(event)) {
			toggleSelectionFiles(id, 'bucket')
		} else if (utils.shiftClick(event)) {
			rangeSelectionFiles(id, 'bucket')
		} else {
			toggleSelectionFiles()
		}
	}

	doubleClick = async () => {
		const {
			id,
			objectKey,
			title,
			showFilesPreview,
			onRefreshBucketElement,
			getSimilarFilesInCategory,
		} = this.props

		const file = { id, objectKey, title }
		const similar = getSimilarFilesInCategory('bucket', file)

		if (utils.hasObjectLength(similar)) {
			const files = similar.map(({ id, objectKey, title }) => ({
					id,
					objectKey,
					title,
				}))

			showFilesPreview(files, file.id, onRefreshBucketElement, id, 'bucket')
			return
		}

		showFilesPreview(file, file.id, onRefreshBucketElement, id, 'bucket')
	}

	moveFile = (entityId, category) => {
		const { id, index, onDragEnd, currentParticipant } = this.props

		onDragEnd(
			{
				source: { index, droppableId: 'bucket' },
				destination: {
					droppableId: category.id,
					index: 0,
				},
				draggableId: id,
			},
			entityId !== currentParticipant && entityId,
			entityId !== currentParticipant
		)
	}

	renderDeleteBlock() {
		const { id, deleteFile } = this.props

		return (
			<div
				onClick={() => deleteFile(id, 'bucket')}
				className='files-inspector-files-bucket__item-delete'
				data-test-id='bucket-delete-btn'
			>
				<i className='zmdi zmdi-delete' />
			</div>
		)
	}

	renderMultipleDraggingCount(isSelected, isDragging) {
		const { selectedFiles } = this.props

		if (isSelected && isDragging && selectedFiles.length !== 1)
			return <div className='files-inspector-files-bucket__item-counts'>{selectedFiles.length}</div>
	}

	renderTitle(isSelected, isDragging, isCategoryFile) {
		const { title, processing, selectedFiles } = this.props

		if (processing) {
			return 'Файл обрабатывается...'
		} else if (!isSelected && isDragging && isCategoryFile) {
			return title
		} else if (isSelected && isDragging && selectedFiles.length !== 1) {
			return 'Несколько файлов'
		}

		return utils.cutText(title, 23)
	}

	render() {
		const {
			id,
			objectKey,
			index,
			dragging,
			onlyBucket,
			extension,
			title,
			downloadPath,
			selectedFiles,
			contextMenuOptions,
		} = this.props

		const dragableOptions = {
			index,
			draggableId: id,
			isDragDisabled: onlyBucket,
		}

		return (
			<Draggable {...dragableOptions}>
				{(provided, snapshot) => {
					const isCategoryFile = Boolean(
						snapshot.draggingOver && snapshot.draggingOver !== 'bucket'
					)
					const classCategoryOrItem = isCategoryFile ? 'category' : 'bucket'
					const isSelected = Boolean(selectedFiles.find((file) => file.id === id))

					const itemBucketBlockOptions = {
						id,
						ref: provided.innerRef,
						onClick: this.handleClick,
						...provided.draggableProps,
						...provided.dragHandleProps,
						className: cx(`files-inspector-files-${classCategoryOrItem}__item`, {
							isSelected,
							isCategoryFile,
							isDragging: snapshot.isDragging,
							involved: isSelected && dragging && !snapshot.isDragging,
						}),
					}

					const previewOptions = {
						objectKey,
						isSelected,
						extension,
						title,
						downloadPath,
						selectedFiles,
						isDragging: snapshot.isDragging,
						onDoubleClick: this.doubleClick,
					}

					return (
						<div {...itemBucketBlockOptions}>
							<ContextMenuTrigger id={'category-context' + id}>
								{this.renderDeleteBlock()}
								{!isCategoryFile && <Preview {...previewOptions} />}
								{this.renderMultipleDraggingCount(isSelected, snapshot.isDragging)}
								<span>{this.renderTitle(isSelected, snapshot.isDragging, isCategoryFile)}</span>
							</ContextMenuTrigger>

							{contextMenuOptions.length > 0 && (
								<ContextMenu
									id={'category-context' + id}
									appendTo='body'
									animation='zoom'
									hideOnLeave={false}
								>
									{contextMenuOptions.map((option) => (
										<Submenu key={option.label} title={option.label}>
											{option.categories.map((category) => (
												<ContextMenuItem
													attributes={{
														'data-is-context-menu-item': true,
													}}
													key={category.id}
													onClick={() => this.moveFile(option.id, category)}
												>
													{category.title}
												</ContextMenuItem>
											))}
										</Submenu>
									))}
								</ContextMenu>
							)}
						</div>
					)
				}}
			</Draggable>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.filesInspector.main,
})

const mapDispatchToProps = {
	onDragEnd,
	deleteFile,
	showFilesPreview,
	rangeSelectionFiles,
	toggleSelectionFiles,
	onRefreshBucketElement,
	getSimilarFilesInCategory,
	getContextMenuOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileItem)
