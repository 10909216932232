import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'

import { Text } from '@creditclubteam/kit/ui-components'

import * as Styled from './styled'

interface Tab {
	label: string | number
	content: ReactNode
	disabled?: boolean
}

export interface TabsProps {
	items: Tab[]
}

export const Tabs = ({ items }: TabsProps) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(0)
	const ref = useRef<HTMLDivElement>(null)
	const activeRef = useRef<HTMLButtonElement>(null)
	const [bounding, setBounding] = useState<{ x: number; width: number } | null>(null)

	useEffect(() => {
		const parent = ref.current!.getBoundingClientRect()
		const rect = activeRef.current!?.getBoundingClientRect()

		if (rect)
			setBounding({
				width: rect.width,
				x: rect.left - parent.left,
			})
	}, [])

	const handleChange = (index: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
		const parent = ref.current!.getBoundingClientRect()
		const rect = (event.target as Record<string, any>).getBoundingClientRect()

		setBounding({
			width: rect.width,
			x: rect.left - parent.left,
		})
		setSelectedIndex(index)
	}

	return (
		<Styled.Wrap>
			<Styled.Tabs ref={ref}>
				{items.map(({ label, disabled }, i) => (
					<Styled.TabItem
						key={i}
						ref={selectedIndex === i ? activeRef : undefined}
						fullWidth={false}
						variant='transparent'
						disabled={disabled}
						styleOverride={{ height: 40 }}
						selected={selectedIndex === i}
						onClick={handleChange(i)}
						type='button'
					>
						<Text ellipsis as='span' weight='bold' font='large'>
							{label}
						</Text>
					</Styled.TabItem>
				))}
				{bounding && <Styled.Border xPos={bounding.x} width={bounding.width} />}
			</Styled.Tabs>
			<Styled.Content key={selectedIndex}>{items[selectedIndex].content}</Styled.Content>
		</Styled.Wrap>
	)
}
