import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import type { Options } from 'api/types/Options'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { REQUEST_VERSION } from 'const/api'
import type { Lead } from 'converters/lead'
import { lead } from 'converters/lead'
import moment from 'moment'
import { join, mergeDeepRight } from 'ramda'
import type { LeadFilter } from 'reducers/lead/list'
import { comparison as bind, eq, ge, le, or } from 'rsql-builder'

import { utils } from 'helpers'
import { defaultToStr } from 'helpers/convertHelper'

export default {
	get: (id: EntityId) =>
		axios.get<Lead.Main>(`/v2/leads/${id}`).then((response) =>
			Object.assign({}, response, {
				data: lead.main(response.data),
			})
		),

	search: async ({ filter, size, page, sort, direction, property }: Options<LeadFilter>) => {
		const params = new URLBuilder()

		if (filter) {
			const re = (value: string) =>
				`=re="(?i)(?=.*${value.trim().replace(/\s+/g, ')(?=.*').replace(/"/g, '\\"')}).+"`

			params
				.addFilter('status', filter.statuses, 'inList')
				.addFilter('marketingData.channel', filter.channel, 'inList')
				.addFilter('workers', filter.worker, 'inList')
				.addFilter(
					'createdAt',
					filter.minCreatedDate &&
						moment.isDate(filter.minCreatedDate) &&
						filter.minCreatedDate.toISOString(),
					ge
				)
				.addFilter(
					'createdAt',
					filter.maxCreatedDate &&
						moment.isDate(filter.maxCreatedDate) &&
						filter.maxCreatedDate.toISOString(),
					le
				)
				.addFilter('facilities.address', filter.facility ? filter.facility[0] : filter.query, re)
				.addFilter(
					'participants',
					filter.participant?.map((id: string) => or(bind('id', eq(id)))),
					(value: string) => `=q="${value}"`
				)
		}

		return axios
			.get('/v2/leads', {
				params: {
					size,
					sort: sort || join(',', [defaultToStr(property), defaultToStr(direction)]) || undefined,
					page,
					filter: params.build('RSQL'),
				},
				$$requestName: 'lead.search',
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(lead.main),
						},
					}) as AxiosResponse<Pageable<Lead.Main>>
			)
	},

	managers: async (options: Options<{ id: string[] }>) => {
		const params = new URLBuilder()

		if (options.filter) {
			const { id } = options.filter

			params.addFilter('managerId', id, 'inList')
		}

		return axios
			.get('/v1/lead-managers', {
				params: {
					size: 100,
					sort:
						utils.join([defaultToStr(options.property), defaultToStr(options.direction)], ',') ||
						undefined,
					page: 0,
					filter: params.build('RSQL'),
				},
				$$requestName: 'lead.managers',
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(lead.manager),
						},
					}) as AxiosResponse<Pageable<Lead.Manager>>
			)
	},

	create: (data: Record<string, any>) =>
		axios
			.post<Lead.Main>('/v2/leads', data)
			.then((response) => Object.assign({}, response, { data: lead.main(response.data) })),

	getPipeline: () => axios.get('/v4/lending/leads/statuses', { $$requestName: 'lead.getPipeline' }),

	resolveNextStatus: (
		id: EntityId,
		transitionId: EntityId,
		comment: string,
		rejectionReasonId: string
	) =>
		axios
			.post<Lead.Status>(`/v2/leads/${id}/status`, {
				comment,
				transitionId,
				rejectionReasonId,
			})
			.then((response) =>
				Object.assign({}, response, {
					data: lead.status(response.data),
				})
			),

	update: (id: EntityId, data: unknown) =>
		axios.patch(`v2/leads/${id}`, data, {
			headers: { 'Content-Type': 'application/merge-patch+json' },
		}),

	sendEsiaAuthLink: ({
		id,
		participantId,
		payload,
	}: {
		id: string
		participantId: string
		payload: { linkType: string }
	}) =>
		axios.post(`/v4/lending/leads/${id}/participants/${participantId}/esia-auth-links`, payload),

	requestDocs: (leadId: EntityId, docsType: unknown) =>
		axios({
			method: 'post',
			url: `/v2/leads/${leadId}/document-request?type=${docsType}`,
			headers: {
				'Content-type': 'application/json',
				[REQUEST_VERSION]: '1.1',
			},
		}),

	sendToMosInvest: (id: string) => axios.post(`/v2/leads/${id}/mosinvest`),
}
