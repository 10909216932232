
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addDocuments, onDragEnd, updateMetadata } from 'actions/document'
import { useFormik } from 'formik'
import moment from 'moment'
import PropTypes from 'prop-types'
import * as yup from 'yup'

import { Button, Input } from '@creditclubteam/kit/legacy-ui-components'
import { DatePicker } from '@creditclubteam/kit/legacy-ui-components'
import { Input as KitInput } from '@creditclubteam/kit/ui-components'
import { Checkbox } from 'components/common'
import { inputFormatting } from 'helpers/inputFormatting'

import { Autocomplete } from './Autocomplete'
import { ConfidantSelect } from './ConfidantSelect'

import styles from './UpdateDocumentMetadata.module.scss'

const Components = {
	DATE: DatePicker,
	STRING: Input,
	NUMBER: KitInput,
	ORGANIZATION: Autocomplete,
	PERSON: Autocomplete,
	BOOLEAN: Checkbox,
	CONFIDANT_ID: ConfidantSelect,
	ANOTHER_PERSON: Autocomplete,
}

const getInitialMetadata = (metadataFields, metadata) => {
	return metadataFields.reduce(
		(acc, { fieldType, id }) => {
			if (fieldType !== 'BOOLEAN' && !acc[id]) acc[id] = null
			if (fieldType === 'BOOLEAN' && typeof acc[id] !== 'boolean') acc[id] = false

			return acc
		},
		{ ...metadata }
	)
}

const getInitialValues = (mode, metadataFields, metadata, filesToAttachMetadata = []) => ({
	files: filesToAttachMetadata.map(() => getInitialMetadata(metadataFields, {})),
	document:
		mode === 'edit'
			? getInitialMetadata(metadataFields, metadata)
			: getInitialMetadata(metadataFields, {}),
})

const getValidatonSchema = (metadataFields, filesToAttachMetadata) => {
	const metadataSchema = yup.object(
		metadataFields.reduce((result, item) => {
			if (item.fieldType === 'BOOLEAN') {
				return result
			}

			if (item.required) {
				result[item.id] = yup.string().required()
			} else {
				result[item.id] = yup.string().nullable()
			}

			return result
		}, {})
	)

	if (!filesToAttachMetadata.length) {
		return yup.object({
			document: metadataSchema,
		})
	}

	return yup.object({
		files: yup.array(metadataSchema),
	})
}

const UpdateDocumentMetadata = ({
	metadata,
	categoryId,
	draggableData,
	filesToAttachMetadata = [],
	metadataFields,
	onSuccess,
	mode,
	entityId,
	entityType,
	participantsParent = [],
}) => {
	const dispatch = useDispatch()

	const validationSchema = useMemo(
		() => getValidatonSchema(metadataFields, filesToAttachMetadata),
		[metadataFields, filesToAttachMetadata]
	)

	const initialValues = useMemo(
		() => getInitialValues(mode, metadataFields, metadata.metadata, filesToAttachMetadata),
		[mode, metadataFields, metadata.metadata, filesToAttachMetadata]
	)

	const { setFieldValue, values, isSubmitting, isValid, handleSubmit } = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: true,
		validateOnMount: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			try {
				if (mode === 'edit') {
					await dispatch(updateMetadata(metadata.id, metadata.title, values.document))
				}

				if (mode === 'upload') {
					if (filesToAttachMetadata.length) {
						await dispatch(
							addDocuments({
								ownerId: entityId,
								files: filesToAttachMetadata,
								categoryId,
								metadata: values.files,
								ownerType: entityType,
							})
						)
					}
				}

				if (mode === 'move') {
					const { titleWithoutExtension } = await dispatch(onDragEnd(draggableData))
					await dispatch(updateMetadata(metadata.id, titleWithoutExtension, values.document))
				}

				onSuccess()
			} catch (error) {
				// eslint-disable-next-line no-console
				console.dir(error);
				setSubmitting(false)
			}
		},
		validationSchema,
	})

	const [index, setIndex] = useState(0)

	const submitProps = {
		mode: 'min',
		title: 'Сохранить',
		buttonType: 'submit',
		disabled: !isValid,
		loading: isSubmitting,
	}

	const prevBtnProps = {
		mode: 'min',
		title: 'Назад',
		disabled: index === 0,
		onClick: () => setIndex((v) => v - 1),
	}

	const nextBtnProps = {
		mode: 'min',
		title: 'Далее',
		disabled: filesToAttachMetadata.length - 1 === index,
		onClick: () => setIndex((v) => v + 1),
	}

	// Please keep for a while / 24.11.22
	// console.log('participantsParent', participantsParent)
	// console.log('filesToAttachMetadata', filesToAttachMetadata)
	// console.log('metadataFields', metadataFields)
	// console.log('initialValues', initialValues)
	// console.log('errors', errors)
	// console.log('values', values)

	return (
		<form autoComplete='off' className={styles.wrap} onSubmit={handleSubmit}>
			<div className={styles.fields}>
				Имя документа: {metadata.title ?? filesToAttachMetadata[index]?.name}
				{metadataFields.map(({ fieldType, id, title }) => {
					const Component = Components[fieldType]

					if (!Component) {
						return null
					}

					const onChange = (value) => {
						if (fieldType === 'DATE' && value) {
							value = moment(value).format('YYYY-MM-DD')
						}

						if (!value && fieldType !== 'BOOLEAN') {
							value = null
						}

						setFieldValue(
							filesToAttachMetadata.length ? `files.${index}.${id}` : `document.${id}`,
							value
						)
					}

					const value =
						(filesToAttachMetadata.length ? values.files[index]?.[id] : values.document[id]) ?? ''

					const props = Object.assign(
						{
							key: title,
							mode: 'min',
							disabled: isSubmitting,
							onChange,
							onReset: () => onChange(''),
							value,
							clearable: true,
						},
						fieldType === 'NUMBER' && {
							value: inputFormatting.format({ value, allowDecimals: true }) ?? '',
							labelPlacing: 'out',
							scale: 'min',
							label: title,
							onReset: undefined,
							clearable: undefined,
							mode: undefined,
							onChange: ({ target: { value } }) => onChange(inputFormatting.unformat({ value })),
						},
						fieldType !== 'BOOLEAN' && {
							title,
						},
						fieldType === 'BOOLEAN' && {
							label: title,
							checked: value,
						},
						fieldType === 'ANOTHER_PERSON' && {
							type: 'PERSON',
							rejectId: entityId,
						},
						(fieldType === 'ORGANIZATION' || fieldType === 'PERSON') && {
							type: fieldType,
						},
						fieldType === 'CONFIDANT_ID' && {
							type: fieldType,
							options: participantsParent
								.filter(({ roles, type }) => roles?.includes('CONFIDANT') && type === 'PERSON')
								.map(({ id, participant }) => ({
									id,
									label: participant.fullName,
									value: id,
								})),
						},
						fieldType === 'DATE' && {
							value: !value ? null : new Date(value),
						}
					)

					return <Component {...props} />
				})}
			</div>
			{filesToAttachMetadata.length > 1 && (
				<div className={styles.controls}>
					<div className={styles.controls}>
						<Button {...prevBtnProps} />
						<Button {...nextBtnProps} />
						{`${index + 1} / ${filesToAttachMetadata.length}`}
					</div>
					<Button {...submitProps} />
				</div>
			)}
			{(!filesToAttachMetadata.length || filesToAttachMetadata.length === 1) && (
				<Button {...submitProps} />
			)}
		</form>
	)
}

UpdateDocumentMetadata.propTypes = {
	metadata: PropTypes.object,
	metadataFields: PropTypes.array,

	onSuccess: PropTypes.func.isRequired,
}

export default UpdateDocumentMetadata
