import { Component } from 'react'
import { connect } from 'react-redux'
import {
	addHistoryComment,
	fetchHistory,
	historySetVisibleStatus,
	setHistoryEntity,
	setHistoryFilters,
} from 'actions/history'
import cx from 'clsx'
import HISTORY from 'const/history'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import { workerSelector } from 'reducers/worker'

import { Loading } from '@creditclubteam/kit/legacy-ui-components'
import { historyUtils, utils } from 'helpers'

import AddComment from './AddComment'
import Category from './Category'
import Controls from './Controls'

// import { openWorkerPicker } from 'actions/worker'
import './History.scss'

// !! resetHistory юзаем в главном компоненте где вызвали историю

/*
 * @param {array} allowedTypes - Типы событий, если не передано будут все
 * @param {array} entitiesList - Список сущностей по которым загружать историю
 * это массив строк и обьектов, может быть таким ['string', { id: 'string' $$type: 'LEAD' }]
 *
 * @param {bool} isCommented - Если указана появляется поле комментарий
 * @param {string} HOCEntity.id - iD главной сущности
 * @param {string} HOCEntity.type - тип сущности
 * @param {string=} HOCEntity.status - статус сущности
 * @param {number & string=} HOCEntity.number - номер сущности
 */
class History extends Component {
	timeoutToOffScrolled = null

	// Список селекторов для класса который делает максимальную высоту
	// selectorsToMaxHeight = [
	// 	'.react-tabs',
	// 	'.data-block',
	// 	'.application_body',
	// 	'.react-tabs__tab-panel--selected',
	// ]

	static propTypes = {
		// From HOC
		isCommented: PropTypes.bool,
		allowedTypes: PropTypes.array,
		entitiesList: PropTypes.array,
		HOCEntity: PropTypes.shape({
			id: PropTypes.string,
			type: PropTypes.string,
			status: PropTypes.string,
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),

		data: PropTypes.array,
		dates: PropTypes.array,
		fetching: PropTypes.bool,
		allEntityId: PropTypes.array,
		pageParams: PropTypes.object,
		entity: PropTypes.shape({
			// from HOCEntity in store
			id: PropTypes.string,
			type: PropTypes.string,
			status: PropTypes.string,
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		workers: PropTypes.array,

		fetchHistory: PropTypes.func,
		openWorkerPicker: PropTypes.func,
		setHistoryFilters: PropTypes.func,
		addHistoryComment: PropTypes.func,
		setHistoryEntity: PropTypes.func,
		historySetVisibleStatus: PropTypes.func,
	}

	state = {
		expanded: false,
		displayDate: null,
		isHistoryScrolled: false,
	}

	async componentDidMount() {
		const {
			data,
			entity,
			HOCEntity,
			allEntityId,
			fetchHistory,
			entitiesList,
			setHistoryFilters,
			setHistoryEntity,
			historySetVisibleStatus,
		} = this.props

		historySetVisibleStatus(true)

		// ДОп. Устанавливаем нужным родителям класс maxHeight
		// он устанавливает максимальную высоту окна для body
		// это необходимо для растягивания контента на всю высоту
		// this.selectorsToMaxHeight.forEach((selector) => {
		// 	const nodeEl = document.querySelector(selector)

		// 	if (nodeEl) {
		// 		nodeEl.classList.add('maxHeight')
		// 	}
		// })

		// Сработает если комонент не был ранее инициализирован (без данных или они были очищены)
		if (!utils.hasObjectLength(data) && !utils.hasObjectLength(allEntityId)) {
			setHistoryEntity(HOCEntity)
			await fetchHistory(entitiesList, true)
		} else if (
			/**
			 * Если происходили изменения в количесве участников при загруженном компонент
			 * или произошло изменения статуса то сбрасываем выбранных если они есть
			 */
			(utils.hasObjectLength(entitiesList) && !this.entityIdsEquals(entitiesList, allEntityId)) ||
			entity.status !== HOCEntity.status
		) {
			setHistoryEntity(HOCEntity)
			setHistoryFilters({ field: 'entityId', value: [] })
			await fetchHistory(entitiesList, true)
		}

		if (this.nodeBodyElement) {
			historyUtils.returnScrollInStart()
			this.nodeBodyElement.addEventListener('scroll', this.scrollHistoryBody)
		}
	}

	componentWillUnmount() {
		const { historySetVisibleStatus } = this.props

		historySetVisibleStatus(false)

		// this.selectorsToMaxHeight.forEach((selector) => {
		// 	const nodeEl = document.querySelector(selector)

		// 	if (nodeEl) nodeEl.classList.remove('maxHeight')
		// })

		if (this.nodeBodyElement) {
			this.nodeBodyElement.removeEventListener('scroll', this.scrollHistoryBody)
		}
	}

	componentDidUpdate(prevProps) {
		const {
			HOCEntity,
			allEntityId,
			fetchHistory,
			entitiesList,
			setHistoryEntity,
			setHistoryFilters,
		} = this.props

		if (
			!this.entityIdsEquals(entitiesList, allEntityId) ||
			prevProps.HOCEntity.status !== HOCEntity.status
		) {
			setHistoryEntity(HOCEntity)
			setHistoryFilters({ field: 'entityId', value: [] })
			fetchHistory(entitiesList, true)
		}
	}

	// Когда компонент инициализируется он создаёт свою копию всех
	// элементов массива entitiesList, иногда надо проверить и совпает ли он с копией
	entityIdsEquals = (initArr, localArr) => {
		if (initArr.length !== localArr.length) {
			return false
		}

		for (let i = 0; i <= initArr.length; i++) {
			if (!equals(initArr[i], localArr[i])) {
				return false
			}
		}

		return true
	}

	scrollHistoryBody = () => {
		const activeDates = []
		const { nodeBodyElement } = this
		const { selectedEntity } = this.state
		const { pageParams, fetchHistory } = this.props

		const scrollTop = nodeBodyElement.scrollTop
		const prevScrollHeight = nodeBodyElement.scrollHeight

		const dates = nodeBodyElement.querySelectorAll('#' + HISTORY.SELECTORS.HISTORY_DATE)
		Array.prototype.forEach.call(dates, (el) => el.offsetTop <= scrollTop && activeDates.push(el))

		if (utils.hasObjectLength(activeDates)) {
			this.setState({
				displayDate: activeDates.pop().innerHTML,
			})
		} else {
			this.setState({ displayDate: null })
		}

		if (
			scrollTop === 0 &&
			pageParams.page + 1 <= pageParams.totalPages &&
			utils.hasObjectLength(dates)
		) {
			fetchHistory(selectedEntity).then(() => {
				nodeBodyElement.scrollTop = nodeBodyElement.scrollHeight - prevScrollHeight
			})
		}

		this.scrolledStatus()
	}

	scrolledStatus = () => {
		const { isHistoryScrolled } = this.state

		if (!isHistoryScrolled) {
			this.setState({ isHistoryScrolled: true })
		}

		clearTimeout(this.timeoutToOffScrolled)
		this.timeoutToOffScrolled = setTimeout(() => {
			this.setState({
				isHistoryScrolled: false,
			})
		}, 250)
	}

	toggleExpand = () => {
		const { expanded } = this.state

		this.setState({ expanded: !expanded }, () => {
			const addCommentWrapper = document.querySelector(
				'.' + HISTORY.SELECTORS.HISTORY_ADD_COMMENT_WRAPPER
			)

			if (addCommentWrapper) {
				addCommentWrapper.querySelector('textarea').focus()
			}
		})
	}

	handleAddComment = (comment) => {
		const { addHistoryComment } = this.props

		addHistoryComment(comment)
	}

	render() {
		const { expanded, displayDate, isHistoryScrolled } = this.state

		const {
			data,
			dates,
			workers,
			fetching,
			isCommented,
			allowedTypes,
			openWorkerPicker,
		} = this.props

		const bodyOptions = {
			ref: (el) => (this.nodeBodyElement = el),
			className: cx(HISTORY.SELECTORS.HISTORY_BODY, {
				notComment: !isCommented,
			}),
		}

		const controlsOptions = {
			expanded,
			displayDate,
			allowedTypes,
			onExpand: this.toggleExpand,
		}

		return (
			<div data-test-id='history-block' className='history-wrapper'>
				<div className={cx('history', 'history-react-tabs', { expanded })}>
					<Controls {...controlsOptions} />
					<div {...bodyOptions}>
						<div className='history-loading'>
							{fetching && <Loading min />}
						</div>
						{utils.hasObjectLength(dates) &&
							utils
								.sortDate(dates, 'fullDate', true)
								.map((item, index) => (
									<Category key={index} {...item} isHistoryScrolled={isHistoryScrolled} />
								))}
						{!utils.hasObjectLength(data) && !fetching && (
							<div className='history-empty'>Ничего не найдено</div>
						)}
					</div>
					{isCommented && (
						<AddComment
							workers={workers}
							openWorkerPicker={openWorkerPicker}
							onAddComment={this.handleAddComment}
						/>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.history,
	workers: workerSelector.selectAll(state),
})

const mapDispatchToProps = {
	fetchHistory,
	setHistoryEntity,
	setHistoryFilters,
	addHistoryComment,
	historySetVisibleStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(History)
