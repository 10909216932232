import axios from 'axios'
import type { BonusApplicationDto } from 'converters/bonusApplication'
import { bonusApplication as converter } from 'converters/bonusApplication'

const baseUrl = '/v4/investment'

export default {
	get: () =>
		axios.get<BonusApplicationDto.Main[]>(`${baseUrl}/bonus-applications`).then((response) => ({
			...response,
			data: response.data.map(converter.main),
		})),

	getOrder: (payload: { bonusApplications: string[] }) =>
		axios.post(`${baseUrl}/bonus-application-orders`, payload),
}
