import { HelmetProvider } from 'react-helmet-async'
import { Provider, ReactReduxContext } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import axios from 'axios'
import { browserHistory } from 'client/browserHistory'
import { ErrorBoundary } from 'client/ErrorBoundary'
import { KeycloakProvider } from 'client/keycloak'
import { keycloak } from 'client/keycloak/instance'
// import { UpdateNotification } from 'client/UpdateNotification'
import { ConnectedRouter } from 'connected-react-router'
import { DataProvider } from 'features/data'
import { routes } from 'routes/routes'
import { preparedTheme } from 'scss/theme'
import { ThemeProvider } from 'styled-components'
import type { Maybe } from 'types/util'

import { ConfigureAxios } from '@creditclubteam/kit/legacy-helpers'

import { store } from './store'

const configureAxios = new ConfigureAxios()

configureAxios.init({ axios, keycloakInstance: keycloak })

export let lastFailedRequest: Maybe<{
	url: Maybe<string>
	status: Maybe<number>
	method: Maybe<string>
	params: Maybe<Record<string, any>>
	data: any
}> = null

axios.interceptors.response.use(undefined, (error) => {
	lastFailedRequest = {
		url: error?.response?.config?.url ?? null,
		method: error?.response?.config?.method?.toUpperCase() ?? null,
		params: error?.response?.config?.params ?? null,
		data: error?.response?.config?.data ?? null,
		status: error?.response?.status ?? null,
	}

	return Promise.reject(error)
})

export const Root = () => {
	return (
		<ThemeProvider theme={preparedTheme}>
			<ErrorBoundary>
				<KeycloakProvider>
					<Provider store={store}>
						<HelmetProvider>
							<DataProvider>
								<ConnectedRouter history={browserHistory} context={ReactReduxContext}>
									{/* <UpdateNotification /> */}
									{renderRoutes(routes as any)}
								</ConnectedRouter>
							</DataProvider>
						</HelmetProvider>
					</Provider>
				</KeycloakProvider>
			</ErrorBoundary>
		</ThemeProvider>
	)
}
