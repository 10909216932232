import { type ErrorInfo, useState } from 'react'
import { lastFailedRequest } from 'client/Root'
import styled from 'styled-components'
import type { Maybe } from 'types/util'

import { join } from '@creditclubteam/kit/helpers'
import { Button, Grid, Text } from '@creditclubteam/kit/ui-components'

const Styled = {
	i: styled.i({
		fontSize: '90px',
		color: '#a3a3a3',
	}),
}

const CriticalError = ({
	error,
	errorInfo,
}: {
	error: Maybe<Error>
	errorInfo: Maybe<ErrorInfo>
}) => {
	const [isCopied, setIsCopied] = useState(false)

	const handleCopy = () => {
		navigator.clipboard.writeText(
			join(
				[
					'```',
					`Причина: ${error?.message ?? '—'}`,
					`Путь: ${window.location.pathname}`,
					`Время: ${new Date().toLocaleString()} (${new Date().toISOString()})`,
					`Лог: ${errorInfo?.componentStack.split('\n').slice(0, 4).join('\n') ?? '—'}`,
					lastFailedRequest &&
						`Последний запрос с ошибкой: ${JSON.stringify(lastFailedRequest, undefined, 4)}`,
					'```',
				],
				'\n'
			)
		)
		setIsCopied(true)
	}

	return (
		<Grid.Section
			$styleOverride={{
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center',
				margin: '0 auto',
				justifyContent: 'center',
				justifyItems: 'center',
			}}
		>
			<Styled.i className='zmdi zmdi-flip' />
			<Text>
				Упс... Вероятно, что то пошло не так. <br />
				Скопируйте лог ошибки и отправьте его в канал{' '}
				<a
					href='https://creditclubteam.slack.com/archives/CATBU8J5P'
					target='_blank'
					rel='noopener nofollow'
				>
					#test_cycle
				</a>
			</Text>
			<Grid.Row>
				<Button
					type='button'
					styleOverride={
						isCopied
							? ({ colors }) => ({ backgroundColor: `${colors.positive} !important` })
							: undefined
					}
					onClick={handleCopy}
				>
					{isCopied ? 'Скопировано' : 'Скопировать'}
				</Button>
				<Button variant='outline-blue' type='button' onClick={() => window.location.reload()}>
					Обновить
				</Button>
			</Grid.Row>
		</Grid.Section>
	)
}

export default CriticalError
