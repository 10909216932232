import type { ChangeEvent } from 'react'
import { PureComponent } from 'react'

import { Input } from '@creditclubteam/kit/legacy-ui-components'
import { type SelectProps, Select } from '@creditclubteam/kit/ui-components'
import { Checkbox, Select as MultiSelect, TextArea } from 'components/common'
import FormLayout from 'components/common/FormLayout'
import { defaultToStr } from 'helpers/convertHelper'
import { inputFormatting } from 'helpers/inputFormatting'

class RenderField extends PureComponent<any> {
	render() {
		const { value, setFieldValue, variable, options } = this.props
		const reader = new FileReader()

		const _value = defaultToStr(value)

		const selectDefaultProps: Omit<SelectProps, 'options'> = {
			scale: 'min',
			labelPlacing: 'out',
			optionsWrapStyleOverride: { zIndex: 11 },
			onChange: ({ value }) => setFieldValue(variable.id, value),
			value: _value,
		}

		const inputDefaultProps: React.ComponentProps<typeof Input> = {
			className: FormLayout.styles.cell,
			mode: 'min',
			onChange: (value) => setFieldValue(variable.id, value),
			value: _value,
		}

		const textAreaProps = {
			value: _value,
			resize: 'vertical',
			onChange: (value: string) => setFieldValue(variable.id, value),
			placeholder: variable.placeholder,
		}

		switch (variable.type) {
			case 'formats':
				return (
					<FormLayout.Field title={variable.label}>
						<MultiSelect
							multi
							className={FormLayout.styles.cell}
							value={_value}
							onChange={(value: Record<string, any>[]) => setFieldValue('formats', value)}
							options={options.formats}
						/>
					</FormLayout.Field>
				)

			case 'warrantor':
				return (
					<FormLayout.Field title={variable.label}>
						<Select {...selectDefaultProps} options={options.warrantors} />
					</FormLayout.Field>
				)

			case 'owner':
				return (
					<FormLayout.Field title={variable.label}>
						<Select {...selectDefaultProps} options={options.owners} />
					</FormLayout.Field>
				)

			case 'person':
				return (
					<FormLayout.Field title={variable.label}>
						<Select {...selectDefaultProps} options={options.persons} />
					</FormLayout.Field>
				)

			case 'string':
				return (
					<FormLayout.Field title={variable.label}>
						{variable.items?.length ? (
							<Select
								{...selectDefaultProps}
								options={variable.items.map(({ key, value }: { key: string; value: any }) => ({
									label: key,
									value,
									id: value,
								}))}
							/>
						) : (
							<Input {...inputDefaultProps} placeholder={variable.placeholder} />
						)}
					</FormLayout.Field>
				)

			case 'string-multiline':
				return (
					<FormLayout.Field title={variable.label}>
						<TextArea {...textAreaProps} />
					</FormLayout.Field>
				)

			case 'facility':
				return (
					<FormLayout.Field title={variable.label}>
						<Select {...selectDefaultProps} options={options.facilities} />
					</FormLayout.Field>
				)

			case 'date':
				return (
					<FormLayout.Field title={variable.label}>
						<Input
							{...inputDefaultProps}
							placeholder={variable.placeholder}
							mask='__.__.____'
						/>
					</FormLayout.Field>
				)

			case 'phone':
				return (
					<FormLayout.Field title={variable.label}>
						<Input
							{...inputDefaultProps}
							placeholder={variable.placeholder}
							mask='+7 ___ ___-__-__'
						/>
					</FormLayout.Field>
				)

			case 'integer':
				return (
					<FormLayout.Field title={variable.label}>
						<Input {...inputDefaultProps} placeholder={variable.placeholder} onlyNum />
					</FormLayout.Field>
				)

			case 'double':
				return (
					<FormLayout.Field title={variable.label}>
						<Input
							{...inputDefaultProps}
							value={inputFormatting.format({ value: _value })}
							onChange={(value) => setFieldValue(variable.id, inputFormatting.unformat({ value }))}
							placeholder={variable.placeholder}
						/>
					</FormLayout.Field>
				)

			case 'file':
				return (
					<FormLayout.Field title={variable.label}>
						<input
							className={FormLayout.styles.cell}
							type='file'
							onChange={(event: ChangeEvent<HTMLInputElement>) => {
								if (!event.target.files) return

								reader.readAsDataURL(event.target.files[0])
								reader.onload = function () {
									setFieldValue(variable.id, reader.result?.toString().split('base64,')[1])
								}
							}}
						/>
					</FormLayout.Field>
				)

			case 'boolean':
				return (
					<Checkbox
						label={variable.label}
						checked={_value}
						onChange={(value: any) => setFieldValue(variable.id, value)}
					/>
				)

			case 'object': {
				const options = variable.items.map((el: { key: string; value: string }) => ({
					id: el.key,
					label: el.value,
					value: el.key,
				}))

				return (
					<FormLayout.Field title={variable.label}>
						<Select {...selectDefaultProps} options={options} />
					</FormLayout.Field>
				)
			}

			default:
				return (
					<FormLayout.Field title={variable.label}>
						{variable.items?.length ? (
							<Select
								{...selectDefaultProps}
								options={variable.items.map(({ key, value }: { key: string; value: any }) => ({
									label: key,
									value,
									id: value,
								}))}
							/>
						) : (
							<Input {...inputDefaultProps} placeholder={variable.placeholder} />
						)}
					</FormLayout.Field>
				)
		}
	}
}

export class Main extends PureComponent<any> {
	render() {
		const { values, setFieldValue, options, documentConstructor } = this.props
		const { inputsOrder, variables } = documentConstructor

		return (
			<FormLayout.Section>
				<FormLayout.Row key='formats'>
					<RenderField
						variable={{ type: 'formats', label: 'Формат' }}
						value={values.formats}
						options={options}
						setFieldValue={setFieldValue}
					/>
				</FormLayout.Row>
				{inputsOrder.map((key: string) => (
					<FormLayout.Row key={variables[key].id}>
						<RenderField
							variable={variables[key]}
							value={values[key]}
							options={options}
							setFieldValue={setFieldValue}
						/>
					</FormLayout.Row>
				))}
			</FormLayout.Section>
		)
	}
}
