import { Component, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { keycloak } from 'client/keycloak/instance'
import cx from 'clsx'
import { SPECIAL_ACCOUNT_ID } from 'const'
import TAB_PATHS from 'const/tabPaths'
import Logo from 'images/logo3.svg'
import logoutIcon from 'images/logout.svg'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import urls from 'routes/urls'

import { DeepDropdown } from '@creditclubteam/kit/legacy-ui-components'
import { utils } from 'helpers'

import './Navigator.scss'

export const tabs = {
	leads: {
		values: TAB_PATHS.lead,
		path: urls.leads.path,
		title: 'Лиды',
	},
	applications: {
		values: TAB_PATHS.application,
		path: urls.applications.path,
		title: 'Заявки',
	},
	delivery: {
		values: TAB_PATHS.deliveries,
		path: urls.delivery.list.path,
		title: 'Доставка',
	},
	rosreestrRegistrations: {
		values: TAB_PATHS.rosreestrRegistrations,
		path: urls.rosreestrRegistration.list.path,
		title: 'Регистрация',
	},
	loans: {
		values: TAB_PATHS.loan,
		path: urls.loans.path,
		title: 'Займы',
	},
	assessment: {
		values: TAB_PATHS.assessment,
		path: urls.assessment.path,
		title: 'Оценка',
		icon: true,
	},
	calculator: {
		values: TAB_PATHS.calculator,
		path: urls.calculator.path,
		title: 'Калькулятор',
		icon: true,
	},
}

export const specialAccountTabs = {
	deposits: {
		values: TAB_PATHS.deposits,
		path: urls.deposit.list.path,
		title: 'Сбережения',
	},
	participants: {
		values: TAB_PATHS.participants,
		path: urls.participants.path,
		title: 'Участники',
	},
}

export const dropdownTabs = {
	loanSellApplications: {
		values: TAB_PATHS.loanSellApplications,
		path: urls.loanSellApplications.list.path,
		title: 'Рынок',
	},
	deposits: {
		values: TAB_PATHS.deposits,
		path: urls.deposit.list.path,
		title: 'Сбережения',
	},
	participants: {
		values: TAB_PATHS.participants,
		path: urls.participants.path,
		title: 'Участники',
	},
	facilities: {
		values: TAB_PATHS.facility,
		path: urls.facilities.path,
		title: 'Объекты',
	},
	partners: {
		values: TAB_PATHS.partners,
		path: urls.partner.list.path,
		title: 'Партнёры',
	},
	representative: {
		values: TAB_PATHS.representatives,
		path: urls.representative.list.path,
		title: 'Представители',
	},
	accounting: {
		values: TAB_PATHS.accounting,
		path: urls.accounting.invest.path,
		title: 'Бухгалтерия',
	},
}

// eslint-disable-next-line
const MobileNavigation = (props) => {
	// eslint-disable-next-line
	const { activeTab, username, handleLogout } = props

	const style = useRef('')
	const [isOpened, setOpened] = useState(false)

	const handleOpened = useCallback(() => {
		setOpened((isOpened) => !isOpened)
	}, [])

	const rootDiv = document.getElementsByTagName('body')[0]

	useEffect(() => {
		if (isOpened) {
			rootDiv.style.cssText = rootDiv.style.cssText + ' overflow: hidden;'
		} else {
			if (style.current) {
				rootDiv.style.cssText = style.current
			} else {
				style.current = rootDiv.style.cssText
			}
		}
	}, [isOpened, rootDiv, style])

	return (
		<>
			<div
				className='mobile-navigation'
				style={{ background: window.__env__.BUILD_MODE !== 'production' ? '#00a1dc' : '' }}
			>
				<div className='mobile-navigation__menu' onClick={handleOpened}>
					Меню
				</div>
				<img src={logoutIcon} onClick={handleLogout} />
			</div>
			{isOpened &&
				ReactDOM.createPortal(
					<div className='mobile-menu' onClick={handleOpened}>
						{Object.keys(tabs).map((key) => (
							<Link
								key={key}
								to={tabs[key].path}
								onClick={() => this.setTab(tabs[key])}
								className={cx('mobile-navigation__tab', {
									active: tabs[key].title === activeTab.title,
								})}
							>
								{tabs[key].title}
							</Link>
						))}
					</div>,
					rootDiv
				)}
		</>
	)
}

class Navigation extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		pipeline: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,

		logout: PropTypes.func.isRequired,
	}

	state = {
		isMobile: utils.isMobileWidth(),
		activeTab: { title: '' },
	}

	componentDidMount() {
		this.defineActiveTab()

		window.addEventListener('resize', this.handleResize)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		this.setState({
			isMobile: utils.isMobileWidth(),
		})
	}

	defineActiveTab = () => {
		const { location } = this.props

		const path = location.pathname.split('/')[1]

		let activeTab = { ...this.state.activeTab }

		const newTabs =
			keycloak.subject === SPECIAL_ACCOUNT_ID
				? specialAccountTabs
				: {
						...tabs,
						...dropdownTabs,
				  }

		Object.keys(newTabs).forEach((key) => {
			activeTab = newTabs[key].values.find((value) => value === path) ? newTabs[key] : activeTab
		})

		this.setState({ activeTab })
	}

	static getDerivedStateFromProps(props, state) {
		const path = props.location.pathname.split('/')[1]
		let activeTab = { ...state.activeTab }

		const newTabs =
			keycloak.subject === SPECIAL_ACCOUNT_ID
				? specialAccountTabs
				: {
						...tabs,
						...dropdownTabs,
				  }

		Object.keys(newTabs).forEach((key) => {
			activeTab = newTabs[key].values.find((value) => value === path) ? newTabs[key] : activeTab
		})

		if (activeTab.title !== state.title) {
			return {
				activeTab,
			}
		}

		return null
	}

	tabs = keycloak.subject === SPECIAL_ACCOUNT_ID ? specialAccountTabs : tabs

	renderTabs = () => {
		const { activeTab } = this.state

		return Object.keys(this.tabs).map((key, i) => {
			const isActive = this.tabs[key].title === activeTab.title ? ' nav-border' : ''

			if (
				this.tabs[key].icon ||
				(key === 'deposits' &&
					!path(['tokenParsed', 'resource_access', 'deposit-service', 'roles'], keycloak)?.includes(
						'read'
					))
			)
				return null

			return (
				<Link
					to={this.tabs[key].path}
					onClick={() => this.setTab(this.tabs[key])}
					key={i}
					className={cx('nav-menu-tab', {
						'nav-border': isActive,
					})}
				>
					{this.tabs[key].title}
				</Link>
			)
		})
	}

	renderIcons = () => {
		const { activeTab } = this.state

		const obj = {
			assessment: 'home',
			calendar: 'calendar',
			calculator: 'functions',
		}

		return Object.keys(this.tabs).map((key, i) => {
			const isActive = this.tabs[key].title === activeTab.title ? ' nav-border' : ''

			if (!this.tabs[key].icon) return null

			return (
				<Link to={this.tabs[key].path} onClick={() => this.setTab(this.tabs[key])} key={i}>
					<i
						className={cx(`zmdi zmdi-${obj[key]}`, {
							active: isActive,
						})}
					/>
				</Link>
			)
		})
	}

	setTab = (activeTab) => {
		this.setState({ activeTab })
	}

	handleLogout = () => {
		const { logout } = this.props

		logout()
	}

	checkIsDropdownTab = () => {
		return !!Object.keys(dropdownTabs).find(
			(key) => dropdownTabs[key].title === this.state.activeTab.title
		)
	}

	getDropdownTitle = () => {
		return dropdownTabs[
			Object.keys(dropdownTabs).find(
				(key) => dropdownTabs[key].title === this.state.activeTab.title
			)
		]?.title
	}

	render() {
		const { user } = this.props
		const { isMobile, activeTab } = this.state

		const username = user ? `${user.name} ${user.surname[0]}.` : 'Неизвестно'

		// eslint-disable-next-line
		const mobileNavProps = {
			activeTab,
			username,
			onLogout: this.handleLogout,
			onChangeTab: this.setTab,
		}

		if (isMobile) return null

		const dropdownProps = {
			className: cx('dropdown', {
				'active-dropdown': this.checkIsDropdownTab(),
				'--black': window.__env__.BUILD_MODE === 'production',
				'--blue': window.__env__.BUILD_MODE !== 'production',
			}),
			onChange: (val) => this.props.history.push(val.value),
			options: Object.keys(dropdownTabs).map((key) => ({
				label: dropdownTabs[key].title,
				value: dropdownTabs[key].path,
			})),
			placeholder: this.checkIsDropdownTab() ? this.getDropdownTitle() : 'Ещё',
		}

		return (
			<div
				id='navMenu'
				className={cx('nav-menu', {
					'--black': window.__env__.BUILD_MODE === 'production',
					'--blue': window.__env__.BUILD_MODE !== 'production',
				})}
			>
				<div className='nav-logo'>
					<span>
						<img src={Logo} alt='Credit.Club' />
					</span>
				</div>
				<div className='nav-wrap'>
					<div className='nav-menu-tabs'>
						{this.renderTabs()}
						{keycloak.subject !== SPECIAL_ACCOUNT_ID && <DeepDropdown {...dropdownProps} />}
					</div>
				</div>
				<div className='nav-menu-user'>
					{keycloak.subject !== SPECIAL_ACCOUNT_ID && (
						<div className='nav-icons'>
							{this.renderIcons()}
						</div>
					)}
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<span className='name'>{username}</span>
						<img src={logoutIcon} onClick={this.handleLogout} alt='' style={{ marginLeft: 16 }} />
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	pipeline: state.pipeline,
})

export default connect(mapStateToProps, null)(Navigation)
