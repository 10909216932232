import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addSigner } from 'actions/document'
import { searchEveryplace } from 'actions/search'
import api from 'api'
import { useFetch } from 'components/hooks'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import { forEachObjIndexed, isEmpty } from 'rambda'
import type { Nullable } from 'ts-toolbelt/out/Union/Nullable'
import type { AppDispatch } from 'types/redux'
import { type RootState } from 'types/redux'

import { date } from '@creditclubteam/helpers'
import { defaultOr } from '@creditclubteam/kit/helpers'
import type { AutocompleteHookValue } from '@creditclubteam/kit/ui-components'
import {
	type ButtonProps,
	type InputProps,
	type SelectProps,
	Button,
	Input,
	Select,
	Text,
} from '@creditclubteam/kit/ui-components'
import { Checkbox, Loading } from 'components/common'
import { Grid } from 'components/common/Grid'
import { utils } from 'helpers'

import { findDocumentsToSign } from './helpers'

const getRussianTextByType = (type?: string) => {
	if (type === PARTICIPANTS_TYPES.PERSON) {
		return 'физ. лица'
	}

	if (type === PARTICIPANTS_TYPES.ORGANIZATION) {
		return 'юр. лица'
	}

	if (type === PARTICIPANTS_TYPES.ENTREPRENEUR) {
		return 'ИП'
	}

	return ''
}

const DocumentsSelection = (props: {
	documentsToSign: Record<string, any>
	selectedParticipant: Nullable<Record<string, any>>
	selectedDocuments: Record<string, any>
	onDocumentSelect: (...args: any[]) => any
}) => {
	const { documentsToSign, selectedParticipant, onDocumentSelect, selectedDocuments } = props

	if (isEmpty(documentsToSign.aggregator) && isEmpty(documentsToSign.participant)) {
		return <Text>Документы отсутствуют или уже отправлены на подпись</Text>
	}

	return (
		<div>
			{!isEmpty(documentsToSign.aggregator) && (
				<Grid.Section gap='s'>
					{documentsToSign.aggregator.map((e: any) => (
						<Checkbox
							key={e.id}
							label={`${e.title} от ${date.format(e.createdAt)}`}
							checked={selectedDocuments[e.id]}
							onChange={(checked) => onDocumentSelect(e.id, checked)}
						/>
					))}
				</Grid.Section>
			)}
			{!isEmpty(documentsToSign.participant) && (
				<Grid.Section gap='s'>
					<Text>{`Документы ${getRussianTextByType(selectedParticipant!.customerType)}`}</Text>
					<Grid.Section gap='s'>
						{documentsToSign.participant.map((e: any) => (
							<Checkbox
								key={e.id}
								label={`${e.title} от ${date.format(e.createdAt)}`}
								checked={selectedDocuments[e.id]}
								onChange={(checked) => onDocumentSelect(e.id, checked)}
							/>
						))}
					</Grid.Section>
				</Grid.Section>
			)}
		</div>
	)
}

interface AddSignersProps {
	aggregator: Record<string, any>[]
	contextId?: string
	contextType?: string
	participants: Record<string, any>[]

	addSigner: (...args: any[]) => Promise<any>
	onClose: () => void
}

const AddSigners = (props: AddSignersProps) => {
	const { aggregator, participants, contextId, contextType, onClose } = props
	const dispatch = useDispatch<AppDispatch>()
	const [query, setQuery] = useState('')
	const searchResult = useSelector((state: RootState) => state.search.internal)
	const [signer, setSigner] = useState<Nullable<AutocompleteHookValue<Record<string, any>>>>(null)
	useState<Nullable<Record<string, any>>>(null)
	const [selectedDocuments, setSelectedDocuments] = useState({})
	const { applicationTypes, qualifiedSignatureProviders } = useSelector(
		(state: RootState) => state.dictionary.signatures
	)
	const [signerSignatureProvider, setSignerSignatureProvider] = useState<Nullable<string>>(null)
	const [signatureApplication, setSignatureApplication] = useState<Nullable<string>>(null)

	useEffect(() => {
		if (!signer?.payload) return

		api.sign.getPersonQualifiedSpec({ personId: signer.payload.customerId }).then(({ data }) => {
			setSignerSignatureProvider(data.signatureProvider)
			setSignatureApplication(data.application)
		})
	}, [signer])

	const onSubmit = useCallback(() => {
		const documentsIds: string[] = []

		forEachObjIndexed((value, key) => value && documentsIds.push(key), selectedDocuments)

		const signerInfo = {
			signatureApplication,
			context: {
				contextId,
				contextType,
			},
			signer: {
				customerId: signer!.payload!.customerId,
				customerType: signer!.payload!.customerType,
			},
			documents: documentsIds,
		}

		return dispatch(addSigner(signerInfo))
	}, [selectedDocuments, signatureApplication, contextId, contextType, signer, dispatch])

	const { request: handleSubmit, isFetching } = useFetch(onSubmit, {
		onSuccess: () => {
			onClose()
		},
	})

	const handleSelectDocument = useCallback((id, value) => {
		setSelectedDocuments((prev) => ({ ...prev, [id]: value }))
	}, [])

	const documentsToSign = useMemo(
		() =>
			findDocumentsToSign({
				aggregator,
				participants,
				contextId,
				selectedParticipant: signer?.payload,
				contextType,
			}),
		[aggregator, participants, contextId, signer, contextType]
	)

	useEffect(() => {
		setSelectedDocuments({})
	}, [signer])

	const participantProps: InputProps = {
		label: 'Участник',
		labelPlacing: 'out',
		placeholder: 'Иванов Иван Иванович',
		scale: 'min',
		value: query,
		onChange: ({ target }) => {
			setQuery(target.value)
			setSigner(null)

			dispatch(
				searchEveryplace({
					query: target.value,
					searchIn: { internal: true },
					methodType: 'PERSON,ORGANIZATION',
					params: { size: 5 },
				})
			)
		},
		autocompleteConfig: {
			// @ts-ignore
			categories: [
				!!participants.length && { id: 'fast-access', label: 'Быстрый доступ' },
				!!searchResult.length && { id: 'search', label: 'Поиск по базе' },
			].filter(Boolean),
			selected: signer,
			onSelect: (option) => {
				setSigner(option)
				setQuery(option.label as string)
			},
			options: [
				...participants.map(({ customerId, signatureProvider, name, customerType }) => ({
					categoryId: 'fast-access',
					id: customerId,
					value: customerId,
					label: name,
					payload: {
						customerId,
						customerType,
						signatureProvider,
					},
				})),
				...searchResult.map(({ id, signatureProvider, ...rest }) => ({
					id,
					label: utils.getFullName(rest),
					value: id,
					categoryId: 'search',
					payload: {
						signatureProvider,
						customerId: id,
						customerType: rest.$$type,
					},
				})),
			],
		},
	}

	const signatureApplicationProps: SelectProps = {
		label: 'Приложение',
		labelPlacing: 'out',
		scale: 'min',
		value: signatureApplication,
		placeholder: 'Выберите сервис',
		onChange: ({ value }) => setSignatureApplication(value as string),
		options: applicationTypes.map(({ id, title }) => ({ id, label: title })),
	}

	const documentsSelectionProps = {
		documentsToSign,
		selectedParticipant: signer?.payload,
		selectedDocuments,

		onDocumentSelect: handleSelectDocument,
	}

	const submitBtnProps: ButtonProps = {
		disabled: !signatureApplication || !signer || !Object.values(selectedDocuments).some(Boolean),
		type: 'button',
		size: 'min',
		styleOverride: { width: 'fit-content' },
		onClick: handleSubmit,
	}

	if (isFetching) {
		return <Loading max />
	}

	const provider =
		qualifiedSignatureProviders.find(({ id }) => id === signerSignatureProvider)?.title ??
		(signerSignatureProvider ? 'Неизвестное значение' : null)

	return (
		<Grid.Section>
			<Grid.Row>
				<Input {...participantProps} />
				<Grid.Section gap='xs'>
					<Text font='small'>Провайдер подписания</Text>
					<Text>{defaultOr(provider)}</Text>
				</Grid.Section>
			</Grid.Row>
			<Grid.Row>
				<Select {...signatureApplicationProps} />
			</Grid.Row>
			{!!signer?.payload && <DocumentsSelection {...documentsSelectionProps} />}
			<Button {...submitBtnProps}>Отправить</Button>
		</Grid.Section>
	)
}

export default AddSigners
